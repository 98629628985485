/* 提现单冲销交易流水模块 */

import * as commonLib from '../modules/commonLib'
interface IModels {

  /**
   * 接口名：订单成本查询
   */
  'POST/commission/cost': {
    Req: {
      page: number
      size: number
      orderNo?: string
      pm?: string
      cm?: string
      am?: string
      customerName?: string
      orgId?: number
    }
    Res: {}
  }

  /**
   * 接口名：订单提成查询
   */
  'POST/commission': {
    Req: {
      page: number
      size: number
      orderNo?: string
      pm?: string
      cm?: string
      am?: string
      customerName?: string
      orgId?: number
    }
    Res: {}
  }

  /**
   * 接口名：提成发放查询
   */
  'POST/commission/distribute': {
    Req: {
      page: number
      size: number
      month: {
        from: string
        to: string
      }
      userId: number
      owner: boolean
    }
    Res: {}
  }

  /**
   * 接口名：提成管理查询
   */
  'POST/commission/manage': {
    Req: {
      page: number
      size: number
      month: {
        from: string
        to: string
      }
      userId: number
      owner: boolean
    }
    Res: {}
  }

  /**
   * 接口名：创建提成管理
   */
  'POST/commission/manage/create': {
    Req: {
      page: number
      size: number
      month: {
        from: string
        to: string
      }
      userId: number
      owner: boolean
    }
    Res: {}
  }

  /**
   * 接口名：创建提成管理
   */
  'DELETE/commission/manage/{id}': {
    Req: {}
    Res: {}
  }

  /**
   * 接口名：提成管理详情查询
   */
  'POST/commission/manage/detail': {
    Req: {
      manageId: number
      page: number
      size: number
    }
    Res: {}
  }

  /**
   * 接口名：确认并推送提成
   */
  'PUT/commission/manage/confirm/{id}': {
    Req: {
      manageId: number
      page: number
      size: number
    }
    Res: {}
  }

  /**
   * 接口名：创建提成管理明细
   */
  'POST/commission/manage/detail/create': {
    Req: {
      manageId: number
      page: number
      size: number
    }
    Res: {}
  }

}

export default function createFetch(fetchConfig: any) {
  const rapperFetch = commonLib.getRapperRequest(fetchConfig)

  return {
    /**
     * 接口名：订单成本查询
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/commission/cost': (req?: IModels['POST/commission/cost']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/commission/cost',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IModels['POST/commission/cost']['Res']>
    },

    /**
     * 接口名：订单提成查询
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/commission': (req?: IModels['POST/commission']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/commission',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IModels['POST/commission']['Res']>
    },

    /**
     * 接口名：提成发放查询
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/commission/distribute': (req?: IModels['POST/commission/distribute']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/commission/distribute',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IModels['POST/commission/distribute']['Res']>
    },

    /**
     * 接口名：提成管理查询
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'POST/commission/manage': (req?: IModels['POST/commission/manage']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/commission/manage',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IModels['POST/commission/manage']['Res']>
    }, */

    /**
     * 接口名：创建提成管理
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'POST/commission/manage/create': (req?: IModels['POST/commission/manage/create']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/commission/manage/create',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IModels['POST/commission/manage/create']['Res']>
    }, */

    /**
     * 接口名：删除提成管理
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'DELETE/commission/manage/{id}': (req?: IModels['DELETE/commission/manage/{id}']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/commission/manage/{id}',
        method: 'DELETE',
        params: req,
        extra,
      }) as Promise<IModels['DELETE/commission/manage/{id}']['Res']>
    }, */

    /**
     * 接口名：提成管理详情查询
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'POST/commission/manage/detail': (req?: IModels['POST/commission/manage/detail']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/commission/manage/detail',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IModels['POST/commission/manage/detail']['Res']>
    }, */

    /**
     * 接口名：提成管理详情查询
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'PUT/commission/manage/confirm/{id}': (req?: IModels['PUT/commission/manage/confirm/{id}']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/commission/manage/confirm/{id}',
        method: 'PUT',
        params: req,
        extra,
      }) as Promise<IModels['PUT/commission/manage/confirm/{id}']['Res']>
    }, */

    /**
     * 接口名：创建提成管理明细
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'POST/commission/manage/detail/create': (req?: IModels['POST/commission/manage/detail/create']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/commission/manage/detail/create',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IModels['POST/commission/manage/detail/create']['Res']>
    }, */
  }
}