/* 文档管理模块 */

import * as commonLib from '../modules/commonLib'
import {IResponseTypes, IModels} from './request'

export default function createFetch(fetchConfig: any) {
  const rapperFetch = commonLib.getRapperRequest(fetchConfig)

  return {
    /**
     * 接口名：文件列表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=524698&itf=2344791
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/drive/list': (req?: IModels['POST/drive/list']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/drive/list',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/drive/list']>
    },

    /**
     * 接口名：重命名
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=524698&itf=2344898
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/drive/rename': (req?: IModels['POST/drive/rename']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/drive/rename',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/drive/rename']>
    },

    /**
     * 接口名：新建文件夹
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=524698&itf=2344899
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/drive/create_folder': (req?: IModels['POST/drive/create_folder']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/drive/create_folder',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/drive/create_folder']>
    },

    /**
     * 接口名：移到回收站
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=524698&itf=2346614
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/drive/recycling': (req?: IModels['POST/drive/recycling']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/drive/recycling',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/drive/recycling']>
    },

    /**
     * 接口名：彻底删除
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=524698&itf=2346615
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'DELETE/drive/delete/{id}': (req?: IModels['DELETE/drive/delete/{id}']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/drive/delete/{id}',
        method: 'DELETE',
        params: req,
        extra,
      }) as Promise<IResponseTypes['DELETE/drive/delete/{id}']>
    },

    /**
     * 接口名：文件下载
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=524698&itf=2346642
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/drive/download/url': (req?: IModels['POST/drive/download/url']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/drive/download/url',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/drive/download/url']>
    },

    /**
     * 接口名：文件搜索
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=524698&itf=2346650
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/drive/search': (req?: IModels['GET/drive/search']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/drive/search',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/drive/search']>
    },

    /**
     * 接口名：更新文件夹
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=524698&itf=2347051
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/drive/update_folder': (req?: IModels['POST/drive/update_folder']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/drive/update_folder',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/drive/update_folder']>
    },

    /**
     * 接口名：上传文件
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=524698&itf=2347052
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/drive/upload': (req?: IModels['POST/drive/upload']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/drive/upload',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/drive/upload']>
    },

    /**
     * 接口名：最近下载人
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=524698&itf=2347396
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/drive/download/recent_record': (
      req?: IModels['POST/drive/download/recent_record']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/drive/download/recent_record',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/drive/download/recent_record']>
    },

    /**
     * 接口名：文件夹树状列表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=524698&itf=2347627
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/drive/tree_folder': (req?: IModels['POST/drive/tree_folder']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/drive/tree_folder',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/drive/tree_folder']>
    },

    /**
     * 接口名：移动文件/文件夹
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=524698&itf=2347624
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/drive/move': (req?: IModels['POST/drive/move']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/drive/move',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/drive/move']>
    },

    /**
     * 接口名：回收站文件列表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=524698&itf=2347756
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/drive/recycle_bin': (req?: IModels['GET/drive/recycle_bin']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/drive/recycle_bin',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/drive/recycle_bin']>
    },

    /**
     * 接口名：彻底删除
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=524698&itf=2347759
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'DELETE/drive/delete': (req?: IModels['DELETE/drive/delete']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/drive/delete',
        method: 'DELETE',
        params: req,
        extra,
      }) as Promise<IResponseTypes['DELETE/drive/delete']>
    },

    /**
     * 接口名：回收站还原
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=524698&itf=2347788
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PUT/drive/restore': (req?: IModels['PUT/drive/restore']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/drive/restore',
        method: 'PUT',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PUT/drive/restore']>
    },

    /**
     * 接口名：获取历史文件
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=524698&itf=2356313
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/drive/{id}/history': (req?: IModels['GET/drive/{id}/history']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/drive/{id}/history',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/drive/{id}/history']>
    },

    /**
     * 接口名：删除历史文件
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=524698&itf=2356325
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/drive/delete/history': (req?: IModels['POST/drive/delete/history']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/drive/delete/history',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/drive/delete/history']>
    },

    /**
     * 接口名：获取历史版本号
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=524698&itf=2359721
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/drive/version_history/{id}': (
      req?: IModels['GET/drive/version_history/{id}']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/drive/version_history/{id}',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/drive/version_history/{id}']>
    },

    /**
     * 接口名：按路径获取所有文件、文件夹
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=524698&itf=2359849
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/drive/file_number': (req?: IModels['GET/drive/file_number']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/drive/file_number',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/drive/file_number']>
    },

    /**
     * 接口名：文件下载详情
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=524698&itf=2395784
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/drive/download/details': (req?: IModels['POST/drive/download/details']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/drive/download/details',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/drive/download/details']>
    },

    /**
     * 接口名：下载的导出详情Excel
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=524698&itf=2396081
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/drive/download/details/export': (
      req?: IModels['POST/drive/download/details/export']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/drive/download/details/export',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/drive/download/details/export']>
    },

    /**
     * 接口名：用户下载详情
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=524698&itf=2396123
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/drive/download/details/statistics': (
      req?: IModels['POST/drive/download/details/statistics']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/drive/download/details/statistics',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/drive/download/details/statistics']>
    },

    /**
     * 接口名：获取警告阈值
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=524698&itf=2396341
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/drive/download/setting': (req?: IModels['GET/drive/download/setting']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/drive/download/setting',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/drive/download/setting']>
    },

    /**
     * 接口名：设置警告阈值
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=524698&itf=2396342
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/drive/download/setting': (req?: IModels['POST/drive/download/setting']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/drive/download/setting',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/drive/download/setting']>
    },

    /**
     * 接口名：设置需要一起上传pdf的文件类型
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=524698&itf=2409814
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/drive/download/setting/file_type': (
      req?: IModels['POST/drive/download/setting/file_type']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/drive/download/setting/file_type',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/drive/download/setting/file_type']>
    },

    /**
     * 接口名：转pdf
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=524698&itf=2410173
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PUT/drive/covert_pdf': (req?: IModels['PUT/drive/covert_pdf']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/drive/covert_pdf',
        method: 'PUT',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PUT/drive/covert_pdf']>
    },

    /**
     * 接口名：文件下载(原)
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=524698&itf=2411295
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/drive/download': (req?: IModels['POST/drive/download']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/drive/download',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/drive/download']>
    },

    /**
     * 接口名：发送企业微信通知
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=524698&itf=2424474
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/drive/notice': (req?: IModels['POST/drive/notice']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/drive/notice',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/drive/notice']>
    },

    /**
     * 接口名：设置可见范围
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=524698&itf=2424675
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/drive/visible_range': (req?: IModels['POST/drive/visible_range']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/drive/visible_range',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/drive/visible_range']>
    },

    /**
     * 接口名：检查字体
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=524698&itf=2424777
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/drive/pdf/fonts': (req?: IModels['GET/drive/pdf/fonts']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/drive/pdf/fonts',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/drive/pdf/fonts']>
    },

    /**
     * 接口名：设置禁用字体
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=524698&itf=2424841
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/drive/download/setting/disabled_font': (
      req?: IModels['POST/drive/download/setting/disabled_font']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/drive/download/setting/disabled_font',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/drive/download/setting/disabled_font']>
    },

    /**
     * 接口名：下载次数校验限制
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=524698&itf=2436563
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/drive/download/check': (req?: IModels['GET/drive/download/check']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/drive/download/check',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/drive/download/check']>
    },

    /**
     * 接口名：预览限制校验
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=524698&itf=2436564
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/drive/preview/check': (req?: IModels['GET/drive/preview/check']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/drive/preview/check',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/drive/preview/check']>
    },
    /**
     * 接口名：批量修改文档权限
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/drive/update_permission': (req?: IModels['POST/drive/update_permission']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/drive/update_permission',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/drive/update_permission']>
    },
  }
}