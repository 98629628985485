import axios from '@/http/index'
import {preParams, Extra, BasicTableResult} from "../index";
// import {} from './model/individualModel';

export default {
  /**
   * @description: 客户管理同步
   * @return {void}
   */
  'POST/customer_basic_info/sync': (params: any, extra?: Extra) => {
    return axios(`/customer_basic_info/sync`, preParams(params, extra, 'post'))
  },
}