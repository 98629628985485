/* 提现单冲销交易流水模块 */

import * as commonLib from '../modules/commonLib'
import {IResponseTypes, IModels} from './request'

export default function createFetch(fetchConfig: any) {
  const rapperFetch = commonLib.getRapperRequest(fetchConfig)

  return {
    /**
     * 接口名：支付包列表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=543401&itf=2441671
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/cost_cash/pack': (req?: IModels['GET/cost_cash/pack']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/cost_cash/pack',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/cost_cash/pack']>
    },

    /**
     * 接口名：未匹配提现分页
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=543401&itf=2441719
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/cost_cash/pack/detail': (req?: IModels['POST/cost_cash/pack/detail']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/cost_cash/pack/detail',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/cost_cash/pack/detail']>
    },

    /**
     * 接口名：未匹配流水分页
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=543401&itf=2441720
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/cost_cash/pack/detail/cash': (
      req?: IModels['POST/cost_cash/pack/detail/cash']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/cost_cash/pack/detail/cash',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/cost_cash/pack/detail/cash']>
    },

    /**
     * 接口名：自动匹配
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=543401&itf=2442712
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/cost_cash/match/auto': (req?: IModels['POST/cost_cash/match/auto']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/cost_cash/match/auto',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/cost_cash/match/auto']>
    },

    /**
     * 接口名：手动匹配
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=543401&itf=2442713
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/cost_cash/match/manual': (req?: IModels['POST/cost_cash/match/manual']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/cost_cash/match/manual',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/cost_cash/match/manual']>
    },

    /**
     * 接口名：取消匹配
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=543401&itf=2442714
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'DELETE/cost_cash/match/cancel': (
      req?: IModels['DELETE/cost_cash/match/cancel']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/cost_cash/match/cancel',
        method: 'DELETE',
        params: req,
        extra,
      }) as Promise<IResponseTypes['DELETE/cost_cash/match/cancel']>
    },

    /**
     * 接口名：导出已匹配列表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=543401&itf=2442716
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/cost_cash/match/export': (req?: IModels['POST/cost_cash/match/export']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/cost_cash/match/export',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/cost_cash/match/export']>
    },

    /**
     * 接口名：已匹配列表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=543401&itf=2442751
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/cost_cash/match/page': (req?: IModels['POST/cost_cash/match/page']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/cost_cash/match/page',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/cost_cash/match/page']>
    },

    /**
     * 接口名：修改匹配记录
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=543401&itf=2442980
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/cost_cash/match/update': (req?: IModels['POST/cost_cash/match/update']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/cost_cash/match/update',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/cost_cash/match/update']>
    },

    /**
     * 接口名：预匹配
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=543401&itf=2445031
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/cost_cash/match/pre': (req?: IModels['POST/cost_cash/match/pre']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/cost_cash/match/pre',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/cost_cash/match/pre']>
    },

    /**
     * 接口名：匹配记录——手动添加流水
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=543401&itf=2446338
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/cost_cash/match/manual/add': (
      req?: IModels['POST/cost_cash/match/manual/add']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/cost_cash/match/manual/add',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/cost_cash/match/manual/add']>
    },

    /**
     * 接口名：下载关单备注导入模板
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=543401&itf=2452710
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/cost_cash_packs/download/close_notes': (
      req?: IModels['GET/cost_cash_packs/download/close_notes']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/cost_cash_packs/download/close_notes',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/cost_cash_packs/download/close_notes']>
    },

    /**
     * 接口名：导入关单备注
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=543401&itf=2452711
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/cost_cash_packs/import/close_notes': (
      req?: IModels['POST/cost_cash_packs/import/close_notes']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/cost_cash_packs/import/close_notes',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/cost_cash_packs/import/close_notes']>
    },

    /**
     * 接口名：更新支付包名称
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=543401&itf=2453527
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/cost_cash_packs/update': (req?: IModels['POST/cost_cash_packs/update']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/cost_cash_packs/update',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/cost_cash_packs/update']>
    },
    /**
     * 接口名：创建支付子包
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/cost_cash_packs/generate_payment_sub_packages': (req?: IModels['POST/cost_cash_packs/generate_payment_sub_packages']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/cost_cash_packs/generate_payment_sub_packages',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/cost_cash_packs/generate_payment_sub_packages']>
    },

     /**
     * 接口名：校验金额是否一致
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=543401&itf=2456434
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/cost_cash/match/checkAmount': (
      req?: IModels['POST/cost_cash/match/checkAmount']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/cost_cash/match/checkAmount',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/cost_cash/match/checkAmount']>
    },
     /**
     * 接口名：特殊匹配
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/cost_cash/match/special': (
      req?: IModels['POST/cost_cash/match/special']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/cost_cash/match/special',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/cost_cash/match/special']>
    },





    /**
     * 接口名：体现单冲销交易流水，已匹配列表同步mars结果
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/cost_cash/match/sync_mars': (req?: IModels['POST/cost_cash/match/sync_mars']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/cost_cash/match/sync_mars',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/cost_cash/match/sync_mars']>
    },
  }
}