/* 数据报表模块 */

import * as commonLib from '../modules/commonLib'
import {IResponseTypes, IModels} from './request'

export default function createFetch(fetchConfig: any) {
  const rapperFetch = commonLib.getRapperRequest(fetchConfig)

  return {
    /**
     * 接口名：基础报表模块1柱状图
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=542079&itf=2434071
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/basic_report/basic_report': (
      req?: IModels['POST/basic_report/basic_report']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/basic_report/basic_report',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/basic_report/basic_report']>
    },

    /**
     * 接口名：基础报表模块2订单统计
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=542079&itf=2434075
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/basic_report/order_stats': (
      req?: IModels['POST/basic_report/order_stats']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/basic_report/order_stats',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/basic_report/order_stats']>
    },

    /**
     * 接口名：基础报表模块三
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=542079&itf=2434078
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/basic_report/query_order': (
      req?: IModels['POST/basic_report/query_order']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/basic_report/query_order',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/basic_report/query_order']>
    },

    /**
     * 接口名：基础报表_获取能看的团队下所有员工列表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=542079&itf=2435078
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/basic_report/seen_userId': (
      req?: IModels['GET/basic_report/seen_userId']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/basic_report/seen_userId',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/basic_report/seen_userId']>
    },

    /**
     * 接口名：基础报表_获取能看的团队
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=542079&itf=2435077
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/basic_report/seen_org': (req?: IModels['GET/basic_report/seen_org']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/basic_report/seen_org',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/basic_report/seen_org']>
    },

    /**
     * 接口名：客户报表获取能看团队下的所有成员
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=542079&itf=2435285
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/customer_report/seen_userId': (
      req?: IModels['GET/customer_report/seen_userId']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/customer_report/seen_userId',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/customer_report/seen_userId']>
    },

    /**
     * 接口名：客户报表获取能看的团队
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=542079&itf=2435287
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/customer_report/seen_org': (
      req?: IModels['GET/customer_report/seen_org']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/customer_report/seen_org',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/customer_report/seen_org']>
    },

    /**
     * 接口名：更新费用单
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=542079&itf=2435291
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/invoice_expense_vouchers': (
      req?: IModels['PATCH/invoice_expense_vouchers']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/invoice_expense_vouchers',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/invoice_expense_vouchers']>
    },

    /**
     * 接口名：客户报表柱状图
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=542079&itf=2435298
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/customer_report/customer_report': (
      req?: IModels['POST/customer_report/customer_report']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/customer_report/customer_report',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/customer_report/customer_report']>
    },

    /**
     * 接口名：客户报表-报表详情
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=542079&itf=2435299
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/customer_report/report_detail': (
      req?: IModels['POST/customer_report/report_detail']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/customer_report/report_detail',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/customer_report/report_detail']>
    },

    /**
     * 接口名：客户报表-客户详情
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=542079&itf=2435300
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/customer_report/customer_detail': (
      req?: IModels['POST/customer_report/customer_detail']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/customer_report/customer_detail',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/customer_report/customer_detail']>
    },

    /**
     * 接口名：订单趋势_获取能看的团队下所有员工列表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=542079&itf=2435318
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/trend_report/seen_userId': (
      req?: IModels['GET/trend_report/seen_userId']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/trend_report/seen_userId',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/trend_report/seen_userId']>
    },

    /**
     * 接口名：订单趋势报表_获取能看的团队
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=542079&itf=2435319
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/trend_report/seen_org': (req?: IModels['GET/trend_report/seen_org']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/trend_report/seen_org',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/trend_report/seen_org']>
    },

    /**
     * 接口名：订单趋势报表柱状图
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=542079&itf=2435321
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/trend_report/trend_report': (
      req?: IModels['POST/trend_report/trend_report']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/trend_report/trend_report',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/trend_report/trend_report']>
    },

    /**
     * 接口名：订单趋势报表-明细栏
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=542079&itf=2435322
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/trend_report/trend_report_detail': (
      req?: IModels['POST/trend_report/trend_report_detail']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/trend_report/trend_report_detail',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/trend_report/trend_report_detail']>
    },

    /**
     * 接口名：客户报表详情导出
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=542079&itf=2438505
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/customer_report/export': (req?: IModels['POST/customer_report/export']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/customer_report/export',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/customer_report/export']>
    },
    /**
     * 接口名：译员成本分析
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/translator_report/costing': (req?: IModels['POST/translator_report/costing']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/translator_report/costing',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/translator_report/costing']>
    },
  }
}