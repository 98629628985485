import axios from '@/http/index'
import {preParams, Extra, BasicTableResult} from "../index";
// import {} from './model/individualModel';

export default {
  /**
   * @description: 后台任务-所有任务
   * @return {void}
   */
  'POST/quartz/jobs': (params: any, extra?: Extra) => {
    return axios(`/quartz/jobs`, preParams(params, extra, 'post'))
  },
  /**
   * @description: 后台任务-新增任务
   * @return {void}
   */
  'POST/quartz/add': (params: any, extra?: Extra) => {
    return axios(`/quartz/add`, preParams(params, extra, 'post'))
  },
  /**
   * @description: 后台任务-更新任务
   * @return {void}
   */
  'POST/quartz/update': (params: any, extra?: Extra) => {
    return axios(`/quartz/update`, preParams(params, extra, 'post'))
  },
  /**
   * @description: 后台任务-暂停任务
   * @return {void}
   */
  'POST/quartz/pause': (params: any, extra?: Extra) => {
    return axios(`/quartz/pause`, preParams(params, extra, 'post'))
  },
  /**
   * @description: 后台任务-重启任务
   * @return {void}
   */
  'POST/quartz/resume': (params: any, extra?: Extra) => {
    return axios(`/quartz/resume`, preParams(params, extra, 'post'))
  },
  /**
   * @description: 后台任务-删除任务
   * @return {void}
   */
  'POST/quartz/delete': (params: any, extra?: Extra) => {
    return axios(`/quartz/delete`, preParams(params, extra, 'post'))
  },
  /**
   * @description: 后台任务-任务类下拉列表
   * @return {void}
   */
  'GET/quartz/job/list': (params: any, extra?: Extra) => {
    return axios(`/quartz/job/list`, preParams(params, extra, 'get'))
  },
  /**
   * @description: 后台任务-获取任务执行详情
   * @return {void}
   */
  'GET/quartz/job/runs': (params: any, extra?: Extra) => {
    return axios(`/quartz/job/runs`, preParams(params, extra, 'get'))
  },

}