/* 订单模块 */

import * as commonLib from '../modules/commonLib'
import {IResponseTypes, IModels} from './request'

export default function createFetch(fetchConfig: any) {
  const rapperFetch = commonLib.getRapperRequest(fetchConfig)

  return {
    /**
     * 接口名：订单成本类别匹配_分页查询
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=538771&itf=2417784
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/order_cost/unmatched/query': (
      req?: IModels['POST/order_cost/unmatched/query']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/order_cost/unmatched/query',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/order_cost/unmatched/query']>
    },

    /**
     * 接口名：订单成本类别匹配_确认无误
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=538771&itf=2418694
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/order_cost/unmatched/match': (
      req?: IModels['POST/order_cost/unmatched/match']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/order_cost/unmatched/match',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/order_cost/unmatched/match']>
    },

    /**
     * 接口名：订单成本类别匹配_自动匹配
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=538771&itf=2418695
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/order_cost/unmatched/auto_match': (
      req?: IModels['POST/order_cost/unmatched/auto_match']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/order_cost/unmatched/auto_match',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/order_cost/unmatched/auto_match']>
    },

    /**
     * 接口名：成本类型下拉框
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=538771&itf=2418696
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/order_cost/unmatched/cost_drop_list': (
      req?: IModels['GET/order_cost/unmatched/cost_drop_list']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/order_cost/unmatched/cost_drop_list',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/order_cost/unmatched/cost_drop_list']>
    },

    /**
     * 接口名：订单成本匹配_修改
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=538771&itf=2418763
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/order_cost/unmatched/update_cost_type': (
      req?: IModels['PATCH/order_cost/unmatched/update_cost_type']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/order_cost/unmatched/update_cost_type',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/order_cost/unmatched/update_cost_type']>
    },

    /**
     * 接口名：订单成本类别匹配记录_分页查询
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=538771&itf=2420323
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/order_cost_record/query': (
      req?: IModels['POST/order_cost_record/query']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/order_cost_record/query',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/order_cost_record/query']>
    },


    /**
 * 接口名：订单分页查询
 * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=543657&itf=2443002
 * @param req 请求参数
 * @param extra 请求配置项
 */
    'POST/order/delivery/query': (req?: IModels['POST/order/delivery/query']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/order/delivery/query',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/order/delivery/query']>
    },

    /**
     * 接口名：订单交付_上传
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=543657&itf=2443157
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/order/delivery/upload': (req?: IModels['POST/order/delivery/upload']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/order/delivery/upload',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/order/delivery/upload']>
    },

    /**
     * 接口名：订单交付_获取单个订单
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=543657&itf=2443173
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/order/delivery/{id}': (req?: IModels['GET/order/delivery/{id}']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/order/delivery/{id}',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/order/delivery/{id}']>
    },

    /**
     * 接口名：订单交付_文件删除
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=543657&itf=2443327
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'DELETE/order/delivery/delete_file': (
      req?: IModels['DELETE/order/delivery/delete_file']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/order/delivery/delete_file',
        method: 'DELETE',
        params: req,
        extra,
      }) as Promise<IResponseTypes['DELETE/order/delivery/delete_file']>
    },

    /**
     * 接口名：订单交付_文件引用
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=543657&itf=2443645
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/order/delivery/referenced_file': (
      req?: IModels['POST/order/delivery/referenced_file']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/order/delivery/referenced_file',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/order/delivery/referenced_file']>
    },

    /**
     * 接口名：订单交付_批量交付
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=543657&itf=2443769
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/order/delivery/batch_delivery': (
      req?: IModels['POST/order/delivery/batch_delivery']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/order/delivery/batch_delivery',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/order/delivery/batch_delivery']>
    },

    /**
     * 接口名：订单交付_上传富文本框文件
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=543657&itf=2445168
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/order/delivery/upload_file': (
      req?: IModels['POST/order/delivery/upload_file']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/order/delivery/upload_file',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/order/delivery/upload_file']>
    },

    /**
     * 接口名：已交付订单
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=543657&itf=2448498
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/order/delivered/query': (req?: IModels['POST/order/delivered/query']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/order/delivered/query',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/order/delivered/query']>
    },

    /**
     * 接口名：获取已交付订单
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=543657&itf=2448924
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/order/delivered/{no}': (req?: IModels['GET/order/delivered/{no}']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/order/delivered/{no}',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/order/delivered/{no}']>
    },

    /**
     * 接口名：待生成发票列表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=543832&itf=2443754
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/order/pending': (req?: IModels['POST/order/pending']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/order/pending',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/order/pending']>
    },

    /**
     * 接口名：合并生成
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=543832&itf=2443755
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/order/merge_order': (req?: IModels['POST/order/merge_order']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/order/merge_order',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/order/merge_order']>
    },

    /**
     * 接口名：批量单独
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=543832&itf=2443756
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/order/batch_generation': (req?: IModels['POST/order/batch_generation']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/order/batch_generation',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/order/batch_generation']>
    },

    /**
     * 接口名：发票列表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=543832&itf=2443758
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/order/invoice/list': (req?: IModels['POST/order/invoice/list']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/order/invoice/list',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/order/invoice/list']>
    },
    /**
     * 接口名：批量合并生成增值税发票
     */
    'POST/order/invoice/vat/merge_order': (req?: IModels['POST/order/invoice/vat/merge_order']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/order/invoice/vat/merge_order',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/order/invoice/vat/merge_order']>
    },
    /**
     * 接口名：批量单独生成增值税发票
     */
    'POST/order/invoice/vat/batch_generation': (req?: IModels['POST/order/invoice/vat/batch_generation']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/order/invoice/vat/batch_generation',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/order/invoice/vat/batch_generation']>
    },
    /**
     * 接口名：增值税发票列表
     */
    'POST/order/invoice/vat/list': (req?: IModels['POST/order/invoice/vat/list']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/order/invoice/vat/list',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/order/invoice/vat/list']>
    },

    /**
     * 接口名：订单收款_分页查询
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=545470&itf=2449602
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/order/receipt/query': (req?: IModels['POST/order/receipt/query']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/order/receipt/query',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/order/receipt/query']>
    },

    /**
     * 接口名：订单收款_获取收款记录
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/order/receipt/payment_record/{id}': (req?: IModels['GET/order/receipt/payment_record/{id}']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/order/receipt/payment_record/{id}',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/order/receipt/payment_record/{id}']>
    },

    /**
     * 接口名：订单收款_获取收款二维码
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/order/receipt/get_qr_code': (req?: IModels['POST/order/receipt/get_qr_code']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/order/receipt/get_qr_code',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/order/receipt/get_qr_code']>
    },

    /**
     * 接口名：订单收款_无订单收款
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/order/receipt/get_qr_code_without_order': (req?: IModels['POST/order/receipt/get_qr_code_without_order']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/order/receipt/get_qr_code_without_order',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/order/receipt/get_qr_code_without_order']>
    },

    /**
     * 接口名：订单收款_无订单收款列表
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/order/receipt/without_order_records': (req?: IModels['POST/order/receipt/without_order_records']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/order/receipt/without_order_records',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/order/receipt/without_order_records']>
    },
    /**
     * 接口名：订单收款_无订单收款单绑定订单
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/order/receipt/bind_order': (req?: IModels['POST/order/receipt/bind_order']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/order/receipt/bind_order',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/order/receipt/bind_order']>
    },

    /**
     * 接口名：项目交付_列表
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/order/proceeding/list': (req?: IModels['POST/order/proceeding/list']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/order/proceeding/list',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/order/proceeding/list']>
    },
    /**
     * 接口名：项目交付_添加修改备注
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/order/comment': (req?: IModels['POST/order/comment']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/order/comment',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/order/comment']>
    },
    /**
     * 接口名：项目交付_订单备注列表
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/order/{id}/comment/list': (req?: IModels['GET/order/{id}/comment/list']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/order/{id}/comment/list',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/order/{id}/comment/list']>
    },

    /**
     * 接口名：项目交付_PM交付
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/order/pm_batch_delivery': (req?: IModels['POST/order/pm_batch_delivery']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/order/pm_batch_delivery',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/order/pm_batch_delivery']>
    },

    /**
     * 接口名：项目交付_需要订单成本控制的条数
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/order/cost_control_count': (req?: IModels['GET/order/cost_control_count']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/order/cost_control_count',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/order/cost_control_count']>
    },

  }
}