import axios from '@/http/index'
import {preParams, Extra, BasicTableResult} from "../index";
// 提现购销
export default {
    /**
     * @description: 提现购销取消校验
     * @return {void}
    */
    'POST/cost_cash/match/cancel/validate': (params: any, extra?: Extra) => {
        return axios(`/cost_cash/match/cancel/validate`, preParams(params, extra, 'post'))
    },
}