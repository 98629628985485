<template>
  <router-view/>
</template>

<style>
*{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  
}
.el-table .el-table__cell{
    padding: 6px 0;
}
.el-table .cell{
    padding: 0px 6px;
}
.el-collapse-item__content{
    padding-bottom: 0px !important;
}
.el-dialog__footer{
    padding-top: 0px !important;
}
.el-input__inner[type="number"]{
    line-height: 0;
}
::-webkit-scrollbar {
    width: 6px
}

::-webkit-scrollbar:horizontal {
    height: 6px
}

::-webkit-scrollbar-track {
    border-radius: 10px
}

::-webkit-scrollbar-thumb {
    background-color: #0003;
    border-radius: 10px;
    transition: all .2s ease-in-out
}

::-webkit-scrollbar-thumb:hover {
    cursor: pointer;
    background-color: #0000004d
}
/* li{
    list-style-type: none;
} */
.el-message{
    z-index: 999999 !important;
}
.el-table .cell{
    user-select: text !important;
}
</style>
