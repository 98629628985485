// 工单模块
import * as commonLib from '../modules/commonLib'
import {IResponseTypes, IModels} from './request'

export default function createFetch(fetchConfig: any) {
  const rapperFetch = commonLib.getRapperRequest(fetchConfig)

    return {
    /**
     * 接口名：工单项目列表
     */
    'GET/tickets/projects': (req?: IModels['GET/tickets/projects']['Req'], extra?: commonLib.IExtra) => {
        return rapperFetch({
          url: '/tickets/projects',
          method: 'GET',
          params: req,
          extra,
        }) as Promise<IResponseTypes['GET/tickets/projects']>
      },
    /**
     * 接口名：语言列表
     */
    'GET/languages': (req?: IModels['GET/languages']['Req'], extra?: commonLib.IExtra) => {
        return rapperFetch({
          url: '/languages',
          method: 'GET',
          params: req,
          extra,
        }) as Promise<IResponseTypes['GET/languages']>
      },
    /**
     * 接口名：行业列表
     */
    'GET/industries': (req?: IModels['GET/industries']['Req'], extra?: commonLib.IExtra) => {
        return rapperFetch({
          url: '/industries',
          method: 'GET',
          params: req,
          extra,
        }) as Promise<IResponseTypes['GET/industries']>
      },
    /**
     * 接口名：提交工单
     */
    'POST/tickets': (req?: IModels['POST/tickets']['Req'], extra?: commonLib.IExtra) => {
        return rapperFetch({
          url: '/tickets',
          method: 'POST',
          params: req,
          extra,
        }) as Promise<IResponseTypes['POST/tickets']>
      },

      /**
     * 接口名：工单列表
     */
    'GET/tickets': (req?: IModels['GET/tickets']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/tickets',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/tickets']>
    },
      /**
     * 接口名：工单详情
     */
    'GET/tickets/{id}': (req?: IModels['GET/tickets/{id}']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/tickets/{id}',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/tickets/{id}']>
    },

     /**
     * 接口名：修改工单
     */
    'PATCH/tickets': (req?: IModels['PATCH/tickets']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/tickets',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/tickets']>
    },
      /**
     * 接口名：工单评论列表
     */
    'GET/tickets/{id}/comments': (req?: IModels['GET/tickets/{id}/comments']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/tickets/{id}/comments',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/tickets/{id}/comments']>
    },
    /**
     * 接口名：评论
     */
    'POST/tickets/comments': (req?: IModels['POST/tickets/comments']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/tickets/comments',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/tickets/comments']>
    },
    /**
     * 接口名：修改工单状态
     */
    'POST/tickets/change_status': (req?: IModels['POST/tickets/change_status']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/tickets/change_status',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/tickets/change_status']>
    },
      /**
     * 接口名：更改处理人
     */
    'PATCH/tickets/forward': (req?: IModels['PATCH/tickets/forward']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/tickets/forward',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/tickets/forward']>
    },
      /**
     * 接口名：更改处理人
     */
    'PATCH/tickets/{id}/accept': (req?: IModels['PATCH/tickets/{id}/accept']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/tickets/{id}/accept',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/tickets/{id}/accept']>
    },
      /**
     * 接口名：拒绝
     */
    'POST/tickets/{id}/reject': (req?: IModels['POST/tickets/{id}/reject']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/tickets/{id}/reject',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/tickets/{id}/reject']>
    },
      /**
     * 接口名：退回修改
     */
    'POST/tickets/{id}/back': (req?: IModels['POST/tickets/{id}/back']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/tickets/{id}/back',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/tickets/{id}/back']>
    },
      /**
     * 接口名：修改类型
     */
    'POST/tickets/change_type': (req?: IModels['POST/tickets/change_type']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/tickets/change_type',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/tickets/change_type']>
    },
     /**
     * 接口名：下载
     */
    'POST/tickets/export': (req?: IModels['POST/tickets/export']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/tickets/export',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/tickets/export']>
    },
    /**
     * 接口名：新增工单项目
     */
    'POST/tickets/projects': (req?: IModels['POST/tickets/projects']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/tickets/projects',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/tickets/projects']>
    },
    /**
     * 接口名：修改工单项目
     */
    'PUT/tickets/projects': (req?: IModels['PUT/tickets/projects']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/tickets/projects',
        method: 'PUT',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PUT/tickets/projects']>
    },
    /**
     * 接口名：删除工单项目
     */
    'DELETE/tickets/projects/{id}': (req?: IModels['DELETE/tickets/projects/{id}']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/tickets/projects/{id}',
        method: 'DELETE',
        params: req,
        extra,
      }) as Promise<IResponseTypes['DELETE/tickets/projects/{id}']>
    },
    /**
     * 接口名：禁用工单项目
     */
    'POST/tickets/projects/{id}/disable': (req?: IModels['POST/tickets/projects/{id}/disable']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/tickets/projects/{id}/disable',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/tickets/projects/{id}/disable']>
    },
    /**
     * 接口名：上移
     */
    'POST/tickets/projects/{id}/move_up': (req?: IModels['POST/tickets/projects/{id}/move_up']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/tickets/projects/{id}/move_up',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/tickets/projects/{id}/move_up']>
    },
    /**
     * 接口名：下移
     */
    'POST/tickets/projects/{id}/move_down': (req?: IModels['POST/tickets/projects/{id}/move_down']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/tickets/projects/{id}/move_down',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/tickets/projects/{id}/move_down']>
    },
    /**
     * 接口名：添加类型
     */
    'POST/tickets/types': (req?: IModels['POST/tickets/types']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/tickets/types',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/tickets/types']>
    },
    /**
     * 接口名：修改类型
     */
    'PUT/tickets/types': (req?: IModels['PUT/tickets/types']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/tickets/types',
        method: 'PUT',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PUT/tickets/types']>
    },
    /**
     * 接口名：禁用启用类型
     */
    'POST/tickets/types/{typeId}/disable': (req?: IModels['POST/tickets/types/{typeId}/disable']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/tickets/types/{typeId}/disable',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/tickets/types/{typeId}/disable']>
    },
    /**
     * 接口名：工单列表（新）
     */
    'POST/tickets/list': (req?: IModels['POST/tickets/list']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/tickets/list',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/tickets/list']>
    },
    /**
     * 接口名：催单
     */
    'POST/tickets/{id}/remind': (req?: IModels['POST/tickets/{id}/remind']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/tickets/{id}/remind',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/tickets/{id}/remind']>
    },
    /**
     * 接口名：耗时
     */
    'PATCH/tickets/duration': (req?: IModels['PATCH/tickets/duration']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/tickets/duration',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/tickets/duration']>
    },
    /**
     * 接口名：判断接口人
     */
    'GET/tickets/user/role': (req?: IModels['GET/tickets/user/role']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/tickets/user/role',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/tickets/user/role']>
    },
    /**
     * 接口名：工单处理人列表
     */
    'POST/tickets/users': (req?: IModels['POST/tickets/users']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/tickets/users',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/tickets/users']>
    },
    }
}