/* 系统模块 */

import * as commonLib from '../modules/commonLib'
import {IResponseTypes, IModels} from './request'

export default function createFetch(fetchConfig: any) {
  const rapperFetch = commonLib.getRapperRequest(fetchConfig)

  return {
    /**
   * 接口名：社保记录导入
   * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=511905&itf=2269027
   * @param req 请求参数
   * @param extra 请求配置项
   */
    'POST/socials/upload': (req?: IModels['POST/socials/upload']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/socials/upload',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/socials/upload']>
    },

    /**
     * 接口名：下载社保导入模板
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=511905&itf=2269368
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/socials/download/social': (
      req?: IModels['POST/socials/download/social']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/socials/download/social',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/socials/download/social']>
    },

    /**
     * 接口名：社保建议值
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=511905&itf=2273684
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/socials/initialization': (req?: IModels['POST/socials/initialization']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/socials/initialization',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/socials/initialization']>
    },

    /**
     * 接口名：新增社保
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=511905&itf=2295408
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/socials/create': (req?: IModels['POST/socials/create']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/socials/create',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/socials/create']>
    },

    /**
     * 接口名：根据ids删除社保记录（批量删除）
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=511905&itf=2309212
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'DELETE/socials': (req?: IModels['DELETE/socials']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/socials',
        method: 'DELETE',
        params: req,
        extra,
      }) as Promise<IResponseTypes['DELETE/socials']>
    },

    /**
     * 接口名：下载湖南社保模板
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=511905&itf=2324828
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/socials/download/social_template': (
      req?: IModels['GET/socials/download/social_template']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/socials/download/social_template',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/socials/download/social_template']>
    },

    /**
     * 接口名：下载湖南公积金模板
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=511905&itf=2324829
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/socials/download/fund_template': (
      req?: IModels['GET/socials/download/fund_template']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/socials/download/fund_template',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/socials/download/fund_template']>
    },

    /**
     * 接口名：湖南公司模板导入
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=511905&itf=2324830
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/socials/import': (req?: IModels['POST/socials/import']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/socials/import',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/socials/import']>
    },

    /**
     * 接口名：员工社保记录变动提示
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=511905&itf=2325363
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/socials/changes': (req?: IModels['POST/socials/changes']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/socials/changes',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/socials/changes']>
    },

    /**
     * 接口名：锁定(单个)社保
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=511905&itf=2454084
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/socials/locked_single': (req?: IModels['POST/socials/locked_single']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/socials/locked_single',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/socials/locked_single']>
    },

  }
}