/* 考勤模块 */

import * as commonLib from '../modules/commonLib'
import {IResponseTypes, IModels} from './request'

export default function createFetch(fetchConfig: any) {
  const rapperFetch = commonLib.getRapperRequest(fetchConfig)

  return {
    /**
     * 接口名：获取-请假类型(下拉)
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=522016&itf=2369298
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/checkin_approvals/type': (req?: IModels['GET/checkin_approvals/type']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/checkin_approvals/type',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/checkin_approvals/type']>
    },

    /**
     * 接口名：新增打卡数据
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=522016&itf=2358859
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/checkin/save': (req?: IModels['POST/checkin/save']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/checkin/save',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/checkin/save']>
    },

    /**
     * 接口名：加班假结余
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=522016&itf=2357810
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/checkin_balances/overtime_surplus': (
      req?: IModels['POST/checkin_balances/overtime_surplus']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/checkin_balances/overtime_surplus',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/checkin_balances/overtime_surplus']>
    },

    /**
     * 接口名：假期余额正常列表查询
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=522016&itf=2357789
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/checkin_balances/normal': (
      req?: IModels['POST/checkin_balances/normal']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/checkin_balances/normal',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/checkin_balances/normal']>
    },

    /**
     * 接口名：假期余额逾期未使用完异常列表查询
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=522016&itf=2356328
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/checkin_balances/overdue': (
      req?: IModels['POST/checkin_balances/overdue']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/checkin_balances/overdue',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/checkin_balances/overdue']>
    },

    /**
     * 接口名：考勤异常报表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=522016&itf=2355721
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/checkin_approvals/report': (
      req?: IModels['POST/checkin_approvals/report']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/checkin_approvals/report',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/checkin_approvals/report']>
    },

    /**
     * 接口名：对接企业微信获取上传附件并打包zip
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=522016&itf=2350599
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/checkin_approvals/enclosure': (
      req?: IModels['GET/checkin_approvals/enclosure']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/checkin_approvals/enclosure',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/checkin_approvals/enclosure']>
    },

    /**
     * 接口名：假期余额导出
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=522016&itf=2349856
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/checkin_balances/export': (
      req?: IModels['POST/checkin_balances/export']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/checkin_balances/export',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/checkin_balances/export']>
    },

    /**
     * 接口名：新增迟到早退
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=522016&itf=2347979
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/checkin_lattes': (req?: IModels['POST/checkin_lattes']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/checkin_lattes',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/checkin_lattes']>
    },

    /**
     * 接口名：获取加班换薪信息
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=522016&itf=2347898
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/checkin_approvals/overtime': (
      req?: IModels['GET/checkin_approvals/overtime']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/checkin_approvals/overtime',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/checkin_approvals/overtime']>
    },

    /**
     * 接口名：下载导入请假数据模板
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=522016&itf=2347685
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/checkin_approvals/download': (
      req?: IModels['GET/checkin_approvals/download']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/checkin_approvals/download',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/checkin_approvals/download']>
    },

    /**
     * 接口名：导入请假数据
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=522016&itf=2347686
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/checkin_approvals/import': (
      req?: IModels['POST/checkin_approvals/import']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/checkin_approvals/import',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/checkin_approvals/import']>
    },

    /**
     * 接口名：加班换薪修改(更新年假加班假)
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=522016&itf=2344007
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/checkin_balances': (req?: IModels['PATCH/checkin_balances']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/checkin_balances',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/checkin_balances']>
    },

    /**
     * 接口名：删除年假加班假
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=522016&itf=2346896
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'DELETE/checkin_balances/{id}': (
      req?: IModels['DELETE/checkin_balances/{id}']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/checkin_balances/{id}',
        method: 'DELETE',
        params: req,
        extra,
      }) as Promise<IResponseTypes['DELETE/checkin_balances/{id}']>
    },

    /**
     * 接口名：导出请假审批数据
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=522016&itf=2346133
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/checkin_approvals/export': (
      req?: IModels['POST/checkin_approvals/export']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/checkin_approvals/export',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/checkin_approvals/export']>
    },

    /**
     * 接口名：新增节假日
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=522016&itf=2329139
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'POST/checkin_schedule': (req?: IModels['POST/checkin_schedule']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/checkin_schedule',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/checkin_schedule']>
    }, */

    /**
     * 接口名：获取所有节日的信息
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=522016&itf=2329143
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'GET/checkin_schedule': (req?: IModels['GET/checkin_schedule']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/checkin_schedule',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/checkin_schedule']>
    }, */

    /**
     * 接口名：维护节假日
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=522016&itf=2329144
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'PATCH/checkin_schedule': (req?: IModels['PATCH/checkin_schedule']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/checkin_schedule',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/checkin_schedule']>
    }, */

    /**
     * 接口名：获取迟到扣款人员信息
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=522016&itf=2329500
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/attendance_lattes': (req?: IModels['POST/attendance_lattes']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/attendance_lattes',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/attendance_lattes']>
    },

    /**
     * 接口名：获取请假扣款人信息
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=522016&itf=2329523
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/checkin_vacation': (req?: IModels['POST/checkin_vacation']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/checkin_vacation',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/checkin_vacation']>
    },

    /**
     * 接口名：假期余额(总)
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=522016&itf=2331019
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/checkin_balances/list': (req?: IModels['POST/checkin_balances/list']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/checkin_balances/list',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/checkin_balances/list']>
    },

    /**
     * 接口名：用户假期余额详情
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=522016&itf=2331023
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/checkin_balances/user': (req?: IModels['POST/checkin_balances/user']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/checkin_balances/user',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/checkin_balances/user']>
    },

    /**
     * 接口名：登记年假加班假(新增)
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=522016&itf=2331025
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/checkin_balances': (req?: IModels['POST/checkin_balances']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/checkin_balances',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/checkin_balances']>
    },

    /**
     * 接口名：获取考勤用户列表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=522016&itf=2333969
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/checkin/user': (req?: IModels['POST/checkin/user']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/checkin/user',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/checkin/user']>
    },

    /**
     * 接口名：根据用户获取考勤信息
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=522016&itf=2333970
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'POST/checkin': (req?: IModels['POST/checkin']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/checkin',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/checkin']>
    }, */

    /**
     * 接口名：对接企业微信获取考勤数据
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=522016&itf=2334709
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/checkin/add': (req?: IModels['POST/checkin/add']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/checkin/add',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/checkin/add']>
    },

    /**
     * 接口名：新增修正记录
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=522016&itf=2335625
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/checkin_corrects': (req?: IModels['POST/checkin_corrects']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/checkin_corrects',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/checkin_corrects']>
    },

    /**
     * 接口名：修正考勤
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=522016&itf=2329475
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/checkin_corrects': (req?: IModels['PATCH/checkin_corrects']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/checkin_corrects',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/checkin_corrects']>
    },

    /**
     * 接口名：根据考勤id获取修正记录
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=522016&itf=2335627
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/checkin_corrects': (req?: IModels['GET/checkin_corrects']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/checkin_corrects',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/checkin_corrects']>
    },

    /**
     * 接口名：修正记录删除
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=522016&itf=2337953
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'DELETE/checkin_corrects/{id}': (
      req?: IModels['DELETE/checkin_corrects/{id}']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/checkin_corrects/{id}',
        method: 'DELETE',
        params: req,
        extra,
      }) as Promise<IResponseTypes['DELETE/checkin_corrects/{id}']>
    },

    /**
     * 接口名：考勤详情列表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=522016&itf=2343995
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/checkin/month_detail': (req?: IModels['POST/checkin/month_detail']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/checkin/month_detail',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/checkin/month_detail']>
    },

    /**
     * 接口名：考勤详情确认
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=522016&itf=2343996
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/checkin/confirm': (req?: IModels['PATCH/checkin/confirm']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/checkin/confirm',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/checkin/confirm']>
    },

    /**
     * 接口名：请假修改(编辑请假)
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=522016&itf=2343998
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/checkin_approvals': (req?: IModels['PATCH/checkin_approvals']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/checkin_approvals',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/checkin_approvals']>
    },

    /**
     * 接口名：导出考勤
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=522016&itf=2344004
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/checkin/export': (req?: IModels['POST/checkin/export']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/checkin/export',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/checkin/export']>
    },

    /**
     * 接口名：加班换薪列表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=522016&itf=2344006
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/checkin_balances/overtime': (
      req?: IModels['POST/checkin_balances/overtime']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/checkin_balances/overtime',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/checkin_balances/overtime']>
    },

    /**
     * 接口名：打卡规则列表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=522016&itf=2344008
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/checkin_punch_rules': (req?: IModels['GET/checkin_punch_rules']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/checkin_punch_rules',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/checkin_punch_rules']>
    },

    /**
     * 接口名：删除打卡规则
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=522016&itf=2344009
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'DELETE/checkin_punch_rules/{id}': (
      req?: IModels['DELETE/checkin_punch_rules/{id}']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/checkin_punch_rules/{id}',
        method: 'DELETE',
        params: req,
        extra,
      }) as Promise<IResponseTypes['DELETE/checkin_punch_rules/{id}']>
    },

    /**
     * 接口名：编辑打卡规则
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=522016&itf=2344010
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/checkin_punch_rules': (req?: IModels['PATCH/checkin_punch_rules']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/checkin_punch_rules',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/checkin_punch_rules']>
    },

    /**
     * 接口名：创建打卡规则
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=522016&itf=2344011
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/checkin_punch_rules': (req?: IModels['POST/checkin_punch_rules']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/checkin_punch_rules',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/checkin_punch_rules']>
    },

    /**
     * 接口名：用户打卡规则列表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=522016&itf=2344012
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/checkin_user_rules/list': (
      req?: IModels['POST/checkin_user_rules/list']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/checkin_user_rules/list',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/checkin_user_rules/list']>
    },

    /**
     * 接口名：一键生成用户对应打卡规则
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=522016&itf=2344014
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/checkin_user_rules/all': (req?: IModels['POST/checkin_user_rules/all']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/checkin_user_rules/all',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/checkin_user_rules/all']>
    },

    /**
     * 接口名：确认个人考勤
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=522016&itf=2344017
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'PATCH/checkin/user_verify': (req?: IModels['PATCH/checkin/user_verify']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/checkin/user_verify',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/checkin/user_verify']>
    }, */

    /**
     * 接口名：加班管理列表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=522016&itf=2344021
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/checkin_balances/overtime/list': (
      req?: IModels['POST/checkin_balances/overtime/list']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/checkin_balances/overtime/list',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/checkin_balances/overtime/list']>
    },

    /**
     * 接口名：下载年假加班调休假导入模板
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=522016&itf=2344024
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/checkin_balances/template': (
      req?: IModels['GET/checkin_balances/template']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/checkin_balances/template',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/checkin_balances/template']>
    },

    /**
     * 接口名：导入年假加班调休假模板
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=522016&itf=2344025
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/checkin_balances/import': (
      req?: IModels['POST/checkin_balances/import']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/checkin_balances/import',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/checkin_balances/import']>
    },

    /**
     * 接口名：加班延期
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=522016&itf=2344027
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/checkin_balances/batch': (
      req?: IModels['PATCH/checkin_balances/batch']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/checkin_balances/batch',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/checkin_balances/batch']>
    },

    /**
     * 接口名：获取请假扣款人信息列表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=522016&itf=2344029
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/checkin_approvals/list': (req?: IModels['POST/checkin_approvals/list']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/checkin_approvals/list',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/checkin_approvals/list']>
    },

    /**
     * 接口名：未设置员工名单
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=522016&itf=2344686
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/checkin_user_rules': (req?: IModels['GET/checkin_user_rules']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/checkin_user_rules',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/checkin_user_rules']>
    },

    /**
     * 接口名：结余
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=522016&itf=2348132
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/checkin_approvals/surplus': (
      req?: IModels['GET/checkin_approvals/surplus']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/checkin_approvals/surplus',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/checkin_approvals/surplus']>
    },

    /**
     * 接口名：根据条件获取假期余额人员列表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=522016&itf=2360889
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/checkin_balances/user/list': (
      req?: IModels['POST/checkin_balances/user/list']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/checkin_balances/user/list',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/checkin_balances/user/list']>
    },

    /**
     * 接口名：根据用户id或日期范围获取假期历史明细
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=522016&itf=2360890
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/checkin_balances/user/history': (
      req?: IModels['POST/checkin_balances/user/history']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/checkin_balances/user/history',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/checkin_balances/user/history']>
    },

    /**
     * 接口名：更新金额
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=522016&itf=2361148
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/checkin_salarys/amount': (
      req?: IModels['PATCH/checkin_salarys/amount']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/checkin_salarys/amount',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/checkin_salarys/amount']>
    },

    /**
     * 接口名：删除打卡记录
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=522016&itf=2365385
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'DELETE/checkin/{id}': (req?: IModels['DELETE/checkin/{id}']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/checkin/{id}',
        method: 'DELETE',
        params: req,
        extra,
      }) as Promise<IResponseTypes['DELETE/checkin/{id}']>
    },

    /**
     * 接口名：批量修正某天考勤数据
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=522016&itf=2367800
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/checkin/batch_exception': (
      req?: IModels['PATCH/checkin/batch_exception']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/checkin/batch_exception',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/checkin/batch_exception']>
    },

    /**
     * 接口名：个人扣款详情
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=522016&itf=2396072
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'POST/checkin/individual/deduct_money': (
      req?: IModels['POST/checkin/individual/deduct_money']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/checkin/individual/deduct_money',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/checkin/individual/deduct_money']>
    }, */

    /**
     * 接口名：个人考勤详情
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=522016&itf=2402386
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'POST/checkin/owner_detail': (req?: IModels['POST/checkin/owner_detail']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/checkin/owner_detail',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/checkin/owner_detail']>
    }, */

    /**
     * 接口名：导出打卡数据
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=522016&itf=2413954
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/checkin/export_punchCard': (
      req?: IModels['POST/checkin/export_punchCard']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/checkin/export_punchCard',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/checkin/export_punchCard']>
    },

    /**
 * 接口名：考勤核算
 * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=525503&itf=2348366
 * @param req 请求参数
 * @param extra 请求配置项
 */
    'POST/checkin_salarys': (req?: IModels['POST/checkin_salarys']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/checkin_salarys',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/checkin_salarys']>
    },

    /**
     * 接口名：考勤推送至薪酬模块
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=525503&itf=2353926
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/checkin_salarys/push': (req?: IModels['POST/checkin_salarys/push']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/checkin_salarys/push',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/checkin_salarys/push']>
    },

    /**
     * 接口名：导出考勤薪酬
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=525503&itf=2354269
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/checkin_salarys/export': (req?: IModels['POST/checkin_salarys/export']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/checkin_salarys/export',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/checkin_salarys/export']>
    },

    /**
     * 接口名：更新全勤奖
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=525503&itf=2357952
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/checkin_salarys/award': (req?: IModels['PATCH/checkin_salarys/award']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/checkin_salarys/award',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/checkin_salarys/award']>
    },
  }
}