import axios from '@/http/index'
import {preParams, Extra, BasicTableResult} from "../index";
// import {} from './model/individualModel';

export default {
    /**
   * @description: 订单收款_多订单合并收款_获取收款二维码
   * @param {array} ids 订单ids
   * @return {void}
   */
    'POST/order/receipt/get_qr_code_with_ids': (params: {ids: number[]}, extra?: Extra) => {
      return axios(`/order/receipt/get_qr_code_with_ids`, preParams(params, extra, 'post'))
    },

    /**
   * @description: 订单收款_关闭二维码使其失效
   * @param {number} id
   * @return {void}
   */
    'POST/order/receipt/qr_code_close/{id}': (params: {id: number}, extra?: Extra) => {
      return axios(`/order/receipt/qr_code_close/${params.id}`, preParams(params, extra, 'post'))
    },

    /**
   * @description: 订单收款_查找支付记录对应的订单列表
   * @param {number} id
   * @return {void}
   */
    'GET/order/receipt/get_merge_orders/{id}': (params: {id: number}, extra?: Extra) => {
      return axios.get(`/order/receipt/get_merge_orders/${params.id}`)
    },
}