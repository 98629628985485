/* 绩效模块 */

import * as commonLib from '../modules/commonLib'
import {IResponseTypes, IModels} from './request'

export default function createFetch(fetchConfig: any) {
  const rapperFetch = commonLib.getRapperRequest(fetchConfig)
  // const rapperFetch: any = fetchConfig

  return {
    /**
     * 接口名：获取-所有员工绩效情况
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=530939&itf=2376397
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/user_achievements/list': (req?: IModels['POST/user_achievements/list']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/user_achievements/list',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/user_achievements/list']>
    },

    /**
     * 接口名：发布-奖惩绩效
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=530939&itf=2376392
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/user_achievements': (req?: IModels['POST/user_achievements']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/user_achievements',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/user_achievements']>
    },

    /**
     * 接口名：修改-奖惩绩效
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=530939&itf=2376394
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/user_achievements': (req?: IModels['PATCH/user_achievements']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/user_achievements',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/user_achievements']>
    },

    /**
     * 接口名：删除-奖惩绩效
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=530939&itf=2376395
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'DELETE/user_achievements/{id}': (
      req?: IModels['DELETE/user_achievements/{id}']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/user_achievements/{id}',
        method: 'DELETE',
        params: req,
        extra,
      }) as Promise<IResponseTypes['DELETE/user_achievements/{id}']>
    },

    /**
     * 接口名：批量审批通过
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=530939&itf=2376398
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/user_achievements/confirm': (
      req?: IModels['PATCH/user_achievements/confirm']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/user_achievements/confirm',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/user_achievements/confirm']>
    },

    /**
     * 接口名：批量取消删除
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=530939&itf=2376400
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/user_achievements/cancelDelete': (
      req?: IModels['PATCH/user_achievements/cancelDelete']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/user_achievements/cancelDelete',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/user_achievements/cancelDelete']>
    },

    /**
     * 接口名：批量取消审批
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=530939&itf=2376399
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/user_achievements/cancelConfirm': (
      req?: IModels['PATCH/user_achievements/cancelConfirm']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/user_achievements/cancelConfirm',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/user_achievements/cancelConfirm']>
    },

    /**
     * 接口名：获取-个人绩效情况
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=530939&itf=2376396
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/user_achievements/personal': (
      req?: IModels['POST/user_achievements/personal']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/user_achievements/personal',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/user_achievements/personal']>
    },

    /**
     * 接口名：批量发布绩效
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=530939&itf=2406674
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/user_achievements/batch_publish': (
      req?: IModels['POST/user_achievements/batch_publish']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/user_achievements/batch_publish',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/user_achievements/batch_publish']>
    },
  }
}