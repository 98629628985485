/* 报销模块 */

import * as commonLib from '../modules/commonLib'
import {IResponseTypes, IModels} from './request'

export default function createFetch(fetchConfig: any) {
  const rapperFetch = commonLib.getRapperRequest(fetchConfig)

  return {
    /**
     * 接口名：个人上传发票记录列表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=540126&itf=2424373
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/invoices/owner': (req?: IModels['POST/invoices/owner']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/invoices/owner',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/invoices/owner']>
    },

    /**
     * 接口名：上传发票/文件
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=540126&itf=2424375
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/invoices/upload': (req?: IModels['POST/invoices/upload']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/invoices/upload',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/invoices/upload']>
    },

    /**
     * 接口名：识别发票
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=540126&itf=2424378
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/invoices/mixInvoice': (req?: IModels['POST/invoices/mixInvoice']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/invoices/mixInvoice',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/invoices/mixInvoice']>
    },

    /**
     * 接口名：解析数据展示(type:Invoice 和status!=Unidentified) 可以点击
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=540126&itf=2424492
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/invoices/{id}': (req?: IModels['GET/invoices/{id}']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/invoices/{id}',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/invoices/{id}']>
    },

    /**
     * 接口名：修改发票
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=540126&itf=2424676
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/invoices': (req?: IModels['PATCH/invoices']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/invoices',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/invoices']>
    },

    /**
     * 接口名：删除发票
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=540126&itf=2424840
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'DELETE/invoices/{id}': (req?: IModels['DELETE/invoices/{id}']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/invoices/{id}',
        method: 'DELETE',
        params: req,
        extra,
      }) as Promise<IResponseTypes['DELETE/invoices/{id}']>
    },

    /**
     * 接口名：获取文件类型
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=540126&itf=2425476
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/invoices/mediaType': (req?: IModels['GET/invoices/mediaType']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/invoices/mediaType',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/invoices/mediaType']>
    },

    /**
     * 接口名：根据当前用户获取单据收集人
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=540126&itf=2426442
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/invoice_expense_vouchers/documentCollector': (
      req?: IModels['GET/invoice_expense_vouchers/documentCollector']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/invoice_expense_vouchers/documentCollector',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/invoice_expense_vouchers/documentCollector']>
    },

    /**
     * 接口名：创建费用单
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=540126&itf=2426760
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/invoice_expense_vouchers': (
      req?: IModels['POST/invoice_expense_vouchers']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/invoice_expense_vouchers',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/invoice_expense_vouchers']>
    },

    /**
     * 接口名：未使用的票据列表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=540126&itf=2426981
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/invoices/unUsed': (req?: IModels['GET/invoices/unUsed']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/invoices/unUsed',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/invoices/unUsed']>
    },

    /**
     * 接口名：费用单详情
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=540126&itf=2427335
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/invoice_expense_vouchers/{id}': (
      req?: IModels['GET/invoice_expense_vouchers/{id}']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/invoice_expense_vouchers/{id}',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/invoice_expense_vouchers/{id}']>
    },

    /**
     * 接口名：查询费用类别及费用事项(级联)
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=540126&itf=2428787
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/invoice_expense_categorys/tree': (
      req?: IModels['GET/invoice_expense_categorys/tree']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/invoice_expense_categorys/tree',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/invoice_expense_categorys/tree']>
    },

    /**
     * 接口名：创建费用类别/事项
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=540126&itf=2428789
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/invoice_expense_categorys': (
      req?: IModels['POST/invoice_expense_categorys']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/invoice_expense_categorys',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/invoice_expense_categorys']>
    },

    /**
     * 接口名：修改费用类别/事项
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=540126&itf=2428791
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/invoice_expense_categorys': (
      req?: IModels['PATCH/invoice_expense_categorys']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/invoice_expense_categorys',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/invoice_expense_categorys']>
    },

    /**
     * 接口名：删除费用类别/事项
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=540126&itf=2428798
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'DELETE/invoice_expense_categorys/{id}': (
      req?: IModels['DELETE/invoice_expense_categorys/{id}']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/invoice_expense_categorys/{id}',
        method: 'DELETE',
        params: req,
        extra,
      }) as Promise<IResponseTypes['DELETE/invoice_expense_categorys/{id}']>
    },

    /**
     * 接口名：创建报销项目
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=540126&itf=2428875
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/invoice_expense_items': (req?: IModels['POST/invoice_expense_items']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/invoice_expense_items',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/invoice_expense_items']>
    },

    /**
     * 接口名：更新报销项目
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=540126&itf=2428876
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/invoice_expense_items': (req?: IModels['PATCH/invoice_expense_items']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/invoice_expense_items',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/invoice_expense_items']>
    },

    /**
     * 接口名：删除报销项目
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=540126&itf=2428877
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'DELETE/invoice_expense_items/{id}': (
      req?: IModels['DELETE/invoice_expense_items/{id}']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/invoice_expense_items/{id}',
        method: 'DELETE',
        params: req,
        extra,
      }) as Promise<IResponseTypes['DELETE/invoice_expense_items/{id}']>
    },

    /**
     * 接口名：获取报销项目
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=540126&itf=2428878
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/invoice_expense_items': (req?: IModels['GET/invoice_expense_items']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/invoice_expense_items',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/invoice_expense_items']>
    },

    /**
     * 接口名：发票批量识别
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=540126&itf=2440211
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/invoices/batch_recognition': (
      req?: IModels['POST/invoices/batch_recognition']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/invoices/batch_recognition',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/invoices/batch_recognition']>
    },

    /**
     * 接口名：新增外部收款账户
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=540126&itf=2440246
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/external_payment_accounts': (
      req?: IModels['POST/external_payment_accounts']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/external_payment_accounts',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/external_payment_accounts']>
    },

    /**
     * 接口名：禁用外部账号
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=540126&itf=2440247
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/external_payment_accounts/enable': (
      req?: IModels['PATCH/external_payment_accounts/enable']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/external_payment_accounts/enable',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/external_payment_accounts/enable']>
    },

    /**
     * 接口名：获取外部收款人信息列表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=540126&itf=2440248
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/external_payment_accounts/list': (
      req?: IModels['POST/external_payment_accounts/list']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/external_payment_accounts/list',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/external_payment_accounts/list']>
    },

    /**
     * 接口名：查询外部收款账户名单（下拉框）
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=540126&itf=2440249
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/external_payment_accounts/nameList': (
      req?: IModels['GET/external_payment_accounts/nameList']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/external_payment_accounts/nameList',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/external_payment_accounts/nameList']>
    },

    /**
     * 接口名：根据name获取外部收款账户(脱敏)
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=540126&itf=2440250
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/external_payment_accounts/owner_account': (
      req?: IModels['GET/external_payment_accounts/owner_account']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/external_payment_accounts/owner_account',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/external_payment_accounts/owner_account']>
    },

    /**
     * 接口名：获取未离职员工列表(下拉框)
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=540126&itf=2440310
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/users/enable': (req?: IModels['GET/users/enable']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/users/enable',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/users/enable']>
    },

    /**
     * 接口名：批量删除发票
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=540126&itf=2441367
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'DELETE/invoices': (req?: IModels['DELETE/invoices']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/invoices',
        method: 'DELETE',
        params: req,
        extra,
      }) as Promise<IResponseTypes['DELETE/invoices']>
    },

    /**
     * 接口名：获取全部费用报销
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=540126&itf=2442746
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/invoice_expense_items/list': (
      req?: IModels['GET/invoice_expense_items/list']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/invoice_expense_items/list',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/invoice_expense_items/list']>
    },

    /**
     * 接口名：导入费用归属部门
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=540126&itf=2444740
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/invoice_expense_vouchers/return_department': (
      req?: IModels['POST/invoice_expense_vouchers/return_department']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/invoice_expense_vouchers/return_department',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/invoice_expense_vouchers/return_department']>
    },

    /**
     * 接口名：更新单据状态
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=540126&itf=2444752
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/invoice_expense_vouchers/voucher_status': (
      req?: IModels['PATCH/invoice_expense_vouchers/voucher_status']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/invoice_expense_vouchers/voucher_status',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/invoice_expense_vouchers/voucher_status']>
    },

    /**
     * 接口名：新预览发票
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=540126&itf=2444936
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/invoices/preview': (req?: IModels['GET/invoices/preview']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/invoices/preview',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/invoices/preview']>
    },

    /**
     * 接口名：撤销账单
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=540126&itf=2445270
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'DELETE/invoice_expense_vouchers': (
      req?: IModels['DELETE/invoice_expense_vouchers']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/invoice_expense_vouchers',
        method: 'DELETE',
        params: req,
        extra,
      }) as Promise<IResponseTypes['DELETE/invoice_expense_vouchers']>
    },

    /**
     * 接口名：下载费用归属部门模板
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=540126&itf=2445274
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/invoice_expense_vouchers/department/template': (
      req?: IModels['GET/invoice_expense_vouchers/department/template']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/invoice_expense_vouchers/department/template',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/invoice_expense_vouchers/department/template']>
    },

    /**
     * 接口名：批量修改文件类型
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=540126&itf=2447289
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/invoices/batch_fileType': (
      req?: IModels['PATCH/invoices/batch_fileType']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/invoices/batch_fileType',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/invoices/batch_fileType']>
    },

    /**
     * 接口名：获取部门负责人
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=540126&itf=2448704
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/users/profile/user_info': (req?: IModels['GET/users/profile/user_info']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/users/profile/user_info',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/users/profile/user_info']>
    },

    /**
     * 接口名：获取流水
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=540126&itf=2452784
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/financial_flows/records': (
      req?: IModels['POST/financial_flows/records']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/financial_flows/records',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/financial_flows/records']>
    },

    /**
     * 接口名：绑定流水记录
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=540126&itf=2452800
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/invoice_expense_vouchers/bind_flow': (
      req?: IModels['PATCH/invoice_expense_vouchers/bind_flow']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/invoice_expense_vouchers/bind_flow',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/invoice_expense_vouchers/bind_flow']>
    },

    /**
     * 接口名：删除人工新增的流水记录
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=540126&itf=2452803
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/invoice_expense_vouchers/un_bind_flow': (
      req?: IModels['PATCH/invoice_expense_vouchers/un_bind_flow']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/invoice_expense_vouchers/un_bind_flow',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/invoice_expense_vouchers/un_bind_flow']>
    },

    /**
     * 接口名：我的审批-详情(发票修改)
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=540126&itf=2452882
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/invoices/approval_update': (
      req?: IModels['PATCH/invoices/approval_update']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/invoices/approval_update',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/invoices/approval_update']>
    },

    /**
     * 接口名：导出报销单
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=540126&itf=2453431
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/invoice_expense_vouchers/export': (
      req?: IModels['POST/invoice_expense_vouchers/export']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/invoice_expense_vouchers/export',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/invoice_expense_vouchers/export']>
    },

    /**
     * 接口名：预算下拉
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=540126&itf=2453800
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/budget/list': (req?: IModels['GET/budget/list']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/budget/list',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/budget/list']>
    },

    /**
     * 接口名：获取银行名称
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=540126&itf=2454025
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/financial_banks/bank_name': (
      req?: IModels['GET/financial_banks/bank_name']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/financial_banks/bank_name',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/financial_banks/bank_name']>
    },

    // 报销审批

    /**
 * 接口名：审批事项列表
 * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=541589&itf=2431692
 * @param req 请求参数
 * @param extra 请求配置项
 */
    'POST/flowable/itemList': (req?: IModels['POST/flowable/itemList']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/flowable/itemList',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/flowable/itemList']>
    },

    /**
     * 接口名：查看原始流程图
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=541589&itf=2431766
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/flowable/resourceView': (req?: IModels['POST/flowable/resourceView']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/flowable/resourceView',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/flowable/resourceView']>
    },

    /**
     * 接口名：创建事项及流程部署
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=541589&itf=2431978
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/flowable/deploy': (req?: IModels['POST/flowable/deploy']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/flowable/deploy',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/flowable/deploy']>
    },

    /**
     * 接口名：激活或挂起流程
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=541589&itf=2432117
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/flowable/active': (req?: IModels['PATCH/flowable/active']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/flowable/active',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/flowable/active']>
    },

    /**
     * 接口名：设置审批人
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=541589&itf=2432472
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/flw_approval_users/set_approval': (
      req?: IModels['POST/flw_approval_users/set_approval']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/flw_approval_users/set_approval',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/flw_approval_users/set_approval']>
    },

    /**
     * 接口名：新建审批流程
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=541589&itf=2432898
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/flowable/template': (req?: IModels['POST/flowable/template']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/flowable/template',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/flowable/template']>
    },

    /**
     * 接口名：我的审批
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=541589&itf=2433112
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/flowable/owner_approval': (
      req?: IModels['POST/flowable/owner_approval']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/flowable/owner_approval',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/flowable/owner_approval']>
    },

    /**
     * 接口名：审批通过
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=541589&itf=2433173
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/flowable/deal': (req?: IModels['POST/flowable/deal']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/flowable/deal',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/flowable/deal']>
    },

    /**
     * 接口名：审批驳回
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=541589&itf=2433175
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/flowable/reBack': (req?: IModels['POST/flowable/reBack']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/flowable/reBack',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/flowable/reBack']>
    },

    /**
     * 接口名：获取所有审批流程
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=541589&itf=2433550
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/flw_approval_process/list': (
      req?: IModels['GET/flw_approval_process/list']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/flw_approval_process/list',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/flw_approval_process/list']>
    },

    /**
     * 接口名：新增/更新-审批模板关联模块
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=541589&itf=2433551
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/flw_relate_approvals': (req?: IModels['POST/flw_relate_approvals']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/flw_relate_approvals',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/flw_relate_approvals']>
    },

    /**
     * 接口名：所有现金流科目(下拉框)
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/cash_flow_subject': (req?: IModels['GET/cash_flow_subject']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/cash_flow_subject',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/cash_flow_subject']>
    },

    /**
     * 接口名：所有凭证科目(下拉框)
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/voucher_subject': (req?: IModels['GET/voucher_subject']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/voucher_subject',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/voucher_subject']>
    },

    /**
     * 接口名：设为默认流程
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=541589&itf=2434758
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/flw_approval_process/default_process': (
      req?: IModels['PATCH/flw_approval_process/default_process']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/flw_approval_process/default_process',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/flw_approval_process/default_process']>
    },

    /**
     * 接口名：获取所有流程
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=541589&itf=2435070
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/flw_approval_process/total_list': (
      req?: IModels['GET/flw_approval_process/total_list']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/flw_approval_process/total_list',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/flw_approval_process/total_list']>
    },

    /**
     * 接口名：费用报销列表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=541589&itf=2435094
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/invoice_expense_vouchers/claim/list': (
      req?: IModels['POST/invoice_expense_vouchers/claim/list']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/invoice_expense_vouchers/claim/list',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/invoice_expense_vouchers/claim/list']>
    },

    /**
     * 接口名：获取费用单详情
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=541589&itf=2435141
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/invoice_expense_vouchers/claim/{id}': (
      req?: IModels['GET/invoice_expense_vouchers/claim/{id}']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/invoice_expense_vouchers/claim/{id}',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/invoice_expense_vouchers/claim/{id}']>
    },

    /**
     * 接口名：流转中的流程图
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=541589&itf=2435293
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/flowable/diagramView': (req?: IModels['POST/flowable/diagramView']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/flowable/diagramView',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/flowable/diagramView']>
    },

    /**
     * 接口名：审批拒绝
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=541589&itf=2437971
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/flowable/reject': (req?: IModels['POST/flowable/reject']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/flowable/reject',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/flowable/reject']>
    },

    /**
     * 接口名：获取审批流程节点审批人的信息(日志)
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=541589&itf=2438119
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/flowable/approval': (req?: IModels['POST/flowable/approval']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/flowable/approval',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/flowable/approval']>
    },

    /**
     * 接口名：更新审批流程
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=541589&itf=2438363
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/flowable/template': (req?: IModels['PATCH/flowable/template']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/flowable/template',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/flowable/template']>
    },

    /**
     * 接口名：查看可以关联的报销单
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=541589&itf=2442432
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/invoice_expense_vouchers/link/bill': (
      req?: IModels['GET/invoice_expense_vouchers/link/bill']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/invoice_expense_vouchers/link/bill',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/invoice_expense_vouchers/link/bill']>
    },

    /**
     * 接口名：关联附件
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=541589&itf=2442449
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/invoices/link_voucher': (req?: IModels['PATCH/invoices/link_voucher']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/invoices/link_voucher',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/invoices/link_voucher']>
    },

    /**
     * 接口名：确认发票
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=541589&itf=2442467
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/invoices/confirm': (req?: IModels['PATCH/invoices/confirm']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/invoices/confirm',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/invoices/confirm']>
    },

    /**
     * 接口名：我的费用报销
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=541589&itf=2444877
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/invoice_expense_vouchers/owner/list': (
      req?: IModels['POST/invoice_expense_vouchers/owner/list']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/invoice_expense_vouchers/owner/list',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/invoice_expense_vouchers/owner/list']>
    },

    /**
     * 接口名：发票条形码扫描
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=541589&itf=2444946
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/invoice_expense_vouchers/remind/{id}': (
      req?: IModels['GET/invoice_expense_vouchers/remind/{id}']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/invoice_expense_vouchers/remind/{id}',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/invoice_expense_vouchers/remind/{id}']>
    },

    /**
     * 接口名：校验订单号和PM的关系
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/invoice_expense_vouchers/verificationOrderId': (
      req?: IModels['POST/invoice_expense_vouchers/verificationOrderId']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/invoice_expense_vouchers/verificationOrderId',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/invoice_expense_vouchers/verificationOrderId']>
    },

    /**
     * 接口名：获取团队审核人
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/organizations/teamReviewer': (
      req?: IModels['GET/organizations/teamReviewer']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/organizations/teamReviewer',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/organizations/teamReviewer']>
    },

    /**
     * 接口名：发送企微消息通知发起人补充发票
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/invoice_expense_vouchers/send_supplement_invoice': (
      req?: IModels['POST/invoice_expense_vouchers/send_supplement_invoice']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/invoice_expense_vouchers/send_supplement_invoice',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/invoice_expense_vouchers/send_supplement_invoice']>
    },

    /**
     * 接口名：修改报销单
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/invoice_expense_vouchers/update_voucher': (
      req?: IModels['PATCH/invoice_expense_vouchers/update_voucher']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/invoice_expense_vouchers/update_voucher',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/invoice_expense_vouchers/update_voucher']>
    },

    /**
     * 接口名：修改预算科目归届部门
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/invoice_expense_vouchers/update_department': (
      req?: IModels['PATCH/invoice_expense_vouchers/update_department']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/invoice_expense_vouchers/update_department',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/invoice_expense_vouchers/update_department']>
    },

    /**
     * 接口名：下载费用预算科目模板
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/invoice_expense_vouchers/budget/template': (
      req?: IModels['GET/invoice_expense_vouchers/budget/template']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/invoice_expense_vouchers/budget/template',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/invoice_expense_vouchers/budget/template']>
    },

    /**
     * 接口名：导入费用归属预算科目返回
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/invoice_expense_vouchers/return_budget': (
      req?: IModels['POST/invoice_expense_vouchers/return_budget']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/invoice_expense_vouchers/return_budget',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/invoice_expense_vouchers/return_budget']>
    },
  }
}