import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
// import zhCn from 'element-plus/es/locale/lang/zh-cn'
import Lang from './lang/index'
import 'element-plus/dist/index.css'
import './style/public.css'
import './permission'
import customForm from '@/components/CustomForm.vue'
import RowBtn from '@/components/TableRowHandleBtn.vue'
import TheUpBtn from '@/components/UploadButton.vue'
import eptBtn from '@/components/ExportButton.vue'
import direPermission from '@/directive/permission'
import showTooltip from '@/directive/showTooltip'
import decimal from '@/directive/decimal'
import throttle from '@/directive/throttle'
import * as ElIconModules from '@element-plus/icons-vue'
import ElTableInfiniteScroll from "el-table-infinite-scroll";
import { Boot } from '@wangeditor/editor'
import attachmentModule from '@wangeditor/plugin-upload-attachment'

// 注册。要在创建编辑器之前注册，且只能注册一次，不可重复注册。

Boot.registerModule(attachmentModule)

const app = createApp(App)
app.component('FlexibleForm', customForm)
app.component('RowBtn', RowBtn)
app.component('TheUpBtn', TheUpBtn)
app.component('eptBtn', eptBtn)
app.use(direPermission)
app.use(showTooltip)
app.use(decimal)
app.use(throttle)
app.use(ElTableInfiniteScroll);

for (const iconName in ElIconModules) {
  if (Reflect.has(ElIconModules, iconName)) {
    const item = ElIconModules[iconName as keyof typeof ElIconModules]
    app.component(iconName, item)
  }
}

app.use(ElementPlus, {
  locale: Lang['zh-CN'],
}).use(store).use(router).mount('#app')