/* 银行账户模块 */

import * as commonLib from '../modules/commonLib'
import {IResponseTypes, IModels} from './request'

export default function createFetch(fetchConfig: any) {
  const rapperFetch = commonLib.getRapperRequest(fetchConfig)

  return {
    /**
 * 接口名：添加银行账户
 * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=508002&itf=2247342
 * @param req 请求参数
 * @param extra 请求配置项
 */
    'POST/financial_banks': (req?: IModels['POST/financial_banks']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/financial_banks',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/financial_banks']>
    },

    /**
     * 接口名：修改银行账户
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=508002&itf=2247381
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/financial_banks': (req?: IModels['PATCH/financial_banks']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/financial_banks',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/financial_banks']>
    },

    /**
     * 接口名：删除银行账户
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=508002&itf=2247705
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'DELETE/financial_banks/{accountNumber}': (
      req?: IModels['DELETE/financial_banks/{accountNumber}']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/financial_banks/{accountNumber}',
        method: 'DELETE',
        params: req,
        extra,
      }) as Promise<IResponseTypes['DELETE/financial_banks/{accountNumber}']>
    },

    /**
     * 接口名：查询
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=508002&itf=2250489
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/financial_banks/conditions': (
      req?: IModels['POST/financial_banks/conditions']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/financial_banks/conditions',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/financial_banks/conditions']>
    },

    /**
     * 接口名：锁定和解锁接口
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=508002&itf=2251935
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/financial_banks/status': (
      req?: IModels['PATCH/financial_banks/status']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/financial_banks/status',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/financial_banks/status']>
    },

    /**
     * 接口名：汇率列表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=508002&itf=2275807
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/exchange_rates/conditions': (
      req?: IModels['POST/exchange_rates/conditions']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/exchange_rates/conditions',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/exchange_rates/conditions']>
    },

    /**
     * 接口名：导出银行账户
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=508002&itf=2320285
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/financial_banks/download': (
      req?: IModels['POST/financial_banks/download']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/financial_banks/download',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/financial_banks/download']>
    },

    /**
     * 接口名：汇率_添加
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=508002&itf=2392444
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/exchange_rates': (req?: IModels['POST/exchange_rates']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/exchange_rates',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/exchange_rates']>
    },

    /**
     * 接口名：银行账户_标记删除(启动，注销)
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=508002&itf=2392516
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/financial_banks/mark_deleted': (
      req?: IModels['PATCH/financial_banks/mark_deleted']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/financial_banks/mark_deleted',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/financial_banks/mark_deleted']>
    },

    /**
     * 接口名：账户余额_分页查询
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=508002&itf=2432157
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/financial_bank_balance/query': (
      req?: IModels['POST/financial_bank_balance/query']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/financial_bank_balance/query',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/financial_bank_balance/query']>
    },

    /**
     * 接口名：账户余额_导出
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=508002&itf=2432158
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/financial_bank_balance/download': (
      req?: IModels['POST/financial_bank_balance/download']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/financial_bank_balance/download',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/financial_bank_balance/download']>
    },
  }
}