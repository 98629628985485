import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, toHandlers as _toHandlers } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_cascader = _resolveComponent("el-cascader")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_option_group = _resolveComponent("el-option-group")!
  const _component_el_select = _resolveComponent("el-select")!

  return (_ctx.selectConfig.selectType === 'CASCADER')
    ? (_openBlock(), _createBlock(_component_el_cascader, _mergeProps({
        key: 0,
        modelValue: _ctx.dataObj[_ctx.selectConfig.upKey],
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dataObj[_ctx.selectConfig.upKey]) = $event)),
        class: "form-input",
        options: _ctx.selectConfig.options
      }, _ctx.selectConfig.props, { onChange: _ctx.changeHandle }), null, 16, ["modelValue", "options", "onChange"]))
    : (_ctx.selectConfig.selectType === 'GROUP')
      ? (_openBlock(), _createBlock(_component_el_select, _mergeProps({
          key: 1,
          modelValue: _ctx.dataObj[_ctx.selectConfig.upKey],
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.dataObj[_ctx.selectConfig.upKey]) = $event)),
          filterable: ""
        }, _ctx.selectConfig.props, _toHandlers(_ctx.selectConfig.events || {}), {
          style: {"width":"280px"},
          onChange: _ctx.changeHandle
        }), {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectConfig.options, (group) => {
              return (_openBlock(), _createBlock(_component_el_option_group, {
                label: group.label,
                key: group.label
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group.options, (item) => {
                    return (_openBlock(), _createBlock(_component_el_option, _mergeProps({
                      label: item[_ctx.selectConfig.seleProps.label] || item.name,
                      value: item[_ctx.selectConfig.seleProps.valueName] || item.id,
                      key: item[_ctx.selectConfig.seleProps.valueName] || item.id
                    }, item.props || {}), null, 16, ["label", "value"]))
                  }), 128))
                ]),
                _: 2
              }, 1032, ["label"]))
            }), 128))
          ]),
          _: 1
        }, 16, ["modelValue", "onChange"]))
      : (_openBlock(), _createBlock(_component_el_select, _mergeProps({
          key: 2,
          modelValue: _ctx.dataObj[_ctx.selectConfig.upKey],
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.dataObj[_ctx.selectConfig.upKey]) = $event)),
          filterable: ""
        }, _ctx.selectConfig.props, _toHandlers(_ctx.selectConfig.events || {}), {
          class: "form-input",
          onChange: _ctx.changeHandle
        }), {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectConfig.options, (item) => {
              return (_openBlock(), _createBlock(_component_el_option, _mergeProps({
                label: item[_ctx.selectConfig.seleProps.label] || item.label,
                key: item[_ctx.selectConfig.seleProps.valueName] || item.id,
                value: item[_ctx.selectConfig.seleProps.valueName] || item.id
              }, item.props || {}), null, 16, ["label", "value"]))
            }), 128))
          ]),
          _: 1
        }, 16, ["modelValue", "onChange"]))
}