
import { Search } from '@element-plus/icons-vue'
import { defineComponent, PropType, onBeforeMount, reactive, ref, onBeforeUnmount, onMounted } from 'vue'
import dayjs from 'dayjs'

import type { ElForm } from 'element-plus'
type FormInstance = InstanceType<typeof ElForm>
import asyncSelect from './AsyncDropdownSele.vue'
export default defineComponent({
    props: {
        title: {
            type: Boolean,
            default: false
        },
        searchConfig: {
            type: Array as PropType<any[]>,
            default: () => []
        },
        // 挂载搜索
        initSearch: {
            type: Boolean,
            default: false
        },
        submitText: {
            type: String,
            default: '搜索'
        },
        showSubmit: {
            type: Boolean,
            default: true
        },
        showRefresh: {
            type: Boolean,
            default: false
        },
        isLeft: {
            type: Boolean,
            default: false
        }
    },
    emits: ['searchFn','reset'],
    components: { asyncSelect },
    setup(props, context) {
        
        let formRef: any = ref<FormInstance>()
        let dataObj: any = reactive({})
        let rulesObj: any = reactive({})
        const timeKey: any = ref([])
        // promise链式调用，MI_modifyForm返回promise参数对象
        let callbackItem: any = () => {
            return {
                submitFunc: Promise.resolve(),
                callback: Promise.resolve(),
            }
        }

        // 初始化函数
        const initialization = () => {
            
            //生产表单规则，取出表单key和value
            props.searchConfig.reduce((_dataObj: any, _searchItem) => {

                _searchItem.upKey && (dataObj[_searchItem.upKey] = _searchItem.value)

                if(_searchItem.upProps && _searchItem.upProps.includeKey){
                    dataObj[_searchItem.upProps.includeKey] = {
                        from: _searchItem.value[0] || '', 
                        to: _searchItem.value[1] || ''
                    }
                } else if(_searchItem.upProps){
                    dataObj[_searchItem.upProps.startKey] = _searchItem.value[0] || ''
                    dataObj[_searchItem.upProps.endKey] = _searchItem.value[1] || ''
                    timeKey.value.push(_searchItem.upProps.startKey)
                    timeKey.value.push(_searchItem.upProps.endKey)
                }
                _searchItem.rule && (rulesObj[_searchItem.upKey] = _searchItem.rule)
                _searchItem.callback && ( callbackItem = _searchItem)

                return _dataObj
            }, {})
        }
        

        onBeforeMount(() => {
            initialization()
        })
        onBeforeUnmount(() => {
            props.searchConfig.map(e => {
                // e.value = typeof e.value === 'string' ? '' : []
                e.value = ''
            })
        })

        onMounted(() => {
            if(props.initSearch){
                submitHandle(formRef.value)
            }
        })

        // 刷新函数
        let eleKeys = ref(new Date().getTime())
        const refreshHandle = () => {
            eleKeys.value = new Date().getTime()
        }

        // 下拉框回调
        const selectChangeHandle = (configItem: any) => {
            // 下拉框change事件后，手动调用表单验证
            let {value, upKey} = configItem
            // dataObj[upKey] = value

            try {
                formRef.value.validateField(upKey)
            } catch (error) {
                
            }
            
        }
        // 远程搜索下拉框
        const remoteHandle = (query: string , item: any) => {
            
            if (query) {

                item.selectFunc({
                    [item.funcParam.upKey]: query
                })
            } else {
                item.option = []
            }
        }
        // 搜索按钮回掉
        let searchBtnFg = ref<boolean>(false)
        const submitHandle = (formEl?: FormInstance  ) => {            

            return formRef.value.validate(async (valid: boolean)=> {
                if(!valid) return;
                // if(callbackItem && callbackItem.submitFunc){
                try {
                    searchBtnFg.value = true
                    await callbackItem.submitFunc(dataObj)
                    searchBtnFg.value = false
                } catch (error) {
                    searchBtnFg.value = false
                }
                // }
                context.emit('searchFn', dataObj)
            
            }).then(() => {
                callbackItem.callback && callbackItem.callback(dataObj)
                return dataObj
            })
        }

        const defaultTimeComputed = (config: FromConfig) => {
            let { props } = config
            if(props && props['type'] === 'daterange'){
                return [new Date(2000, 1, 1, 0, 0, 0), new Date(2000, 2, 1, 23, 59, 59)]
            }
            return [new Date(2000, 1, 1, 0, 0, 0)]
        }
        const defaultValueComputed = (config: FromConfig) => {
            let { props } = config
            if(props && props['type'] === 'daterange'){
                return [
                    dayjs().subtract(1, 'months').startOf('month').toDate(), 
                    dayjs().subtract(0, 'months').startOf('month').toDate()
                ]
            }
            return new Date()
        }
        const timeChange = (_time: any, config: FromConfig) => {
            
            let { props, upProps } = config
            config.value = _time
            
            if(upProps && typeof upProps.includeKey === 'string'){
                dataObj[upProps.includeKey] = {from: _time ? _time[0] :  '', to: _time ? _time[1] :  ''}
            } else if(upProps && props && (props['type'] === 'daterange' ||  props['type'] === 'monthrange')){
                
                dataObj[upProps.startKey] = _time ? _time[0] :  ''
                dataObj[upProps.endKey] = _time ? _time[1] :  ''
            }
            config.timeCb && config.timeCb(_time, config.upKey)
        }

        const inputChnageHandle = (val: string | number, config?: FromConfig) => {
            config ? config.value = val : false
            if(config && config.inputCb){
                config.inputCb(val)
            }
        }
        // 重置校验
        const resetForm = () => {
            formRef.value.resetFields()
            timeKey.value.map((key: string) => {
                if(dataObj.hasOwnProperty(key)){
                    dataObj[key] = ''
                }
            })
            context.emit('reset')
        }


        
        return {
            formRef,
            dataObj,
            rulesObj,
            selectChangeHandle,
            Search,
            remoteHandle,
            submitHandle,
            defaultTimeComputed,
            defaultValueComputed,
            timeChange,
            searchBtnFg,
            inputChnageHandle,
            refreshHandle,
            eleKeys,
            resetForm,
        }
    }
})
