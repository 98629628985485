import axios from '@/http/index'
import {preParams, Extra, BasicTableResult} from "../index";
// import {} from './model/individualModel';

export default {
  /**
   * @description: 获取-项目列表
   * @param {number} page
   * @param {number} size
   * @return {void}
   */
  'GET/projects': (params: any, extra?: Extra) => {
    return axios.get(`/projects`, preParams(params, extra, 'get'))
  },

  /**
   * @description: 新增-项目
   * @param {string} name 项目名
   * @param {string} purpose 项目ID
   * @param {string} startDate 开始时间
   * @param {string} endDate 结束时间
   * @param {string} note 备注
   * @return {void}
   */
  'POST/projects': (params: any, extra?: Extra) => {
    return axios(`/projects`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 更新-项目
   * @param {number} id
   * @param {string} name 项目名
   * @param {string} purpose 项目ID
   * @param {string} startDate 开始时间
   * @param {string} endDate 结束时间
   * @param {string} note 备注
   * @return {void}
   */
  'PATCH/projects': (params: any, extra?: Extra) => {
    return axios(`/projects`, preParams(params, extra, 'patch'))
  },

  /**
   * @description: 删除-项目
   * @param {number} id
   * @return {void}
   */
  'DELETE/projects/{id}': (params: {id: number}) => axios.delete(`/projects/${params.id}`),

  /**
   * @description: 获取-项目成员列表
   * @param {number} projectId 项目ID
   * @return {void}
   */
  'POST/project_members/list': (params: {projectId: number}, extra?: Extra) => {
    return axios(`/project_members/list`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 获取-项目成本人员勾选信息
   * @param {number} page 页数
   * @param {number} size 条数
   * @param {number} projectId 项目ID
   * @param {number} userId 用户ID
   * @param {number} orgId 团队ID
   * @param {array} companyIds 公司ids
   * @param {string} date 日期
   * @param {string} key 姓名
   * @return {void}
   */
  'POST/project_cost_time/user': (params: {userId: number, date: string, projectId: number, key: string, orgId: number}, extra?: Extra) => {
    return axios(`/project_cost_time/user`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 产生个人项目参与时间
   * @return {void}
   */
  'POST/project_cost_time/generate': (params: any, extra?: Extra) => {
    return axios(`/project_cost_time/generate`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 获取-项目成本人员勾选列表
   * @return {void}
   */
  'POST/project_cost_time/click': (params: any, extra?: Extra) => {
    return axios(`/project_cost_time/click`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 新增-项目成员
   * @param {number} userId 员工ID
   * @param {number} projectId 项目ID
   * @param {string} startDate 入项时间
   * @param {string} endDate 离项时间
   * @return {void}
   */
  'POST/project_members': (params: {userId: number, startDate: string, endDate: string, projectId: number}, extra?: Extra) => {
    return axios(`/project_members`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 更新-项目成员
   * @param {number} userId 员工ID
   * @param {number} projectId 项目ID
   * @param {string} startDate 入项时间
   * @param {string} endDate 离项时间
   * @return {void}
   */
  'PATCH/project_members': (params: {userId: number, startDate: string, endDate: string, projectId: number}, extra?: Extra) => {
    return axios(`/project_members`, preParams(params, extra, 'patch'))
  },

  /**
   * @description: 删除-项目成员
   * @param {number} id
   * @return {void}
   */
  'DELETE/project_members/{id}': (params: {id: number}) => axios.delete(`/project_members/${params.id}`),

  /**
   * @description: 展示研发项目成本
   * @param {object} dateRange 时间
   * @param {string} type 类型
   * @param {number} companyId 公司Id
   * @return {void}
   */
  'POST/project_cost_details/list': (params: {dateRange: object, companyId: number, type: string}, extra?: Extra) => {
    return axios(`/project_cost_details/list`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 获取研发工时
   * @param {object} dateRange 时间
   * @param {string} key 姓名
   * @return {void}
   */
  'POST/project_cost_time/time': (params: {dateRange: object, key: string}, extra?: Extra) => {
    return axios(`/project_cost_time/time`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 获取成本列表
   * @param {string} date 日期
   * @param {string} key 姓名
   * @param {number} categoryId 导入项目
   * @param {number} page
   * @param {number} size
   * @return {void}
   */
  'POST/project_cost': (params: {date: string, key: string, categoryId: number}, extra?: Extra) => {
    return axios(`/project_cost`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 获取成本列表
   * @param {string} date 日期
   * @return {void}
   */
  'POST/project_cost/salary': (params: {date: string}, extra?: Extra) => {
    return axios(`/project_cost/salary`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 批量按月导出研发工时表并打包压缩文件
   * @return {void}
   */
  'POST/project_cost_time/export': (params: any, extra?: Extra) => {
    return axios(`/project_cost_time/export`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 批量设置人员归属项目
   * @return {void}
   */
  'POST/project_members/batch_set': (params: any, extra?: Extra) => {
    return axios(`/project_members/batch_set`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 修改分摊日期
   * @param {number} id
   * @param {string} startDate 受益结束日期
   * @param {string} endDate 受益开始日期
   * @return {void}
   */
  'PATCH/project_cost': (params: {startDate: string, endDate: string, id: number}, extra?: Extra) => {
    return axios(`/project_cost`, preParams(params, extra, 'patch'))
  },

  /**
   * @description: 锁定项目成本
   * @param {object} dates 受益结束日期
   * @param {boolean} flag 是否锁定
   * @return {void}
   */
  'PATCH/project_cost_time/locked_time': (params: {dates: object, flag: boolean}, extra?: Extra) => {
    return axios(`/project_cost_time/locked_time`, preParams(params, extra, 'patch'))
  },

  /**
   * @description: 根据人员获取所在项目
   * @param {number} userId 用户ID
   * @return {void}
   */
  'GET/projects/user': (params: {userId: number}, extra?: Extra) => {
    return axios.get(`/projects/user`, preParams(params, extra, 'get'))
  },

  /**
   * @description: 获取未设置研发项目人员
   * @param {number} userId 用户ID
   * @return {void}
   */
  'GET/projects/unset': (params: {userId: number}, extra?: Extra) => {
    return axios.get(`/projects/unset`, preParams(params, extra, 'get'))
  },

  /**
   * @description: 个人研发项目
   * @return {void}
   */
  'POST/project_cost_time/owner': (params: any, extra?: Extra) => {
    return axios(`/project_cost_time/owner`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 取消工时确认
   * @param {number} userId
   * @param {string} date 日期
   * @return {void}
   */
  'PATCH/project_cost_time/cancel_time': (params: {userId: number, date: string}, extra?: Extra) => {
    return axios(`/project_cost_time/cancel_time`, preParams(params, extra, 'patch'))
  },
}