function processRestfulUrl(url: string, params: any) {
  // 只处理 object
  if (Object.prototype.toString.call(params) === '[object Object]') {
    let urlSplit = url.split('/');
    let newParams = Object.assign({}, params);
    for (let i = 0; i < urlSplit.length; ++i) {
      let part = urlSplit[i];
      // 兼容 oneApi 与 egg 两种 模式的 params 传参 例： :appId / {appId}
      let matchKeys = part.match(/(?:\{(.*)\}|\:(.*))/);
      if (!matchKeys)
        continue;
      let key = matchKeys[1] || matchKeys[2];
      if (!params.hasOwnProperty(key)) {
        console.warn('Please set value for template key: ', key);
        continue;
      }
      urlSplit[i] = params[key];
      delete newParams[key];
    }
    return { url: urlSplit.join('/'), params: newParams };
  }
  return { url: url, params: params };
}
/**
* 包装请求函数，预处理 Restful API 的 url，把 params 塞到 url 里面
*/
function wrapPreProcessRestfulUrl(fetch: any) {
  return function (fetchParams: any) {
    return fetch(Object.assign(Object.assign({}, fetchParams), processRestfulUrl(fetchParams.url, fetchParams.params)));
  };
}

export const getRapperRequest = function (fetchConfig: any) {
  let rapperFetch;
  if (typeof fetchConfig === 'function') {
    rapperFetch = fetchConfig;
  }
  return wrapPreProcessRestfulUrl(rapperFetch);
};

/** 请求的额外参数类型 */
export interface IExtra {
  /**
   * 请求的类型，默认不传 代表redux请求，会发送 Action，也存入redux store
   * normal 代表普通请求，不发送 Action，也不存入redux store
   * redux 代表redux请求，会发送 Action，也存入redux store
   */
  type?: 'normal' | 'redux';
  /**
   * 请求头 content-type，默认是 'application/json'
   */
  contentType?: 'application/json' | 'multipart/form-data' | 'application/x-www-form-urlencoded' | 'text/plain' | 'text/html' | 'application/javascript';
  /**
   * 请求 url 后面拼接的 query 参数，比如 POST 请求需要拼接 token 参数
   */
  query?: {
      [key: string]: any;
  };
  /**
   * 用户自定义的queryString函数，默认使用JSON.stringify处理，例如 { a: 1, b: 2 } 结果是 a=1&b=2，可以替换成 qs.stringify
   */
  queryStringFn?: (input: any[] | object) => string;
  /** 扩展字段 */
  [key: string]: any;
}