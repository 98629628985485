import axios from '@/http/index'
import {preParams, Extra, BasicTableResult} from "../index";
import {
  MteSearch
} from './model/reportModel';

export default {
  /**
   * @description: MTE报表
   * @param {}
   * @return {array}
   */
  'POST/mte_report/mte_report': (params: MteSearch, extra?: Extra) => {
    return axios(`/mte_report/mte_report`, preParams(params, extra, 'post'))
  },
  /**
   * @description: 预算维护_预算记录查询
   * @param {}
   * @return {array}
   */
  'GET/budget/{id}/modify_record': (params: any, extra?: Extra) => {
    return axios(`/budget/${params.id}/modify_record`, preParams({}, extra, 'get'))
  },
  /**
   * @description: 加班报表_类型下拉框
   * @param {}
   * @return {array}
   */
  'GET/work_overtime/type_drop_list': (params: any, extra?: Extra) => {
    return axios(`/work_overtime/type_drop_list`, preParams({}, extra, 'get'))
  },
  /**
   * @description: 加班报表_报表
   * @param {}
   * @return {array}
   */
  'POST/work_overtime/report': (params: any, extra?: Extra) => {
    return axios(`/work_overtime/report`, preParams(params, extra, 'post'))
  },
  /**
   * @description: 加班报表_用户加班时长分页查询
   * @param {}
   * @return {array}
   */
  'POST/work_overtime/report/users': (params: any, extra?: Extra) => {
    return axios(`/work_overtime/report/users`, preParams(params, extra, 'post'))
  }
}
  
