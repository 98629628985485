import axios from '@/http/index'
import {preParams, Extra, BasicTableResult} from "../index";
// import {} from './model/individualModel';

export default {
    /**
     * @description: 支付池-转薪酬查询
     * @return {void}
    */
    'POST/pay_pool/transfer_payroll/query': (params: any, extra?: Extra) => {
        return axios(`/pay_pool/transfer_payroll/query`, preParams(params, extra, 'post'))
    },
    /**
     * @description: 支付池-转薪酬
     * @return {void}
    */
    'POST/pay_pool/transfer_payroll': (params: any, extra?: Extra) => {
        return axios(`/pay_pool/transfer_payroll`, preParams(params, extra, 'post'))
    },

    /**
     * @description: 支付池 绑定交易记录
     * @param: {number} Id
     * @param: {number} flwoId
     * @return {void}
    */
    'POST/pay_pool/bind': (params: any, extra?: Extra) => {
        return axios(`/pay_pool/bind`, preParams(params, extra, 'post'))
    },
    
    /**
     * @description: 支付池_交易记录明细查询
     * @param: {number} Id
     * @return {void}
    */
    'POST/pay_pool/flow_detail': (params: any, extra?: Extra) => {
      return axios(`/pay_pool/flow_detail`, preParams(params, extra, 'post'))
    },
        
    /**
     * @description: 支付池_解绑交易记录(删除)
     * @param: {number} flowId
     * @return {void}
    */
    'POST/pay_pool/unbind': (params: {flowId: number}, extra?: Extra) => {
      return axios(`/pay_pool/unbind`, preParams(params, extra, 'post'))
    },
    /**
     * @description: 单据审批-批量设置是否需要绑定流水
     * @return {void}
    */
    'PATCH/invoice_expense_vouchers/need_bind_flow': (params: {flowId: number}, extra?: Extra) => {
      return axios(`/invoice_expense_vouchers/need_bind_flow`, preParams(params, extra, 'patch'))
    },


}