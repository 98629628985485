import axios from '@/http/index'
import {preParams, Extra, BasicTableResult} from "../index";
// import {} from './model/individualModel';

export default {
  /**
   * @description: 内部交易_查询
   * @return {void}
   */
  'POST/order_profit/transaction/query': (params: any, extra?: Extra) => {
    return axios(`/order_profit/transaction/query`, preParams(params, extra, 'post'))
  },
}