/* 银行卡模块 */

import * as commonLib from '../modules/commonLib'
import {IResponseTypes, IModels} from './request'

export default function createFetch(fetchConfig: any) {
  const rapperFetch = commonLib.getRapperRequest(fetchConfig)

  return {
    /**
     * 接口名：获取-个人银行卡列表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=530544&itf=2374236
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/users/bank_cards/personal': (
      req?: IModels['GET/users/bank_cards/personal']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/users/bank_cards/personal',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/users/bank_cards/personal']>
    },

    /**
     * 接口名：新增-个人银行卡
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=530544&itf=2374215
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/users/bank_cards': (req?: IModels['POST/users/bank_cards']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/users/bank_cards',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/users/bank_cards']>
    },

    /**
     * 接口名：修改-个人银行卡
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=530544&itf=2374237
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/users/bank_cards': (req?: IModels['PATCH/users/bank_cards']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/users/bank_cards',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/users/bank_cards']>
    },

    /**
     * 接口名：单个删除-个人银行卡
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=530544&itf=2374244
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'DELETE/users/bank_cards/{id}': (
      req?: IModels['DELETE/users/bank_cards/{id}']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/users/bank_cards/{id}',
        method: 'DELETE',
        params: req,
        extra,
      }) as Promise<IResponseTypes['DELETE/users/bank_cards/{id}']>
    },

    /**
     * 接口名：获取-银行列表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=530544&itf=2374261
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/banks/list': (req?: IModels['POST/banks/list']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/banks/list',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/banks/list']>
    },

    /**
     * 接口名：新增-银行
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=530544&itf=2374258
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/banks': (req?: IModels['POST/banks']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/banks',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/banks']>
    },

    /**
     * 接口名：修改-银行
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=530544&itf=2374259
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/banks': (req?: IModels['PATCH/banks']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/banks',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/banks']>
    },

    /**
     * 接口名：获取所有用户银行卡(加权限 bank_card)
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=530544&itf=2374414
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/users/bank_cards/list': (req?: IModels['POST/users/bank_cards/list']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/users/bank_cards/list',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/users/bank_cards/list']>
    },

    /**
     * 接口名：批量确认-个人银行卡
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=530544&itf=2374248
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/users/bank_cards/batch': (
      req?: IModels['PATCH/users/bank_cards/batch']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/users/bank_cards/batch',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/users/bank_cards/batch']>
    },

    /**
     * 接口名：导入银行卡
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=530544&itf=2374464
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/users/bank_cards/import': (
      req?: IModels['POST/users/bank_cards/import']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/users/bank_cards/import',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/users/bank_cards/import']>
    },

    /**
     * 接口名：批量插入
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=530544&itf=2374466
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/users/bank_cards/batch_insert': (
      req?: IModels['POST/users/bank_cards/batch_insert']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/users/bank_cards/batch_insert',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/users/bank_cards/batch_insert']>
    },

    /**
     * 接口名：导出银行卡
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=530544&itf=2376005
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/users/bank_cards/export': (
      req?: IModels['POST/users/bank_cards/export']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/users/bank_cards/export',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/users/bank_cards/export']>
    },

    /**
     * 接口名：下载银行卡模板
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=530544&itf=2376006
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/users/bank_cards/download': (
      req?: IModels['GET/users/bank_cards/download']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/users/bank_cards/download',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/users/bank_cards/download']>
    },

    /**
     * 接口名：批量使用
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=530544&itf=2376463
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/users/bank_cards/use': (req?: IModels['PATCH/users/bank_cards/use']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/users/bank_cards/use',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/users/bank_cards/use']>
    },

    /**
     * 接口名：关闭和启用银行卡
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=530544&itf=2376479
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/users/bank_cards/convert': (
      req?: IModels['PATCH/users/bank_cards/convert']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/users/bank_cards/convert',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/users/bank_cards/convert']>
    },

    /**
     * 接口名：银行卡设置比例
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=530544&itf=2379664
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/users/bank_cards/setRatio': (
      req?: IModels['PATCH/users/bank_cards/setRatio']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/users/bank_cards/setRatio',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/users/bank_cards/setRatio']>
    },

    /**
     * 接口名：查询未录入银行卡人员
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=530544&itf=2381013
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/users/bank_cards/unset': (req?: IModels['POST/users/bank_cards/unset']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/users/bank_cards/unset',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/users/bank_cards/unset']>
    },

    /**
     * 接口名：用户银行卡编辑（管理）
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=530544&itf=2391166
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/users/bank_cards/update_admin': (
      req?: IModels['PATCH/users/bank_cards/update_admin']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/users/bank_cards/update_admin',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/users/bank_cards/update_admin']>
    },

    /**
     * 接口名：根据userId获取银行卡信息
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=530544&itf=2426430
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/users/bank_cards/{userId}': (
      req?: IModels['GET/users/bank_cards/{userId}']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/users/bank_cards/{userId}',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/users/bank_cards/{userId}']>
    },

    /**
     * 接口名：银行维护-删除银行
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=530544&itf=2445258
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'DELETE/banks/{id}': (req?: IModels['DELETE/banks/{id}']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/banks/{id}',
        method: 'DELETE',
        params: req,
        extra,
      }) as Promise<IResponseTypes['DELETE/banks/{id}']>
    },
  }
}