const getters = {
    currentRoute: (state: any): any => state.path,
    token: (state: any) => state.token,
    userId: (state: any) => Number(state.userId),
    userName: (state: any) => state.userName,
    sidebarRouters: (state: any) => state.permissionMd.sidebarRouters,
    privileges: (state: any) => state.permissionMd.privileges,
    resources: (state: any) => state.permissionMd.resources,
    weCom: (state: any) => state.permissionMd.weCom,
    personalMessage: (state: any): any => state.messageMd.personalMessageArr,
    noticMessage: (state: any): any => state.messageMd.noticMessageArr,
    approveNumber: (state: any): any => state.approveNumber,

    sysCompany: (state: any): any => state.sysCompany,
    sysUsers: (state: any): any => state.sysUsers,
    sysOrganizations: (state: any): any => state.sysOrganizations,
}

export default getters