/* 收入成本,团队利润模块 */

import * as commonLib from '../modules/commonLib'
import {IResponseTypes, IModels} from './request'

export default function createFetch(fetchConfig: any) {
  const rapperFetch = commonLib.getRapperRequest(fetchConfig)

  return {
    /**
     * 接口名：获取订单毛利信息
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=532130&itf=2382708
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/order_profit/list': (req?: IModels['POST/order_profit/list']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/order_profit/list',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/order_profit/list']>
    },

    /**
     * 接口名：收入 成本确认
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=532130&itf=2388946
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/order_profit/sum': (req?: IModels['POST/order_profit/sum']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/order_profit/sum',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/order_profit/sum']>
    },

    /**
     * 接口名：内部交易计算
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=532130&itf=2390083
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/order_profit/transaction': (
      req?: IModels['POST/order_profit/transaction']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/order_profit/transaction',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/order_profit/transaction']>
    },

    /**
     * 接口名：导出收入成本报表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=532130&itf=2390170
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/order_profit/export': (req?: IModels['POST/order_profit/export']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/order_profit/export',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/order_profit/export']>
    },

    /**
     * 接口名：获取客户名称或客户id得下拉
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=532130&itf=2391757
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/order/customer_name': (req?: IModels['GET/order/customer_name']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/order/customer_name',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/order/customer_name']>
    },

    /**
     * 接口名：销售员下拉框
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=532130&itf=2391758
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/order/salesperson': (req?: IModels['GET/order/salesperson']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/order/salesperson',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/order/salesperson']>
    },

    /**
     * 接口名：团队余额-获取团队
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=532130&itf=2401852
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/order_profit/resources_org': (
      req?: IModels['GET/order_profit/resources_org']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/order_profit/resources_org',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/order_profit/resources_org']>
    },

    /**
     * 接口名：团队余额-根据团队获取信息
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=532130&itf=2401856
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/order_profit/org': (req?: IModels['POST/order_profit/org']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/order_profit/org',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/order_profit/org']>
    },

    /**
     * 接口名：团队余额,薪酬穿透
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=532130&itf=2402009
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/salaries/distributes/org/salary': (
      req?: IModels['POST/salaries/distributes/org/salary']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/salaries/distributes/org/salary',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/salaries/distributes/org/salary']>
    },

    /**
     * 接口名：团队利润总报表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=532130&itf=2403259
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/order_profit/org_report': (
      req?: IModels['POST/order_profit/org_report']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/order_profit/org_report',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/order_profit/org_report']>
    },

    /**
     * 接口名：订单类别报表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=532130&itf=2419054
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/order_profit/order_category_report': (
      req?: IModels['POST/order_profit/order_category_report']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/order_profit/order_category_report',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/order_profit/order_category_report']>
    },

    /**
     * 接口名：订单查询
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=532130&itf=2432427
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/order_cost/query_order': (req?: IModels['POST/order_cost/query_order']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/order_cost/query_order',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/order_cost/query_order']>
    },

    /**
     * 接口名：订单导出
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=532130&itf=2432429
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/order_cost/export_order': (
      req?: IModels['POST/order_cost/export_order']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/order_cost/export_order',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/order_cost/export_order']>
    },

    /**
     * 接口名：收入成本明细
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=532130&itf=2449483
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/order_profit/cost_breakdown': (
      req?: IModels['POST/order_profit/cost_breakdown']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/order_profit/cost_breakdown',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/order_profit/cost_breakdown']>
    },

    /**
     * 接口名：相同订单发票主体不一致(内部交易)
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=532130&itf=2451476
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/order_profit/inconsistent': (
      req?: IModels['POST/order_profit/inconsistent']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/order_profit/inconsistent',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/order_profit/inconsistent']>
    },

    /**
     * 接口名：存货余额分析
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/order_profit/goodsCost': (
      req?: IModels['POST/order_profit/goodsCost']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/order_profit/goodsCost',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/order_profit/goodsCost']>
    },

    /**
     * 接口名：存货余额分析导出
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/order_profit/export_costOrder': (
      req?: IModels['POST/order_profit/export_costOrder']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/order_profit/export_costOrder',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/order_profit/export_costOrder']>
    },

    /**
     * 接口名：销售订单分析
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/order_profit/saleOrder': (
      req?: IModels['POST/order_profit/saleOrder']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/order_profit/saleOrder',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/order_profit/saleOrder']>
    },

    /**
     * 接口名：销售订单分析状态
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/order/status_list': (
      req?: IModels['GET/order/status_list']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/order/status_list',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/order/status_list']>
    },
  }
}