/* 待支付池模块 */

import * as commonLib from '../modules/commonLib'
import {IResponseTypes, IModels} from './request'

export default function createFetch(fetchConfig: any) {
  const rapperFetch = commonLib.getRapperRequest(fetchConfig)

  return {
    /**
 * 接口名：同步旧co数据
 * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=511302&itf=2265987
 * @param req 请求参数
 * @param extra 请求配置项
 */
    'POST/reimburse/synchro': (req?: IModels['POST/reimburse/synchro']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/reimburse/synchro',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/reimburse/synchro']>
    },

    /**
     * 接口名：条件查询
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=511302&itf=2266804
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/pay_pool/conditions': (req?: IModels['POST/pay_pool/conditions']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/pay_pool/conditions',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/pay_pool/conditions']>
    },

    /**
     * 接口名：修改紧急状态
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=511302&itf=2266845
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/pay_pool/urgent': (req?: IModels['PATCH/pay_pool/urgent']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/pay_pool/urgent',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/pay_pool/urgent']>
    },

    /**
     * 接口名：下拉框
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=511302&itf=2267857
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/pay_pool/drop_list': (req?: IModels['GET/pay_pool/drop_list']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/pay_pool/drop_list',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/pay_pool/drop_list']>
    },

    /**
     * 接口名：批量转账接口
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=511302&itf=2277978
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/pay_pool/batch_pay': (req?: IModels['POST/pay_pool/batch_pay']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/pay_pool/batch_pay',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/pay_pool/batch_pay']>
    },

    /**
     * 接口名：支付检测接口（去支付）
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=511302&itf=2278321
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/pay_pool/prepare_pay': (req?: IModels['POST/pay_pool/prepare_pay']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/pay_pool/prepare_pay',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/pay_pool/prepare_pay']>
    },

    /**
     * 接口名：修改备注
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=511302&itf=2280897
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/pay_pool/update_note': (req?: IModels['PATCH/pay_pool/update_note']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/pay_pool/update_note',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/pay_pool/update_note']>
    },

    /**
     * 接口名：当前银行账户是否支持直连
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=511302&itf=2282930
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/pay_pool/directed': (req?: IModels['POST/pay_pool/directed']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/pay_pool/directed',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/pay_pool/directed']>
    },

    /**
     * 接口名：内部转账
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=511302&itf=2284022
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/pay_pool/internal_pay': (req?: IModels['POST/pay_pool/internal_pay']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/pay_pool/internal_pay',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/pay_pool/internal_pay']>
    },

    /**
     * 接口名：条件查询下拉
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=511302&itf=2285350
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/pay_pool/conditions_drop_box': (
      req?: IModels['GET/pay_pool/conditions_drop_box']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/pay_pool/conditions_drop_box',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/pay_pool/conditions_drop_box']>
    },

    /**
     * 接口名：审批
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=511302&itf=2290960
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/pay_pool/approved': (req?: IModels['PATCH/pay_pool/approved']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/pay_pool/approved',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/pay_pool/approved']>
    },

    /**
     * 接口名：列表接口
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=511302&itf=2293020
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/pay_pool/list': (req?: IModels['POST/pay_pool/list']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/pay_pool/list',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/pay_pool/list']>
    },

    /**
     * 接口名：重新支付
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=511302&itf=2314743
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/pay_pool/repayments': (req?: IModels['PATCH/pay_pool/repayments']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/pay_pool/repayments',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/pay_pool/repayments']>
    },

    /**
     * 接口名：导出单据+ 发票接口
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=511302&itf=2319772
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/invoice/download': (req?: IModels['POST/invoice/download']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/invoice/download',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/invoice/download']>
    },

    /**
     * 接口名：修改账单银行信息
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=511302&itf=2343294
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/api/pay_pool/update_bank': (
      req?: IModels['PATCH/api/pay_pool/update_bank']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/api/pay_pool/update_bank',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/api/pay_pool/update_bank']>
    },

    /**
     * 接口名：向企业微信发送消息修改某账单账户信息副本
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=511302&itf=2343638
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/api/pay_pool/send_msg': (req?: IModels['POST/api/pay_pool/send_msg']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/api/pay_pool/send_msg',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/api/pay_pool/send_msg']>
    },

    /**
     * 接口名：单个查询账单信息
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=511302&itf=2390278
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/pay_pool/get/{id}': (req?: IModels['GET/pay_pool/get/{id}']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/pay_pool/get/{id}',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/pay_pool/get/{id}']>
    },

    /**
     * 接口名：清单统计-公司
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=511302&itf=2400611
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/salaries/distributes/companies/statistical': (
      req?: IModels['POST/salaries/distributes/companies/statistical']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/salaries/distributes/companies/statistical',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/salaries/distributes/companies/statistical']>
    },

    /**
     * 接口名：清单下拉列表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=511302&itf=2405643
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/salaries/distributes/select_list': (
      req?: IModels['GET/salaries/distributes/select_list']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/salaries/distributes/select_list',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/salaries/distributes/select_list']>
    },

    /**
     * 接口名：导出发票管理
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=511302&itf=2422468
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/invoice/invoice_download': (
      req?: IModels['POST/invoice/invoice_download']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/invoice/invoice_download   ',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/invoice/invoice_download']>
    },

    /**
     * 接口名：修改账单
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=511302&itf=2429812
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/pay_pool/send_msg': (req?: IModels['POST/pay_pool/send_msg']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/pay_pool/send_msg',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/pay_pool/send_msg']>
    },

    /**
 * 接口名：条件查询
 * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=514688&itf=2284977
 * @param req 请求参数
 * @param extra 请求配置项
 */
    'POST/pay_record/conditions': (req?: IModels['POST/pay_record/conditions']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/pay_record/conditions',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/pay_record/conditions']>
    },

    /**
     * 接口名：关单
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=514688&itf=2285065
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/pay_record/close_record': (
      req?: IModels['POST/pay_record/close_record']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/pay_record/close_record',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/pay_record/close_record']>
    },

    /**
     * 接口名：查询支付详情
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=514688&itf=2295786
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/pay_record/find_children': (
      req?: IModels['GET/pay_record/find_children']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/pay_record/find_children',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/pay_record/find_children']>
    },

    /**
     * 接口名：手动同步支付结果
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=514688&itf=2334429
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/pay_record/payment_call_back': (
      req?: IModels['POST/pay_record/payment_call_back']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/pay_record/payment_call_back',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/pay_record/payment_call_back']>
    },

    /**
     * 接口名：获取收款公司下拉
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=514688&itf=2341891
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/pay_pool/payer_company_list': (
      req?: IModels['GET/pay_pool/payer_company_list']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/pay_pool/payer_company_list',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/pay_pool/payer_company_list']>
    },
  }
}