/* 组织架构模块 */

import * as commonLib from '../modules/commonLib'
import {IResponseTypes, IModels} from './request'

export default function createFetch(fetchConfig: any) {
  const rapperFetch = commonLib.getRapperRequest(fetchConfig)

  return {
    /**
     * 接口名：根据团队id查找所有团员
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=514235&itf=2282270
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/org_user': (req?: IModels['GET/org_user']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/org_user',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/org_user']>
    },

    /**
     * 接口名：获取团队列表(树状)
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=514235&itf=2283648
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/organizations/tree': (req?: IModels['GET/organizations/tree']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/organizations/tree',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/organizations/tree']>
    },

    /**
     * 接口名：获取所有团队
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=514235&itf=2295648
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/organizations': (req?: IModels['GET/organizations']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/organizations',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/organizations']>
    },

    /**
     * 接口名：公司名称修改
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=514235&itf=2341746
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/companies': (req?: IModels['PATCH/companies']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/companies',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/companies']>
    },

    /**
     * 接口名：公司删除
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=514235&itf=2341753
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'DELETE/companies/{id}': (req?: IModels['DELETE/companies/{id}']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/companies/{id}',
        method: 'DELETE',
        params: req,
        extra,
      }) as Promise<IResponseTypes['DELETE/companies/{id}']>
    },

    /**
     * 接口名：新增公司
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=514235&itf=2341757
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/companies': (req?: IModels['POST/companies']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/companies',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/companies']>
    },

    /**
     * 接口名：译员_条件查询
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=514235&itf=2357692
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/translator/query': (req?: IModels['POST/translator/query']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/translator/query',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/translator/query']>
    },

    /**
     * 接口名：译员_添加
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=514235&itf=2357694
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/translator': (req?: IModels['POST/translator']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/translator',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/translator']>
    },

    /**
     * 接口名：译员_修改
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=514235&itf=2357695
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/translator': (req?: IModels['PATCH/translator']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/translator',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/translator']>
    },

    /**
     * 接口名：译员_删除
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=514235&itf=2357696
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'DELETE/translator': (req?: IModels['DELETE/translator']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/translator',
        method: 'DELETE',
        params: req,
        extra,
      }) as Promise<IResponseTypes['DELETE/translator']>
    },

    /**
     * 接口名：获取所有公司（树状）
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=514235&itf=2341767
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/companies': (req?: IModels['GET/companies']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/companies',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/companies']>
    },

    /**
     * 接口名：部门列表（树状）
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=514235&itf=2341779
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/departments/tree': (req?: IModels['GET/departments/tree']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/departments/tree',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/departments/tree']>
    },

    /**
     * 接口名：岗位列表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=514235&itf=2341783
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/jobs': (req?: IModels['GET/jobs']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/jobs',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/jobs']>
    },

    /**
     * 接口名：团队内员工树状列表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=514235&itf=2344001
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/org_user/list': (req?: IModels['GET/org_user/list']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/org_user/list',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/org_user/list']>
    },

    /**
     * 接口名：新增薪酬导入项目类别
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=514235&itf=2353935
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/salary_categories': (req?: IModels['POST/salary_categories']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/salary_categories',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/salary_categories']>
    },

    /**
     * 接口名：修改薪酬导入项目类别
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=514235&itf=2353944
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/salary_categories': (req?: IModels['PATCH/salary_categories']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/salary_categories',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/salary_categories']>
    },

    /**
     * 接口名：删除薪酬导入项目类别
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=514235&itf=2353947
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'DELETE/salary_categories/{id}': (
      req?: IModels['DELETE/salary_categories/{id}']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/salary_categories/{id}',
        method: 'DELETE',
        params: req,
        extra,
      }) as Promise<IResponseTypes['DELETE/salary_categories/{id}']>
    },

    /**
     * 接口名：客户维护
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=514235&itf=2358854
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/order/receivable/clients': (
      req?: IModels['POST/order/receivable/clients']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/order/receivable/clients',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/order/receivable/clients']>
    },

    /**
     * 接口名：删除客户维护
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=514235&itf=2359072
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'DELETE/order/receivable/clients/delete/{id}': (
      req?: IModels['DELETE/order/receivable/clients/delete/{id}']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/order/receivable/clients/delete/{id}',
        method: 'DELETE',
        params: req,
        extra,
      }) as Promise<IResponseTypes['DELETE/order/receivable/clients/delete/{id}']>
    },

    /**
     * 接口名：创建客户维护
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=514235&itf=2359074
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/order/receivable/clients/save': (
      req?: IModels['POST/order/receivable/clients/save']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/order/receivable/clients/save',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/order/receivable/clients/save']>
    },

    /**
     * 接口名：更新客户维护
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=514235&itf=2359083
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/order/receivable/clients/update': (
      req?: IModels['POST/order/receivable/clients/update']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/order/receivable/clients/update',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/order/receivable/clients/update']>
    },

    /**
     * 接口名：更新插入客户
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=514235&itf=2359124
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/order/receivable/create_client': (
      req?: IModels['POST/order/receivable/create_client']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/order/receivable/create_client',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/order/receivable/create_client']>
    },

    /**
     * 接口名：客户详情列表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=514235&itf=2360848
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PUT/order/receivable/clients/list': (
      req?: IModels['PUT/order/receivable/clients/list']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/order/receivable/clients/list',
        method: 'PUT',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PUT/order/receivable/clients/list']>
    },

    /**
     * 接口名：上传公司文件
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=514235&itf=2387004
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/companies/upload': (req?: IModels['POST/companies/upload']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/companies/upload',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/companies/upload']>
    },

    /**
     * 接口名：获取公司档案列表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=514235&itf=2387006
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/companies/archive': (req?: IModels['POST/companies/archive']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/companies/archive',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/companies/archive']>
    },

    /**
     * 接口名：删除公司档案(>3天 逻辑删除，<=3天 物理删除)
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=514235&itf=2387010
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'DELETE/companies/archive/{id}': (
      req?: IModels['DELETE/companies/archive/{id}']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/companies/archive/{id}',
        method: 'DELETE',
        params: req,
        extra,
      }) as Promise<IResponseTypes['DELETE/companies/archive/{id}']>
    },

    /**
     * 接口名：更新公司档案
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=514235&itf=2387013
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/companies/archive': (req?: IModels['PATCH/companies/archive']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/companies/archive',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/companies/archive']>
    },

    /**
     * 接口名：树状公司
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=514235&itf=2387678
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/companies/tree': (req?: IModels['GET/companies/tree']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/companies/tree',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/companies/tree']>
    },

    /**
     * 接口名：公司英文名列表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=514235&itf=2388796
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/companies/en/page': (req?: IModels['GET/companies/en/page']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/companies/en/page',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/companies/en/page']>
    },

    /**
     * 接口名：新增英文名
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=514235&itf=2388798
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/companies/en/create': (req?: IModels['POST/companies/en/create']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/companies/en/create',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/companies/en/create']>
    },

    /**
     * 接口名：删除英文名
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'DELETE/companies/enNames/{id}': (req?: IModels['DELETE/companies/enNames/{id}']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/companies/enNames/{id}',
        method: 'DELETE',
        params: req,
        extra,
      }) as Promise<IResponseTypes['DELETE/companies/enNames/{id}']>
    },

    /**
     * 接口名：客户维护编辑
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=514235&itf=2391905
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PUT/order/receivable/clients/country/update': (
      req?: IModels['PUT/order/receivable/clients/country/update']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/order/receivable/clients/country/update',
        method: 'PUT',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PUT/order/receivable/clients/country/update']>
    },

    /**
     * 接口名：国别列表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=514235&itf=2395209
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/order/receivable/clients/countries': (
      req?: IModels['GET/order/receivable/clients/countries']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/order/receivable/clients/countries',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/order/receivable/clients/countries']>
    },

    /**
     * 接口名：客户维护导出
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=514235&itf=2403595
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/order/receivable/clients/export': (
      req?: IModels['POST/order/receivable/clients/export']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/order/receivable/clients/export',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/order/receivable/clients/export']>
    },

    /**
     * 接口名：新增团队
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=514235&itf=2403931
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/organizations': (req?: IModels['POST/organizations']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/organizations',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/organizations']>
    },

    /**
     * 接口名：批量下载-公司档案
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=514235&itf=2407393
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/companies/archive/batch_download': (
      req?: IModels['POST/companies/archive/batch_download']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/companies/archive/batch_download',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/companies/archive/batch_download']>
    },

    /**
     * 接口名：删除岗位
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=514235&itf=2417023
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'DELETE/jobs/{id}': (req?: IModels['DELETE/jobs/{id}']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/jobs/{id}',
        method: 'DELETE',
        params: req,
        extra,
      }) as Promise<IResponseTypes['DELETE/jobs/{id}']>
    },

    /**
     * 接口名：添加部门
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=514235&itf=2417024
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/departments': (req?: IModels['POST/departments']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/departments',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/departments']>
    },

    /**
     * 接口名：修改岗位
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=514235&itf=2417028
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/jobs': (req?: IModels['PATCH/jobs']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/jobs',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/jobs']>
    },

    /**
     * 接口名：部门名称修改
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=514235&itf=2417030
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/departments': (req?: IModels['PATCH/departments']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/departments',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/departments']>
    },

    /**
     * 接口名：新增岗位
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=514235&itf=2417035
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/jobs': (req?: IModels['POST/jobs']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/jobs',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/jobs']>
    },

    /**
     * 接口名：修改团队
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=514235&itf=2417042
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/organizations': (req?: IModels['PATCH/organizations']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/organizations',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/organizations']>
    },

    /**
     * 接口名：删除团队
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=514235&itf=2417043
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'DELETE/organizations/:id': (req?: IModels['DELETE/organizations/:id']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/organizations/:id',
        method: 'DELETE',
        params: req,
        extra,
      }) as Promise<IResponseTypes['DELETE/organizations/:id']>
    },

    /**
     * 接口名：新增订单类别(大类)
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=514235&itf=2417056
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/order_categorys': (req?: IModels['POST/order_categorys']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/order_categorys',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/order_categorys']>
    },

    /**
     * 接口名：修改订单名称
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=514235&itf=2417057
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/order_categorys': (req?: IModels['PATCH/order_categorys']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/order_categorys',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/order_categorys']>
    },

    /**
     * 接口名：获取订单类别列表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=514235&itf=2417058
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/order_categorys': (req?: IModels['GET/order_categorys']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/order_categorys',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/order_categorys']>
    },

    /**
     * 接口名：删除大类
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=514235&itf=2417059
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'DELETE/order_categorys/{id}': (req?: IModels['DELETE/order_categorys/{id}']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/order_categorys/{id}',
        method: 'DELETE',
        params: req,
        extra,
      }) as Promise<IResponseTypes['DELETE/order_categorys/{id}']>
    },

    /**
     * 接口名：获取类别中绑定和未绑定的列表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=514235&itf=2417060
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/order_category_binds': (req?: IModels['GET/order_category_binds']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/order_category_binds',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/order_category_binds']>
    },

    /**
     * 接口名：订单类别绑定
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=514235&itf=2417061
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/order_category_binds': (req?: IModels['POST/order_category_binds']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/order_category_binds',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/order_category_binds']>
    },

    /**
     * 接口名：获取未绑定的数据
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=514235&itf=2417327
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/order_category_binds/unbind': (
      req?: IModels['GET/order_category_binds/unbind']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/order_category_binds/unbind',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/order_category_binds/unbind']>
    },

    /**
     * 接口名：根据当前用户返回所在团队信息
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=514235&itf=2426444
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/org_user/owner_org': (req?: IModels['GET/org_user/owner_org']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/org_user/owner_org',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/org_user/owner_org']>
    },

    /**
     * 接口名：预算维护_新增
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=514235&itf=2453277
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/budget': (req?: IModels['POST/budget']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/budget',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/budget']>
    },

    /**
     * 接口名：预算维护_获取列表(树状)
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=514235&itf=2453278
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/budget/tree': (req?: IModels['POST/budget/tree']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/budget/tree',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/budget/tree']>
    },

    /**
     * 接口名：预算维护_修改
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=514235&itf=2453279
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/budget': (req?: IModels['PATCH/budget']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/budget',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/budget']>
    },

    /**
     * 接口名：预算维护_关闭
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=514235&itf=2453281
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/budget/close': (req?: IModels['POST/budget/close']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/budget/close',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/budget/close']>
    },

    /**
     * 接口名：预算维护_设置管理员
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=514235&itf=2453282
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/budget/set_budget_admin': (
      req?: IModels['POST/budget/set_budget_admin']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/budget/set_budget_admin',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/budget/set_budget_admin']>
    },
     /**
     * 接口名：预算维护详情
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/budget/{id}/invoice_expense_voucher_detail': (req?: IModels['POST/budget/{id}/invoice_expense_voucher_detail']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/budget/{id}/invoice_expense_voucher_detail',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/budget/{id}/invoice_expense_voucher_detail']>
    },
  }
}