/* 翻译核销收入模块 */

import * as commonLib from '../modules/commonLib'
import {IResponseTypes, IModels} from './request'

export default function createFetch(fetchConfig: any) {
  const rapperFetch = commonLib.getRapperRequest(fetchConfig)

  return {
    /**
     * 接口名：获取单个客户付款账号详情
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=515636&itf=2290932
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/verification/accounts/{id}': (
      req?: IModels['GET/verification/accounts/{id}']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/verification/accounts/{id}',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/verification/accounts/{id}']>
    },

    /**
     * 接口名：获取所有客户付款账号
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=515636&itf=2290933
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/verification/accounts': (req?: IModels['GET/verification/accounts']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/verification/accounts',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/verification/accounts']>
    },

    /**
     * 接口名：创建客户付款账号
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=515636&itf=2290928
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/verification/accounts': (req?: IModels['POST/verification/accounts']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/verification/accounts',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/verification/accounts']>
    },

    /**
     * 接口名：修改客户付款账号
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=515636&itf=2290929
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PUT/verification/accounts': (req?: IModels['PUT/verification/accounts']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/verification/accounts',
        method: 'PUT',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PUT/verification/accounts']>
    },

    /**
     * 接口名：删除客户付款账号
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=515636&itf=2290931
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'DELETE/verification/accounts': (
      req?: IModels['DELETE/verification/accounts']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/verification/accounts',
        method: 'DELETE',
        params: req,
        extra,
      }) as Promise<IResponseTypes['DELETE/verification/accounts']>
    },

    /**
     * 接口名：匹配
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=515636&itf=2307209
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/verification/translation/match': (
      req?: IModels['POST/verification/translation/match']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/verification/translation/match',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/verification/translation/match']>
    },

    /**
     * 接口名：手动匹配
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=515636&itf=2309555
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/verification/translation/verify': (
      req?: IModels['GET/verification/translation/verify']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/verification/translation/verify',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/verification/translation/verify']>
    },

    /**
     * 接口名：待核销列表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=515636&itf=2309557
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/verification/translation/verifies': (
      req?: IModels['POST/verification/translation/verifies']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/verification/translation/verifies',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/verification/translation/verifies']>
    },

    /**
     * 接口名：核销记录（已核销）
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=515636&itf=2311365
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/verification/verified_records': (
      req?: IModels['GET/verification/verified_records']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/verification/verified_records',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/verification/verified_records']>
    },

    /**
     * 接口名：翻译核销
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=515636&itf=2311366
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/verification/translation/verify': (
      req?: IModels['POST/verification/translation/verify']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/verification/translation/verify',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/verification/translation/verify']>
    },

    /**
     * 接口名：获取所有客户名称
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=515636&itf=2311695
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/verification/verified_records/customers': (
      req?: IModels['GET/verification/verified_records/customers']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/verification/verified_records/customers',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/verification/verified_records/customers']>
    },

    /**
     * 接口名：统计
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=515636&itf=2311696
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/verification/translation/statistical': (
      req?: IModels['GET/verification/translation/statistical']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/verification/translation/statistical',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/verification/translation/statistical']>
    },

    /**
     * 接口名：获取核销记录对应的流水和发票
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=515636&itf=2313337
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/verification/translation/{id}/details': (
      req?: IModels['GET/verification/translation/{id}/details']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/verification/translation/{id}/details',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/verification/translation/{id}/details']>
    },

    /**
     * 接口名：将客户列表刷新到缓存
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=515636&itf=2330905
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/verification/account/cache': (
      req?: IModels['GET/verification/account/cache']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/verification/account/cache',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/verification/account/cache']>
    },

    /**
     * 接口名：客户账号导入模板下载
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=515636&itf=2334734
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/verification/accounts/download': (
      req?: IModels['GET/verification/accounts/download']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/verification/accounts/download',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/verification/accounts/download']>
    },

    /**
     * 接口名：导入->客户账号
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=515636&itf=2334735
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/verification/accounts/import': (
      req?: IModels['POST/verification/accounts/import']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/verification/accounts/import',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/verification/accounts/import']>
    },

    /**
     * 接口名：导出客户付款账号
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=515636&itf=2339302
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/verification/accounts/export': (
      req?: IModels['GET/verification/accounts/export']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/verification/accounts/export',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/verification/accounts/export']>
    },

    /**
     * 接口名：已核销获取相关发票
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=515636&itf=2340323
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PUT/verification/translation/invoices': (
      req?: IModels['PUT/verification/translation/invoices']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/verification/translation/invoices',
        method: 'PUT',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PUT/verification/translation/invoices']>
    },

    /**
     * 接口名：获取发票相关联流水
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=515636&itf=2343187
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PUT/verification/translation/flows': (
      req?: IModels['PUT/verification/translation/flows']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/verification/translation/flows',
        method: 'PUT',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PUT/verification/translation/flows']>
    },

    /**
     * 接口名：获取流水和发票
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=515636&itf=2343347
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/verification/translation/flows': (
      req?: IModels['POST/verification/translation/flows']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/verification/translation/flows',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/verification/translation/flows']>
    },

    /**
     * 接口名：获取差异金额大于0的账户列表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=515636&itf=2344022
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/verification/verified_records/accounts': (
      req?: IModels['GET/verification/verified_records/accounts']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/verification/verified_records/accounts',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/verification/verified_records/accounts']>
    },

    /**
     * 接口名：获取待核销发票
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=515636&itf=2344023
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PUT/verification/translation/co/invoices': (
      req?: IModels['PUT/verification/translation/co/invoices']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/verification/translation/co/invoices',
        method: 'PUT',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PUT/verification/translation/co/invoices']>
    },

    /**
     * 接口名：使用差异金额核销
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=515636&itf=2344028
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/verification/translation/balance': (
      req?: IModels['POST/verification/translation/balance']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/verification/translation/balance',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/verification/translation/balance']>
    },

    /**
     * 接口名：全部发票查询
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=515636&itf=2355806
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/verification/translation/all_invoice': (
      req?: IModels['POST/verification/translation/all_invoice']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/verification/translation/all_invoice',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/verification/translation/all_invoice']>
    },

    /**
     * 接口名：自动匹配流水客户
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=515636&itf=2362315
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/verification/account/match_account': (
      req?: IModels['GET/verification/account/match_account']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/verification/account/match_account',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/verification/account/match_account']>
    },

    /**
     * 接口名：取消匹配
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=515636&itf=2362586
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PUT/verification/translation/cancel_matching': (
      req?: IModels['PUT/verification/translation/cancel_matching']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/verification/translation/cancel_matching',
        method: 'PUT',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PUT/verification/translation/cancel_matching']>
    },

    /**
     * 接口名：匹配核销备注
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=515636&itf=2363014
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PUT/verification/translation/match_remarks': (
      req?: IModels['PUT/verification/translation/match_remarks']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/verification/translation/match_remarks',
        method: 'PUT',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PUT/verification/translation/match_remarks']>
    },

    /**
     * 接口名：获取组列表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=515636&itf=2368944
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/verification/accounts/groups': (
      req?: IModels['GET/verification/accounts/groups']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/verification/accounts/groups',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/verification/accounts/groups']>
    },

    /**
     * 接口名：导出错误客户信息
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=515636&itf=2368958
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/verification/accounts/import/error': (
      req?: IModels['GET/verification/accounts/import/error']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/verification/accounts/import/error',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/verification/accounts/import/error']>
    },

    /**
     * 接口名：批量修改组
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=515636&itf=2368960
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/verification/accounts/update_group': (
      req?: IModels['POST/verification/accounts/update_group']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/verification/accounts/update_group',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/verification/accounts/update_group']>
    },

    /**
     * 接口名：导出发票
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=515636&itf=2371771
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/verification/translation/all_invoice/export': (
      req?: IModels['POST/verification/translation/all_invoice/export']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/verification/translation/all_invoice/export',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/verification/translation/all_invoice/export']>
    },

    /**
     * 接口名：匹配核销备注表头下拉
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=515636&itf=2375505
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/verification/translation/match_remarks': (
      req?: IModels['GET/verification/translation/match_remarks']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/verification/translation/match_remarks',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/verification/translation/match_remarks']>
    },

    /**
     * 接口名：获取流水匹配的客户记录
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=515636&itf=2380110
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/verification/translation/flows/{id}/customers': (
      req?: IModels['GET/verification/translation/flows/{id}/customers']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/verification/translation/flows/{id}/customers',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/verification/translation/flows/{id}/customers']>
    },

    /**
     * 接口名：删除获取到的流水匹配的客户记录
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=515636&itf=2380111
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'DELETE/verification/account_flows/{id}': (
      req?: IModels['DELETE/verification/account_flows/{id}']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/verification/account_flows/{id}',
        method: 'DELETE',
        params: req,
        extra,
      }) as Promise<IResponseTypes['DELETE/verification/account_flows/{id}']>
    },
  }
}