/* 凭证模块 */

import * as commonLib from '../modules/commonLib'
import {IResponseTypes, IModels} from './request'

export default function createFetch(fetchConfig: any) {
  const rapperFetch = commonLib.getRapperRequest(fetchConfig)

  return {
    /**
     * 接口名：凭证科目_添加
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=534358&itf=2394333
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/voucher_subject': (req?: IModels['POST/voucher_subject']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/voucher_subject',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/voucher_subject']>
    },

    /**
     * 接口名：凭证科目_查询
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=534358&itf=2394334
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/voucher_subject/query': (req?: IModels['POST/voucher_subject/query']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/voucher_subject/query',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/voucher_subject/query']>
    },

    /**
     * 接口名：凭证科目_删除
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=534358&itf=2394340
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'DELETE/voucher_subject/{id}': (req?: IModels['DELETE/voucher_subject/{id}']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/voucher_subject/{id}',
        method: 'DELETE',
        params: req,
        extra,
      }) as Promise<IResponseTypes['DELETE/voucher_subject/{id}']>
    },

    /**
     * 接口名：凭证科目_修改
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=534358&itf=2394342
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/voucher_subject': (req?: IModels['PATCH/voucher_subject']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/voucher_subject',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/voucher_subject']>
    },

    /**
     * 接口名：凭证_条件查询
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=534358&itf=2395130
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/voucher/query': (req?: IModels['POST/voucher/query']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/voucher/query',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/voucher/query']>
    },

    /**
     * 接口名：凭证_删除
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=534358&itf=2395133
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'DELETE/voucher/{id}': (req?: IModels['DELETE/voucher/{id}']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/voucher/{id}',
        method: 'DELETE',
        params: req,
        extra,
      }) as Promise<IResponseTypes['DELETE/voucher/{id}']>
    },

    /**
     * 接口名：凭证_生成
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=534358&itf=2395132
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/voucher/generate': (req?: IModels['POST/voucher/generate']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/voucher/generate',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/voucher/generate']>
    },

    /**
     * 接口名：凭证_解锁
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=534358&itf=2395621
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/voucher/unlock': (req?: IModels['PATCH/voucher/unlock']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/voucher/unlock',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/voucher/unlock']>
    },

    /**
     * 接口名：凭证_锁定
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=534358&itf=2395622
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/voucher/lock': (req?: IModels['PATCH/voucher/lock']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/voucher/lock',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/voucher/lock']>
    },

    /**
     * 接口名：凭证_下载
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=534358&itf=2396264
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/voucher/download': (req?: IModels['POST/voucher/download']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/voucher/download',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/voucher/download']>
    },

    /**
     * 接口名：凭证科目_上传
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=534358&itf=2397049
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/voucher_subject/upload': (req?: IModels['POST/voucher_subject/upload']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/voucher_subject/upload',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/voucher_subject/upload']>
    },

    /**
     * 接口名：现金流科目_分页查询
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/cash_flow_subject/query': (req?: IModels['POST/cash_flow_subject/query']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/cash_flow_subject/query',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/cash_flow_subject/query']>
    },
    /**
     * 接口名：现金流科目_新增
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/cash_flow_subject': (req?: IModels['POST/cash_flow_subject']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/cash_flow_subject',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/cash_flow_subject']>
    },
    /**
     * 接口名：现金流科目_修改
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/cash_flow_subject': (req?: IModels['PATCH/cash_flow_subject']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/cash_flow_subject',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/cash_flow_subject']>
    },
  /**
     * 接口名：现金流项目_上传
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/cash_flow_subject/upload': (req?: IModels['POST/cash_flow_subject/upload']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/cash_flow_subject/upload',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/cash_flow_subject/upload']>
    },
  /**
     * 接口名：现金流项目_删除
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'DELETE/cash_flow_subject/{id}': (req?: IModels['DELETE/cash_flow_subject/{id}']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/cash_flow_subject/{id}',
        method: 'DELETE',
        params: req,
        extra,
      }) as Promise<IResponseTypes['DELETE/cash_flow_subject/{id}']>
    },






    /**
     * 接口名：财资银行下拉
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=534358&itf=2397122
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/financial_flows/drop_list/sync_financial_bank': (
      req?: IModels['GET/financial_flows/drop_list/sync_financial_bank']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/financial_flows/drop_list/sync_financial_bank',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/financial_flows/drop_list/sync_financial_bank']>
    },

    /**
     * 接口名：同步财资回单
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=534358&itf=2397121
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/financial_flows/manual_financial_receipt': (
      req?: IModels['POST/financial_flows/manual_financial_receipt']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/financial_flows/manual_financial_receipt',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/financial_flows/manual_financial_receipt']>
    },
  }
}