export default (vue: any) => {
  vue.directive("throttle", {
    mounted(el: any, binding: any) {
      // 没有绑定函数抛出错误
      if (typeof binding.value !== 'function') {
        throw 'throttle callback not a function'
      }
      
      let isClickEnabled = true
      el.addEventListener('click', (event: any) => {
        if(!isClickEnabled) return;

        // 禁止点击
        isClickEnabled = false
        setTimeout(() => {
          binding.value()
          isClickEnabled = true
        }, binding.arg  || 1000)




      });


    },
    unmounted(el: any,) { }
  })
}