import axios from '@/http/index'
import {preParams, Extra, BasicTableResult} from "../index";
// import {} from './model/individualModel';

export default {
  /**
 * @description: 订单开票-获取开票信息
 * @return {void}
 */
  'POST/order/create_info': (params: any, extra?: Extra) => {
    return axios(`/order/create_info`, preParams(params, extra, 'post'))
  },
  /**
 * @description: 订单开票-开票
 * @return {void}
 */
  'POST/order/create_invoice': (params: any, extra?: Extra) => {
    return axios(`/order/create_invoice`, preParams(params, extra, 'post'))
  },
  /**
 * @description: 根据id获取发票
 * @return {void}
 */
  'GET/order/invoice/{id}': (params: any, extra?: Extra) => {
    return axios(`/order/invoice/${params.id}`, preParams({}, extra, 'get'))
  },
  /**
 * @description: 增值税发票订单列表
 * @return {void}
 */
  'GET/order/invoice/vat/{id}/orders': (params: any, extra?: Extra) => {
    return axios(`/order/invoice/vat/${params.id}/orders`, preParams({}, extra, 'get'))
  },
  /**
 * @description: 增值税开票记录（导出）
 * @return {void}
 */
  'POST/order/vat_record/export': (params: any, extra?: Extra) => {
    return axios(`/order/vat_record/export`, preParams(params, extra, 'post'))
  },
  /**
 * @description: 增值税开票记录（导入）
 * @return {void}
 */
  'POST/order/vat_record/import': (params: any, extra?: Extra) => {
    return axios(`/order/vat_record/import`, preParams(params, extra, 'post'))
  },
  /**
 * @description: 税务批量开票模板（导出）
 * @return {void}
 */
  'POST/order/vat_record/template': (params: any, extra?: Extra) => {
    return axios(`/order/vat_record/template`, preParams(params, extra, 'post'))
  },
  /**
 * @description: 增值税发票驳回
 * @return {void}
 */
  'POST/order/invoice/vat/reject': (params: any, extra?: Extra) => {
    return axios(`/order/invoice/vat/reject`, preParams(params, extra, 'post'))
  },
   /**
 * @description: invoice发票作废
 * @return {void}
 */
  'POST/order/invoice/vat/void': (params: any, extra?: Extra) => {
    return axios(`/order/invoice/vat/void`, preParams(params, extra, 'post'))
  },
  /**
 * @description: 增值税发票重新申请
 * @return {void}
 */
  'POST/order/invoice/vat/again': (params: any, extra?: Extra) => {
    return axios(`/order/invoice/vat/again`, preParams(params, extra, 'post'))
  },
  /**
 * @description: 正在使用的税收项目列表
 * @return {void}
 */
  'GET/order/invoice/items': (params: any, extra?: Extra) => {
    return axios(`/order/invoice/items`, preParams(params, extra, 'get'))
  },
  /**
 * @description: 税收项目创建、修改
 * @return {void}
 */
  'POST/order/invoice/item/create': (params: any, extra?: Extra) => {
    return axios(`/order/invoice/item/create`, preParams(params, extra, 'post'))
  },
  /**
 * @description: 设置税收项目禁用
 * @return {void}
 */
  'PUT/order/invoice/item/disabled/{id}': (params: any, extra?: Extra) => {
    return axios(`/order/invoice/item/disabled/${params.id}`, preParams({}, extra, 'put'))
  },
  /**
 * @description: 发送邮件（信息获取）
 * @return {void}
 */
  'POST/order/invoice/send_mail/info': (params: any, extra?: Extra) => {
    return axios(`/order/invoice/send_mail/info`, preParams(params, extra, 'post'))
  },
  /**
 * @description: 发送邮件
 * @return {void}
 */
  'POST/order/invoice/send_mail': (params: any, extra?: Extra) => {
    return axios(`/order/invoice/send_mail`, preParams(params, extra, 'post'))
  },
  /**
 * @description: 下载发票（'PDF'）
 * @return {void}
 */
  'POST/order/invoice/pdf_download': (params: any, extra?: Extra) => {
    return axios(`/order/invoice/pdf_download`, preParams(params, extra, 'post'))
  },
  /**
 * @description: 增值税发票上传识别
 * @return {void}
 */
  'POST/order/invoice/vat/upload': (params: any, extra?: Extra) => {
    return axios(`/order/invoice/vat/upload`, preParams(params, extra, 'post'))
  },
  /**
 * @description: 批量修改真实开票日期
 * @return {void}
 */
  'POST/order/invoice/vat/update/date': (params: any, extra?: Extra) => {
    return axios(`/order/invoice/vat/update/date`, preParams(params, extra, 'post'))
  },
  /**
 * @description: 增值税发票附件下载
 * @return {void}
 */
  'POST/order/invoice/vat/download': (params: any, extra?: Extra) => {
    return axios(`/order/invoice/vat/download`, preParams(params, extra, 'post'))
  },
  /**
 * @description: 正在使用的单位、规格型号列表
 * @return {void}
 */
  'GET/order/invoice/settings': (params: any, extra?: Extra) => {
    return axios(`/order/invoice/settings`, preParams(params, extra, 'get'))
  },
  /**
 * @description: 单位、规格型号设置创建、修改
 * @return {void}
 */
  'POST/order/invoice/setting/create': (params: any, extra?: Extra) => {
    return axios(`/order/invoice/setting/create`, preParams(params, extra, 'post'))
  },
  /**
 * @description: 单位、规格型号设置创建、作废
 * @return {void}
 */
  'PUT/order/invoice/setting/disabled/:id': (params: any, extra?: Extra) => {
    return axios(`/order/invoice/setting/disabled/${params.id}`, preParams({}, extra, 'put'))
  },
  /**
 * @description: 销售作废发票
 * @return {void}
 */
  'PUT/order/invoice/vat/negate': (params: any, extra?: Extra) => {
    return axios(`/order/invoice/vat/negate`, preParams(params, extra, 'put'))
  },
  /**
 * @description: 增值税发票上传识别（批量）
 * @return {void}
 */
  'POST/order/invoice/vat/batch_upload': (params: any, extra?: Extra) => {
    return axios(`/order/invoice/vat/batch_upload`, preParams(params, extra, 'post'))
  },
  /**
 * @description: 增值税发票附件删除
 * @return {void}
 */
  'DELETE/order/invoice/vat/delete/:id': (params: any, extra?: Extra) => {
    return axios(`/order/invoice/vat/delete/${params.id}`, preParams({}, extra, 'delete'))
  },
  /**
 * @description: 增值税发票附件列表
 * @return {void}
 */
  'GET/order/invoice/vat/upload/files': (params: any, extra?: Extra) => {
    return axios(`/order/invoice/vat/upload/files`, preParams(params, extra, 'get'))
  },
  /**
 * @description: 修改发票的订单列表，重新计算发票金额
 * @return {void}
 */
  'POST/order/invoice/update_invoice_order': (params: any, extra?: Extra) => {
    return axios(`/order/invoice/update_invoice_order`, preParams(params, extra, 'post'))
  },
  /**
 * @description: 修改发票的订单列表，重新计算发票金额（订单列表）
 * @return {void}
 */
  'POST/order/invoice/delivered_orders': (params: any, extra?: Extra) => {
    return axios(`/order/invoice/delivered_orders`, preParams(params, extra, 'post'))
  },
  /**
 * @description: 解析订单号
 * @return {void}
 */
  'POST/order/invoice/analyze_orderNo': (params: any, extra?: Extra) => {
    return axios(`/order/invoice/analyze_orderNo`, preParams(params, extra, 'post'))
  },
  /**
 * @description: 根据发票号、类型批量下载发票
 * @return {void}
 */
  'POST/order/invoice/batch_download': (params: any, extra?: Extra) => {
    return axios(`/order/invoice/batch_download`, preParams(params, extra, 'post'))
  },
  /**
 * @description: 未完工订单
 * @return {void}
 */
  'POST/order/delivering_or_proceeding': (params: any, extra?: Extra) => {
    return axios(`/order/delivering_or_proceeding`, preParams(params, extra, 'post'))
  },
  /**
 * @description: 未完工订单开票
 * @return {void}
 */
  'POST/order/create_invoice/no_order': (params: any, extra?: Extra) => {
    return axios(`/order/create_invoice/no_order`, preParams(params, extra, 'post'))
  },
  /**
 * @description: 交付订单
 * @return {void}
 */
  'POST/order/vat_record/create_ref': (params: any, extra?: Extra) => {
    return axios(`/order/vat_record/create_ref`, preParams(params, extra, 'post'))
  },
  /**
 * @description: 开票校验
 * @return {void}
 */
  'POST/order/check_order': (params: any, extra?: Extra) => {
    return axios(`/order/check_order`, preParams(params, extra, 'post'))
  },
  /**
 * @description: 更新信息
 * @return {void}
 */
  'POST/order/update_check_order': (params: any, extra?: Extra) => {
    return axios(`/order/update_check_order`, preParams(params, extra, 'post'))
  },
}