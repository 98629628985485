/* 人资模块 */
import * as commonLib from '../modules/commonLib'
import {IResponseTypes, IModels} from './request'

export default function createFetch(fetchConfig: any) {
  const rapperFetch = commonLib.getRapperRequest(fetchConfig)

  return {
    /**
     * 接口名：添加新员工
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507463&itf=2281388
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'POST/users': (req?: IModels['POST/users']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/users',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/users']>
    }, */

    /**
     * 接口名：批量上传新员工
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507463&itf=2341793
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'POST/users/upload': (req?: IModels['POST/users/upload']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/users/upload',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/users/upload']>
    }, */

    /**
     * 接口名：根据username获取员工信息
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507463&itf=2322657
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'GET/users/username': (req?: IModels['GET/users/username']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/users/username',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/users/username']>
    }, */
    /**
     * 接口名：获取jwtc创建的token*/
     
    /* 'GET/jwt/token': (req?: IModels['GET/jwt/token']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/jwt/token',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/jwt/token']>
    }, */

    /**
     * 接口名：找回密码
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507463&itf=2322769
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'PATCH/users/forget_pwd': (req?: IModels['PATCH/users/forget_pwd']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/users/forget_pwd',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/users/forget_pwd']>
    }, */

    /**
     * 接口名：根据条件查询人员信息
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507463&itf=2265212
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'POST/users/profile/list': (req?: IModels['POST/users/profile/list']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/users/profile/list',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/users/profile/list']>
    }, */

    /**
     * 接口名：判断邮箱是否重复，失焦回显(与登录名一样
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507463&itf=2316377
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'GET/users/check_email': (req?: IModels['GET/users/check_email']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/users/check_email',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/users/check_email']>
    }, */

    /**
     * 接口名：员工其他信息
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507463&itf=2253519
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'PATCH/users/profile': (req?: IModels['PATCH/users/profile']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/users/profile',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/users/profile']>
    }, */

    /**
     * 接口名：员工信息(导出所有员工exlce)
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507463&itf=2244277
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'POST/users/export': (req?: IModels['POST/users/export']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/users/export',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/users/export']>
    }, */

    /**
     * 接口名：员工岗位变动信息查询
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507463&itf=2244437
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'GET/job_changes/{id}': (req?: IModels['GET/job_changes/{id}']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/job_changes/{id}',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/job_changes/{id}']>
    }, */

    /**
     * 接口名：删除员工岗位信息
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507463&itf=2248655
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'DELETE/job_changes/{id}': (req?: IModels['DELETE/job_changes/{id}']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/job_changes/{id}',
        method: 'DELETE',
        params: req,
        extra,
      }) as Promise<IResponseTypes['DELETE/job_changes/{id}']>
    }, */

    /**
     * 接口名：删除员工资产信息
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507463&itf=2248697
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'DELETE/assets/{id}': (req?: IModels['DELETE/assets/{id}']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/assets/{id}',
        method: 'DELETE',
        params: req,
        extra,
      }) as Promise<IResponseTypes['DELETE/assets/{id}']>
    }, */

    /**
     * 接口名：员工列表(不分页,下拉框)
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507463&itf=2255784
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'GET/users/list': (req?: IModels['GET/users/list']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/users/list',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/users/list']>
    }, */

    /**
     * 接口名：重置密码
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507463&itf=2268914
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'GET/users/reset_pwd/{userId}': (
      req?: IModels['GET/users/reset_pwd/{userId}']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/users/reset_pwd/{userId}',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/users/reset_pwd/{userId}']>
    }, */

    /**
     * 接口名：判断登录名是否存在
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507463&itf=2269020
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'GET/users/check_name': (req?: IModels['GET/users/check_name']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/users/check_name',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/users/check_name']>
    }, */

    /**
     * 接口名：员工个人密码修改
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507463&itf=2269052
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'PATCH/users/update_pwd': (req?: IModels['PATCH/users/update_pwd']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/users/update_pwd',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/users/update_pwd']>
    }, */

    /**
     * 接口名：检查未完善信息的员工
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507463&itf=2283107
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'GET/users/profile': (req?: IModels['GET/users/profile']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/users/profile',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/users/profile']>
    }, */

    /**
     * 接口名：获取员工办公所在地
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507463&itf=2283348
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'GET/house_leases': (req?: IModels['GET/house_leases']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/house_leases',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/house_leases']>
    }, */

    /**
     * 接口名：校验身份证号码是否已存在系统
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507463&itf=2293972
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'GET/users/check_card': (req?: IModels['GET/users/check_card']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/users/check_card',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/users/check_card']>
    }, */

    /**
     * 接口名：退出登录
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507463&itf=2307208
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'POST/logout': (req?: IModels['POST/logout']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/logout',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/logout']>
    }, */

    /**
     * 接口名：//获取出生年月，年龄，性别，户籍所在地
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507463&itf=2316095
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'GET/users/card_id': (req?: IModels['GET/users/card_id']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/users/card_id',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/users/card_id']>
    }, */

    /**
     * 接口名：新增-为用户新增财务岗位
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507463&itf=2366699
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'POST/user_finance_stations': (req?: IModels['POST/user_finance_stations']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/user_finance_stations',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/user_finance_stations']>
    }, */

    /**
     * 接口名：更新-为用户更新财务岗位
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507463&itf=2366700
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'PATCH/user_finance_stations': (req?: IModels['PATCH/user_finance_stations']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/user_finance_stations',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/user_finance_stations']>
    }, */

    /**
     * 接口名：删除-删除用户财务岗位
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507463&itf=2366701
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'DELETE/user_finance_stations/{id}': (
      req?: IModels['DELETE/user_finance_stations/{id}']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/user_finance_stations/{id}',
        method: 'DELETE',
        params: req,
        extra,
      }) as Promise<IResponseTypes['DELETE/user_finance_stations/{id}']>
    }, */

    /**
     * 接口名：获取-用户财务岗位列表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507463&itf=2366702
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'POST/user_finance_stations/list': (
      req?: IModels['POST/user_finance_stations/list']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/user_finance_stations/list',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/user_finance_stations/list']>
    }, */

    /**
     * 接口名：获取-财务岗位
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507463&itf=2366874
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'GET/user_finances': (req?: IModels['GET/user_finances']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/user_finances',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/user_finances']>
    }, */

    /**
     * 接口名：新增-财务岗位
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507463&itf=2366872
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'POST/user_finances': (req?: IModels['POST/user_finances']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/user_finances',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/user_finances']>
    }, */

    /**
     * 接口名：删除-财务岗位
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507463&itf=2366875
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'DELETE/user_finances/{id}': (req?: IModels['DELETE/user_finances/{id}']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/user_finances/{id}',
        method: 'DELETE',
        params: req,
        extra,
      }) as Promise<IResponseTypes['DELETE/user_finances/{id}']>
    }, */

    /**
     * 接口名：更新-财务岗位
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507463&itf=2366873
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'PATCH/user_finances': (req?: IModels['PATCH/user_finances']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/user_finances',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/user_finances']>
    }, */

    /**
     * 接口名：批量修改财务岗位
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507463&itf=2368953
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'PATCH/user_finance_stations/batch': (
      req?: IModels['PATCH/user_finance_stations/batch']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/user_finance_stations/batch',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/user_finance_stations/batch']>
    }, */

    /**
     * 接口名：员工基本信息保存
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507463&itf=2373416
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'POST/users/profile': (req?: IModels['POST/users/profile']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/users/profile',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/users/profile']>
    }, */

    /**
     * 接口名：获取员工基本信息
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507463&itf=2373417
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'GET/users/{id}/profile': (req?: IModels['GET/users/{id}/profile']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/users/{id}/profile',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/users/{id}/profile']>
    }, */

    /**
     * 接口名：员工资产信息保存
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507463&itf=2373418
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'PATCH/assets': (req?: IModels['PATCH/assets']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/assets',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/assets']>
    }, */

    /**
     * 接口名：员合同酬信息保存
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507463&itf=2373419
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'POST/users/{id}/contracts': (req?: IModels['POST/users/{id}/contracts']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/users/{id}/contracts',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/users/{id}/contracts']>
    }, */

    /**
     * 接口名：删除员工工作经历
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507463&itf=2373421
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'DELETE/users/{userId}/word_experiences/{id}': (
      req?: IModels['DELETE/users/{userId}/word_experiences/{id}']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: 'users/{userId}/word_experiences/{id}',
        method: 'DELETE',
        params: req,
        extra,
      }) as Promise<IResponseTypes['DELETE/users/{userId}/word_experiences/{id}']>
    }, */

    /**
     * 接口名：员工作经历保存
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507463&itf=2373422
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'POST/users/{uId}/word_experiences': (
      req?: IModels['POST/users/{uId}/word_experiences']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/users/{uId}/word_experiences',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/users/{uId}/word_experiences']>
    }, */

    /**
     * 接口名：员合同酬信息保存
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507463&itf=2373435
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'POST/users/{userId}/contracts': (
      req?: IModels['POST/users/{userId}/contracts']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/users/{userId}/contracts',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/users/{userId}/contracts']>
    }, */

    /**
     * 接口名：获取员工工作经历
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507463&itf=2373441
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'GET/users/{userId}/word_experiences': (
      req?: IModels['GET/users/{userId}/word_experiences']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/users/{userId}/word_experiences',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/users/{userId}/word_experiences']>
    }, */

    /**
     * 接口名：获取员工资产信息
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507463&itf=2373446
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'GET/assets/{userId}': (req?: IModels['GET/assets/{userId}']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/assets/{userId}',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/assets/{userId}']>
    }, */

    /**
     * 接口名：员工资产信息保存
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507463&itf=2373448
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'POST/assets': (req?: IModels['POST/assets']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/assets',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/assets']>
    }, */

    /**
     * 接口名：根据id查询社保卡信息
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507463&itf=2373453
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'GET/socials/{userId}': (req?: IModels['GET/socials/{userId}']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/socials/{userId}',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/socials/{userId}']>
    }, */

    /**
     * 接口名：员工社保信息保存
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507463&itf=2373454
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'POST/socials': (req?: IModels['POST/socials']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/socials',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/socials']>
    }, */

    /**
     * 接口名：根据员工英文名或身份证号码查重
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507463&itf=2379714
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'GET/users/repeat': (req?: IModels['GET/users/repeat']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/users/repeat',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/users/repeat']>
    }, */

    /**
     * 接口名：获取工位使用情况
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507463&itf=2380593
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'POST/users/seat': (req?: IModels['POST/users/seat']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/users/seat',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/users/seat']>
    }, */

    /**
     * 接口名：未维护离职员工
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507463&itf=2382814
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'GET/users/profile/quit': (req?: IModels['GET/users/profile/quit']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/users/profile/quit',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/users/profile/quit']>
    }, */

    /**
     * 接口名：财务成本列表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507463&itf=2385092
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'POST/users/salary_details/page/finance': (
      req?: IModels['POST/users/salary_details/page/finance']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/users/salary_details/page/finance',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/users/salary_details/page/finance']>
    }, */

    /**
     * 接口名：批量修改员工信息
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507463&itf=2408826
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'PATCH/users/batch_update': (req?: IModels['PATCH/users/batch_update']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/users/batch_update',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/users/batch_update']>
    }, */

    /**
     * 接口名：下载批量修改员工信息模板
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507463&itf=2408904
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'GET/users/info_template': (req?: IModels['GET/users/info_template']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/users/info_template',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/users/info_template']>
    }, */

    /**
     * 接口名：获取材料(入职资料)
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507463&itf=2430496
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'GET/user/material/{userId}': (req?: IModels['GET/user/material/{userId}']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/user/material/{userId}',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/user/material/{userId}']>
    }, */

    /**
     * 接口名：上传用户入职资料
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507463&itf=2430512
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'POST/user/material/upload': (req?: IModels['POST/user/material/upload']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/user/material/upload',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/user/material/upload']>
    }, */

    /**
     * 接口名：添加证件自定义类别
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507463&itf=2430566
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'POST/user/material/category': (req?: IModels['POST/user/material/category']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/user/material/category',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/user/material/category']>
    }, */

    /**
     * 接口名：上传自定义证件
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507463&itf=2430569
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'POST/user/material/upload/custom': (
      req?: IModels['POST/user/material/upload/custom']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/user/material/upload/custom',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/user/material/upload/custom']>
    }, */

    // 工作台模块

    /**
   * 接口名：员工入职
   * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=522014&itf=2329131
   * @param req 请求参数
   * @param extra 请求配置项
   */
    /* 'POST/workbench/staff_entry': (req?: IModels['POST/workbench/staff_entry']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/workbench/staff_entry',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/workbench/staff_entry']>
    }, */

    /**
     * 接口名：员工转正
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=522014&itf=2329141
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'POST/workbench/staff_regular': (
      req?: IModels['POST/workbench/staff_regular']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/workbench/staff_regular',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/workbench/staff_regular']>
    }, */

    /**
     * 接口名：员工调薪
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=522014&itf=2329146
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'POST/workbench/staff_salary_adjust': (
      req?: IModels['POST/workbench/staff_salary_adjust']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/workbench/staff_salary_adjust',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/workbench/staff_salary_adjust']>
    }, */

    /**
     * 接口名：员工离职
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=522014&itf=2329147
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'POST/workbench/staff_quit': (req?: IModels['POST/workbench/staff_quit']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/workbench/staff_quit',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/workbench/staff_quit']>
    }, */

    /**
     * 接口名：员工变动记录条件查询
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=522014&itf=2329148
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'POST/workbench/staff_change/conditions': (
      req?: IModels['POST/workbench/staff_change/conditions']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/workbench/staff_change/conditions',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/workbench/staff_change/conditions']>
    }, */

    /**
     * 接口名：员工转岗
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=522014&itf=2329366
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'POST/workbench/staff_transfer': (
      req?: IModels['POST/workbench/staff_transfer']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/workbench/staff_transfer',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/workbench/staff_transfer']>
    }, */

    /**
     * 接口名：获取员工变动记录
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'GET/user_change_records': (
      req?: IModels['GET/user_change_records']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/user_change_records',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/user_change_records']>
    }, */

    /**
     * 接口名：处理变动记录
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'PATCH/user_change_records/handle': (
      req?: IModels['PATCH/user_change_records/handle']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/user_change_records/handle',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/user_change_records/handle']>
    }, */

    /**
     * 接口名：获取异常信息列表
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'POST/labor_contracts/exception/list': (
      req?: IModels['POST/labor_contracts/exception/list']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/labor_contracts/exception/list',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/labor_contracts/exception/list']>
    }, */

    /**
     * 接口名：删除异常信息
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'DELETE/labor_contracts/exception/{id}': (
      req?: IModels['DELETE/labor_contracts/exception/{id}']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/labor_contracts/exception/{id}',
        method: 'DELETE',
        params: req,
        extra,
      }) as Promise<IResponseTypes['DELETE/labor_contracts/exception/{id}']>
    }, */

    /**
     * 接口名：获取有权限查看的异常个数
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'GET/labor_contracts/exception/count': (
      req?: IModels['GET/labor_contracts/exception/count']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/labor_contracts/exception/count',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/labor_contracts/exception/count']>
    }, */
  }
}