import axios from '@/http/index'
import {preParams, Extra, BasicTableResult} from "../index";
// 员工关怀
export default {
    /**
     * @description: 员工关怀_添加
     * @return {void}
    */
    'POST/employee_care': (params: any, extra?: Extra) => {
        return axios(`/employee_care`, preParams(params, extra, 'post'))
    },
    /**
     * @description: 员工关怀_分页查询
     * @return {void}
    */
    'POST/employee_care/query': (params: any, extra?: Extra) => {
        return axios(`/employee_care/query`, preParams(params, extra, 'post'))
    },
    /**
     * @description: 员工关怀_编辑
     * @return {void}
    */
    'PATCH/employee_care': (params: any, extra?: Extra) => {
        return axios(`/employee_care`, preParams(params, extra, 'patch'))
    },
    /**
     * @description: 员工关怀_删除
     * @return {void}
    */
    'DELETE/employee_care/{id}': (params: any, extra?: Extra) => {
        return axios(`/employee_care/${params.id}`, preParams({}, extra, 'delete'))
    },
    /**
     * @description: 员工关怀_修改状态
     * @return {void}
    */
    'PATCH/employee_care/status': (params: any, extra?: Extra) => {
        return axios(`/employee_care/status`, preParams(params, extra, 'patch'))
    },

    /**
     * @description: 员工列表-离职转入职
     * @return {void}
    */
    'POST/users/resign_employee': (params: any, extra?: Extra) => {
        return axios(`/users/resign_employee`, preParams(params, extra, 'post'))
    },

    /**
     * @description: 员工列表-员工历史记录
     * @return {void}
    */
    'GET/users/history/{userId}': (params: any, extra?: Extra) => {
        return axios(`/users/history/${params.userId}`, preParams({}, extra, 'get'))
    },

    /**
     * @description: 上传离职交接表
     * @return {void}
    */
    'POST/user/material/upload/handover': (params: any, extra?: Extra) => {
      return axios(`/user/material/upload/handover`, preParams(params, extra, 'post'))
  },

    /**
     * @description: 员工变更日志_查询
     * @return {void}
    */
    'POST/users/modify/record/all/query': (params: any, extra?: Extra) => {
      return axios(`/users/modify/record/all/query`, preParams(params, extra, 'post'))
  },
    /**
     * @description: 离职人员账户_分页查询
     * @return {void}
    */
    'POST/account_reactivation/query': (params: any, extra?: Extra) => {
      return axios(`/account_reactivation/query`, preParams(params, extra, 'post'))
  },
    /**
     * @description: 离职人员账户_启用
     * @return {void}
    */
    'POST/account_reactivation/enable_account': (params: any, extra?: Extra) => {
      return axios(`/account_reactivation/enable_account`, preParams(params, extra, 'post'))
  },
}