/*资产模块*/
import * as commonLib from '../../modules/commonLib'
import {IResponseTypes, IModels} from '../request'

export default function createFetch(fetchConfig: any) {
    const rapperFetch = commonLib.getRapperRequest(fetchConfig)

    return {
        /**
         * 接口名：办公室资产-所有资产类别
         * @param req 请求参数
         * @param extra 请求配置项
         */
        'GET/office/asset/categories': (
            req?: IModels['GET/office/asset/categories']['Req'],
            extra?: commonLib.IExtra
        ) => {
            return rapperFetch({
                url: '/office/asset/categories',
                method: 'GET',
                params: req,
                extra,
            }) as Promise<IResponseTypes['GET/office/asset/categories']>
        },
        /**
         * 接口名：办公室资产-有效资产类别
         * @param req 请求参数
         * @param extra 请求配置项
         */
        'GET/office/asset/category/{type}/valid_list': (
            req?: IModels['GET/office/asset/category/{type}/valid_list']['Req'],
            extra?: commonLib.IExtra
        ) => {
            return rapperFetch({
                url: '/office/asset/category/{type}/valid_list',
                method: 'GET',
                params: req,
                extra,
            }) as Promise<IResponseTypes['GET/office/asset/category/{type}/valid_list']>
        },

        /**
         * 接口名：办公室资产-添加/修改资产类别
         * @param req 请求参数
         * @param extra 请求配置项
         */
        'POST/office/asset/category': (
            req?: IModels['POST/office/asset/category']['Req'],
            extra?: commonLib.IExtra
        ) => {
            return rapperFetch({
                url: '/office/asset/category',
                method: 'POST',
                params: req,
                extra,
            }) as Promise<IResponseTypes['POST/office/asset/category']>
        },

        /**
         * 接口名：办公室资产-禁用类别
         * @param req 请求参数
         * @param extra 请求配置项
         */
        'POST/office/asset/category/{id}/disable': (
            req?: IModels['POST/office/asset/category/{id}/disable']['Req'],
            extra?: commonLib.IExtra
        ) => {
            return rapperFetch({
                url: '/office/asset/category/{id}/disable',
                method: 'POST',
                params: req,
                extra,
            }) as Promise<IResponseTypes['POST/office/asset/category/{id}/disable']>
        },
        /**
         * 接口名：办公室资产-启用禁用类别
         * @param req 请求参数
         * @param extra 请求配置项
         */
        'POST/office/asset/category/{id}/enable': (
            req?: IModels['POST/office/asset/category/{id}/enable']['Req'],
            extra?: commonLib.IExtra
        ) => {
            return rapperFetch({
                url: '/office/asset/category/{id}/enable',
                method: 'POST',
                params: req,
                extra,
            }) as Promise<IResponseTypes['POST/office/asset/category/{id}/enable']>
        },

        /**
         * 接口名：办公室资产-资产登记
         * @param req 请求参数
         * @param extra 请求配置项
         */
        'POST/office/asset/register': (
            req?: IModels['POST/office/asset/register']['Req'],
            extra?: commonLib.IExtra
        ) => {
            return rapperFetch({
                url: '/office/asset/register',
                method: 'POST',
                params: req,
                extra,
            }) as Promise<IResponseTypes['POST/office/asset/category/{id}/disable']>
        },
        /**
         * 接口名：办公室资产-固定资产列表
         * @param req 请求参数
         * @param extra 请求配置项
         */
        'POST/office/asset/fixed_list': (
            req?: IModels['POST/office/asset/fixed_list']['Req'],
            extra?: commonLib.IExtra
        ) => {
            return rapperFetch({
                url: '/office/asset/fixed_list',
                method: 'POST',
                params: req,
                extra,
            }) as Promise<IResponseTypes['POST/office/asset/fixed_list']>
        },
        /**
         * 接口名：办公室资产-资产可关联的采购单
         * @param req 请求参数
         * @param extra 请求配置项
         */
        'POST/office/asset/invoice_expense_voucher_list': (
            req?: IModels['POST/office/asset/invoice_expense_voucher_list']['Req'],
            extra?: commonLib.IExtra
        ) => {
            return rapperFetch({
                url: '/office/asset/invoice_expense_voucher_list',
                method: 'POST',
                params: req,
                extra,
            }) as Promise<IResponseTypes['POST/office/asset/invoice_expense_voucher_list']>
        },

        /**
         * 接口名：办公室资产-批量登记
         * @param req 请求参数
         * @param extra 请求配置项
         */
        'POST/office/asset/batch_register_fixed_assets': (
            req?: IModels['POST/office/asset/batch_register_fixed_assets']['Req'],
            extra?: commonLib.IExtra
        ) => {
            return rapperFetch({
                url: '/office/asset/batch_register_fixed_assets',
                method: 'POST',
                params: req,
                extra,
            }) as Promise<IResponseTypes['POST/office/asset/batch_register_fixed_assets']>
        },
        /**
         * 接口名：办公室资产-领用固定资产
         * @param req 请求参数
         * @param extra 请求配置项
         */
        'POST/office/asset/ly_fixed_assets': (
            req?: IModels['POST/office/asset/ly_fixed_assets']['Req'],
            extra?: commonLib.IExtra
        ) => {
            return rapperFetch({
                url: '/office/asset/ly_fixed_assets',
                method: 'POST',
                params: req,
                extra,
            }) as Promise<IResponseTypes['POST/office/asset/ly_fixed_assets']>
        },
        /**
         * 接口名：办公室资产-退库固定资产
         * @param req 请求参数
         * @param extra 请求配置项
         */
        'POST/office/asset/return': (
            req?: IModels['POST/office/asset/return']['Req'],
            extra?: commonLib.IExtra
        ) => {
            return rapperFetch({
                url: '/office/asset/return',
                method: 'POST',
                params: req,
                extra,
            }) as Promise<IResponseTypes['POST/office/asset/return']>
        },
        /**
         * 接口名：办公室资产-报废固定资产
         * @param req 请求参数
         * @param extra 请求配置项
         */
        'POST/office/asset/discard': (
            req?: IModels['POST/office/asset/discard']['Req'],
            extra?: commonLib.IExtra
        ) => {
            return rapperFetch({
                url: '/office/asset/discard',
                method: 'POST',
                params: req,
                extra,
            }) as Promise<IResponseTypes['POST/office/asset/discard']>
        },
        /**
         * 接口名：办公室资产-批量登记的错误
         * @param req 请求参数
         * @param extra 请求配置项
         */
        'GET/office/asset/batch_register_fixed_assets_errors': (
            req?: IModels['GET/office/asset/batch_register_fixed_assets_errors']['Req'],
            extra?: commonLib.IExtra
        ) => {
            return rapperFetch({
                url: '/office/asset/batch_register_fixed_assets_errors',
                method: 'GET',
                params: req,
                extra,
            }) as Promise<IResponseTypes['GET/office/asset/batch_register_fixed_assets_errors']>
        },
        /**
         * 接口名：办公室资产-批量登记文件模板
         * @param req 请求参数
         * @param extra 请求配置项
         */
        'GET/office/asset/download_batch_register_template': (
            req?: IModels['GET/office/asset/download_batch_register_template']['Req'],
            extra?: commonLib.IExtra
        ) => {
            return rapperFetch({
                url: '/office/asset/download_batch_register_template',
                method: 'GET',
                params: req,
                extra,
            }) as Promise<IResponseTypes['GET/office/asset/download_batch_register_template']>
        },


        /**
         * 接口名：办公室资产-资产变更记录
         * @param req 请求参数
         * @param extra 请求配置项
         */
        'POST/office/asset/change_records': (
            req?: IModels['POST/office/asset/change_records']['Req'],
            extra?: commonLib.IExtra
        ) => {
            return rapperFetch({
                url: '/office/asset/change_records',
                method: 'POST',
                params: req,
                extra,
            }) as Promise<IResponseTypes['POST/office/asset/change_records']>
        },
        /**
         * 接口名：办公室资产-资产变更记录明细
         * @param req 请求参数
         * @param extra 请求配置项
         */
        'POST/office/asset/change_record/{id}': (
            req?: IModels['POST/office/asset/change_record/{id}']['Req'],
            extra?: commonLib.IExtra
        ) => {
            return rapperFetch({
                url: '/office/asset/change_record/{id}',
                method: 'POST',
                params: req,
                extra,
            }) as Promise<IResponseTypes['POST/office/asset/change_record/{id}']>
        },
        /**
         * 接口名：办公室资产-无形资产到期提醒个数
         * @param req 请求参数
         * @param extra 请求配置项
         */
        'GET/office/asset/approaching_expire_count': (
            req?: IModels['GET/office/asset/approaching_expire_count']['Req'],
            extra?: commonLib.IExtra
        ) => {
            return rapperFetch({
                url: '/office/asset/approaching_expire_count',
                method: 'GET',
                params: req,
                extra,
            }) as Promise<IResponseTypes['GET/office/asset/approaching_expire_count']>
        },
        /**
         * 接口名：办公室资产-到期提醒列表
         * @param req 请求参数
         * @param extra 请求配置项
         */
        'POST/office/asset/approaching_expire_list': (
            req?: IModels['POST/office/asset/approaching_expire_list']['Req'],
            extra?: commonLib.IExtra
        ) => {
            return rapperFetch({
                url: '/office/asset/approaching_expire_list',
                method: 'POST',
                params: req,
                extra,
            }) as Promise<IResponseTypes['POST/office/asset/approaching_expire_list']>
        },
        /**
         * 接口名：办公室资产-忽略无形资产
         * @param req 请求参数
         * @param extra 请求配置项
         */
        'POST/office/asset/mark_intangible_asset': (
            req?: IModels['POST/office/asset/mark_intangible_asset']['Req'],
            extra?: commonLib.IExtra
        ) => {
            return rapperFetch({
                url: '/office/asset/mark_intangible_asset',
                method: 'POST',
                params: req,
                extra,
            }) as Promise<IResponseTypes['POST/office/asset/mark_intangible_asset']>
        },
        /**
         * 接口名：办公室资产-耗材列表
         * @param req 请求参数
         * @param extra 请求配置项
         */
        'POST/office/asset/consumables_list': (
            req?: IModels['POST/office/asset/consumables_list']['Req'],
            extra?: commonLib.IExtra
        ) => {
            return rapperFetch({
                url: '/office/asset/consumables_list',
                method: 'POST',
                params: req,
                extra,
            }) as Promise<IResponseTypes['POST/office/asset/consumables_list']>
        },
        /**
         * 接口名：办公室资产-耗材领用
         * @param req 请求参数
         * @param extra 请求配置项
         */
        'POST/office/asset/ly_consumable_assets': (
            req?: IModels['POST/office/asset/ly_consumable_assets']['Req'],
            extra?: commonLib.IExtra
        ) => {
            return rapperFetch({
                url: '/office/asset/ly_consumable_assets',
                method: 'POST',
                params: req,
                extra,
            }) as Promise<IResponseTypes['POST/office/asset/ly_consumable_assets']>
        },
        /**
         * 接口名：办公室资产-资产查询
         * @param req 请求参数
         * @param extra 请求配置项
         */
        'POST/office/asset/search': (
            req?: IModels['POST/office/asset/search']['Req'],
            extra?: commonLib.IExtra
        ) => {
            return rapperFetch({
                url: '/office/asset/search',
                method: 'POST',
                params: req,
                extra,
            }) as Promise<IResponseTypes['POST/office/asset/search']>
        },


        /**
         * 接口名：办公室资产-导出资产
         * @param req 请求参数
         * @param extra 请求配置项
         */
        'POST/office/asset/depreciation_info': (
            req?: IModels['POST/office/asset/depreciation_info']['Req'],
            extra?: commonLib.IExtra
        ) => {
            return rapperFetch({
                url: '/office/asset/depreciation_info',
                method: 'POST',
                params: req,
                extra,
            }) as Promise<IResponseTypes['POST/office/asset/depreciation_info']>
        },

        /**
         * 接口名：办公室资产-资产详情
         * @param req 请求参数
         * @param extra 请求配置项
         */
        'GET/office/asset/{id}/detail': (
            req?: IModels['GET/office/asset/{id}/detail']['Req'],
            extra?: commonLib.IExtra
        ) => {
            return rapperFetch({
                url: '/office/asset/{id}/detail',
                method: 'GET',
                params: req,
                extra,
            }) as Promise<IResponseTypes['GET/office/asset/{id}/detail']>
        },
        /**
         * 接口名：办公室资产-资产生命周期
         * @param req 请求参数
         * @param extra 请求配置项
         */
        'GET/office/asset/{id}/life_cycle': (
            req?: IModels['GET/office/asset/{id}/life_cycle']['Req'],
            extra?: commonLib.IExtra
        ) => {
            return rapperFetch({
                url: '/office/asset/{id}/life_cycle',
                method: 'GET',
                params: req,
                extra,
            }) as Promise<IResponseTypes['GET/office/asset/{id}/life_cycle']>
        },
        /**
         * 接口名：办公室资产-固定资产数量
         * @param req 请求参数
         * @param extra 请求配置项
         */
        'POST/office/asset/fixed_asset_count': (
            req?: IModels['POST/office/asset/fixed_asset_count']['Req'],
            extra?: commonLib.IExtra
        ) => {
            return rapperFetch({
                url: '/office/asset/fixed_asset_count',
                method: 'POST',
                params: req,
                extra,
            }) as Promise<IResponseTypes['POST/office/asset/fixed_asset_count']>
        },

        /**
         * 接口名：办公室资产-导出资产
         * @param req 请求参数
         * @param extra 请求配置项
         */
        'POST/office/asset/export_asset': (
            req?: IModels['POST/office/asset/export_asset']['Req'],
            extra?: commonLib.IExtra
        ) => {
            return rapperFetch({
                url: '/office/asset/export_asset',
                method: 'POST',
                params: req,
                extra,
            }) as Promise<IResponseTypes['POST/office/asset/export_asset']>
        },
        /**
         * 接口名：用户领用的固定资产
         * @param req 请求参数
         * @param extra 请求配置项
         */
        'GET/office/asset/user/{id}/fixed_assets': (
            req?: IModels['GET/office/asset/user/{id}/fixed_assets']['Req'],
            extra?: commonLib.IExtra
        ) => {
            return rapperFetch({
                url: '/office/asset/user/{id}/fixed_assets',
                method: 'GET',
                params: req,
                extra,
            }) as Promise<IResponseTypes['GET/office/asset/user/{id}/fixed_assets']>
        },
        /**
         * 接口名：办公室资产-创建盘点任务
         * @param req 请求参数
         * @param extra 请求配置项
         */
        'POST/office/asset/task': (
            req?: IModels['POST/office/asset/task']['Req'],
            extra?: commonLib.IExtra
        ) => {
            return rapperFetch({
                url: '/office/asset/task',
                method: 'POST',
                params: req,
                extra,
            }) as Promise<IResponseTypes['POST/office/asset/task']>
        },
        /**
         * 接口名：办公室资产-盘点任务列表
         * @param req 请求参数
         * @param extra 请求配置项
         */
        'POST/office/asset/task/list': (
            req?: IModels['POST/office/asset/task/list']['Req'],
            extra?: commonLib.IExtra
        ) => {
            return rapperFetch({
                url: '/office/asset/task/list',
                method: 'POST',
                params: req,
                extra,
            }) as Promise<IResponseTypes['POST/office/asset/task/list']>
        },
        /**
         * 接口名：办公室资产-删除盘点任务
         * @param req 请求参数
         * @param extra 请求配置项
         */
        'POST/office/asset/task/delete': (
            req?: IModels['POST/office/asset/task/delete']['Req'],
            extra?: commonLib.IExtra
        ) => {
            return rapperFetch({
                url: '/office/asset/task/delete',
                method: 'POST',
                params: req,
                extra,
            }) as Promise<IResponseTypes['POST/office/asset/task/delete']>
        },
        /**
         * 接口名：办公室资产-盘点任务对应的资产列表
         * @param req 请求参数
         * @param extra 请求配置项
         */
        'POST/office/asset/task/assets': (
            req?: IModels['POST/office/asset/task/assets']['Req'],
            extra?: commonLib.IExtra
        ) => {
            return rapperFetch({
                url: '/office/asset/task/assets',
                method: 'POST',
                params: req,
                extra,
            }) as Promise<IResponseTypes['POST/office/asset/task/assets']>
        },
        /**
         * 接口名：办公室资产-该盘点任务中对应的我的资产
         * @param req 请求参数
         * @param extra 请求配置项
         */
        'POST/office/asset/task/my_assets': (
            req?: IModels['POST/office/asset/task/my_assets']['Req'],
            extra?: commonLib.IExtra
        ) => {
            return rapperFetch({
                url: '/office/asset/task/my_assets',
                method: 'POST',
                params: req,
                extra,
            }) as Promise<IResponseTypes['POST/office/asset/task/my_assets']>
        },
        /**
         * 接口名：办公室资产-批量确认资产
         * @param req 请求参数
         * @param extra 请求配置项
         */
        'POST/office/asset/task/batch_confirm_assets': (
            req?: IModels['POST/office/asset/task/batch_confirm_assets']['Req'],
            extra?: commonLib.IExtra
        ) => {
            return rapperFetch({
                url: '/office/asset/task/batch_confirm_assets',
                method: 'POST',
                params: req,
                extra,
            }) as Promise<IResponseTypes['POST/office/asset/task/batch_confirm_assets']>
        },
        /**
         * 接口名：办公室资产-确认资产
         * @param req 请求参数
         * @param extra 请求配置项
         */
        'POST/office/asset/task/confirm_asset': (
            req?: IModels['POST/office/asset/task/confirm_asset']['Req'],
            extra?: commonLib.IExtra
        ) => {
            return rapperFetch({
                url: '/office/asset/task/confirm_asset',
                method: 'POST',
                params: req,
                extra,
            }) as Promise<IResponseTypes['POST/office/asset/task/confirm_asset']>
        },
        /**
         * 接口名：办公室资产-通知当前盘点任务的未确认资产的用户
         * @param req 请求参数
         * @param extra 请求配置项
         */
        'POST/office/asset/task/notify_unconfirmed_user': (
            req?: IModels['POST/office/asset/task/notify_unconfirmed_user']['Req'],
            extra?: commonLib.IExtra
        ) => {
            return rapperFetch({
                url: '/office/asset/task/notify_unconfirmed_user',
                method: 'POST',
                params: req,
                extra,
            }) as Promise<IResponseTypes['POST/office/asset/task/notify_unconfirmed_user']>
        },

    }
}