import axios from '@/http/index'
import {preParams, Extra, BasicTableResult} from "../index";
// 单据审批
export default {
    /**
     * @description: 提现购销取消校验
     * @return {void}
    */
    'PATCH/invoices/update_info': (params: any, extra?: Extra) => {
        return axios(`/invoices/update_info`, preParams(params, extra, 'patch'))
    },
    /**
     * @description: 根据ids获取发票列表
     * @return {void}
    */
    'POST/invoices/reimbursement': (params: any, extra?: Extra) => {
        return axios(`/invoices/reimbursement`, preParams(params, extra, 'post'))
    },
}