/* 询证函模块 */

import * as commonLib from '../modules/commonLib'
import {IResponseTypes, IModels} from './request'

export default function createFetch(fetchConfig: any) {
  const rapperFetch = commonLib.getRapperRequest(fetchConfig)

  return {
    /**
     * 接口名：询证函列表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=535128&itf=2399389
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/confirmation/page': (req?: IModels['POST/confirmation/page']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/confirmation/page',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/confirmation/page']>
    },

    /**
     * 接口名：创建询证记录
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=535128&itf=2400239
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/confirmation/create': (req?: IModels['POST/confirmation/create']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/confirmation/create',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/confirmation/create']>
    },
    /**
     * 接口名：应收账款公司币种汇总
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/order/receivable/annual_statistics/summary': (req?: IModels['POST/order/receivable/annual_statistics/summary']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/order/receivable/annual_statistics/summary',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/order/receivable/annual_statistics/summary']>
    },

    /**
     * 接口名：删除询证函
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=535128&itf=2400560
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/confirmation/delete': (req?: IModels['POST/confirmation/delete']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/confirmation/delete',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/confirmation/delete']>
    },

    /**
     * 接口名：下载询证函
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=535128&itf=2400561
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/confirmation/download': (req?: IModels['POST/confirmation/download']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/confirmation/download',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/confirmation/download']>
    },

    /**
     * 接口名：上传询证函
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=535128&itf=2401175
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/confirmation/upload': (req?: IModels['POST/confirmation/upload']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/confirmation/upload',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/confirmation/upload']>
    },

    /**
     * 接口名：更新询证函信息
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=535128&itf=2401309
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/confirmation/info/update': (
      req?: IModels['POST/confirmation/info/update']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/confirmation/info/update',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/confirmation/info/update']>
    },

    /**
     * 接口名：我的询证记录的发票信息
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=535128&itf=2401405
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/confirmation/invoices': (req?: IModels['POST/confirmation/invoices']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/confirmation/invoices',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/confirmation/invoices']>
    },

    /**
     * 接口名：创建询证函
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=535128&itf=2403286
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/confirmation/confirmation_file': (
      req?: IModels['POST/confirmation/confirmation_file']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/confirmation/confirmation_file',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/confirmation/confirmation_file']>
    },

    /**
     * 接口名：导出询证函
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=535128&itf=2403579
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/confirmation/export': (req?: IModels['POST/confirmation/export']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/confirmation/export',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/confirmation/export']>
    },

    /**
     * 接口名：生成NO
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=535128&itf=2403592
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PUT/confirmation/no/create': (req?: IModels['PUT/confirmation/no/create']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/confirmation/no/create',
        method: 'PUT',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PUT/confirmation/no/create']>
    },

    /**
     * 接口名：清除NO
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=535128&itf=2403593
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PUT/confirmation/no/clear': (req?: IModels['PUT/confirmation/no/clear']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/confirmation/no/clear',
        method: 'PUT',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PUT/confirmation/no/clear']>
    },

    /**
     * 接口名：修改询证函
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=535128&itf=2403771
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/confirmation/update': (req?: IModels['POST/confirmation/update']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/confirmation/update',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/confirmation/update']>
    },

    /**
     * 接口名：锁定or解锁询证函记录
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=535128&itf=2404134
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/confirmation/locked': (req?: IModels['POST/confirmation/locked']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/confirmation/locked',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/confirmation/locked']>
    },

    /**
     * 接口名：创建替代测试
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/confirmation/alternative_test': (req?: any, extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/confirmation/alternative_test',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<any>
    },

    /**
     * 接口名：询证函统计分析
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=535128&itf=2414561
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/confirmation/statistics': (
      req?: IModels['POST/confirmation/statistics']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/confirmation/statistics',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/confirmation/statistics']>
    },
  }
}