/**
 * This is just a simple version of deep copy
 * Has a lot of edge cases bug
 * If you want to use a perfect deep copy, use lodash's _.cloneDeep
 * @param {Object} source
 * @returns {Object}
 */
import dayjs from 'dayjs'
import { ElMessage } from 'element-plus'

export function deepClone(source: any) {
  if (!source && typeof source !== "object") {
    throw new Error("error arguments")
  }
  const targetObj: any = source.constructor === Array ? [] : {};
  Object.keys(source).forEach((keys) => {
    if (source[keys] && typeof source[keys] === "object") {
      targetObj[keys] = deepClone(source[keys]);
    } else {
      targetObj[keys] = source[keys];
    }
  });
  return targetObj;
}

/**
 * 格式化时间
 * @param cellValue {String}
 * @returns String
 */
export function formatTime(row: Object, column: any, cellValue: number, index: number) :string {
  if(!cellValue) return ''
  return dayjs(cellValue).format('YYYY-MM-DD HH:mm')
}

/**
 * 格式化完整时间
 * @param cellValue {String}
 * @returns String
 */
export function formatFullTime(row: Object, column: any, cellValue: number, index: number) :string {
  if(!cellValue) return ''
  return dayjs(cellValue).format('YYYY-MM-DD HH:mm:ss')
}

/**
 * 保留两位小数，以及千分位
 * @param numb {Number}
 * @returns String
 */
export function getNumber(numb?: number | string | null | undefined): string {
  
  if(numb === null || numb === undefined) return '-';
  // 转换成数字
  const numStr: any = parseFloat(numb as any).toLocaleString('zh', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

  return numStr === '0.00' ? '-' : numStr

  // 使用正则表达式进行替换
  // return strNum.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

}



export function formatNumber(row: Object, column: any, cellValue: number, index: number) : string {
  return getNumber(cellValue)
}

/*  */
/**
 * 数组降维（多维转一维）
 *
 * @param {*} arr
 * @returns
 */
/*  export const dimensionReduce = function(arr: any) {
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].children) {
      arr = arr.slice(0, i).concat(arr[i].children, arr.slice(i + 1))
      i--
      // delete arr[i].children
    }
  }
  
  return arr
} */

export function dimensionReduce (children: any ) {
  return children.reduce((arr: object[], _child: {children?: any[]}) => {
    // _routes.children.push(dimensionReduce(_child))
    if(_child.children) {
      arr = arr.concat(dimensionReduce(_child.children))
    } else {
      arr.push(_child)
    }
    return arr
  }, [])
  
}

// ------
/**
 *  format bank card number
 * @param {number} phoneNumber 
 * @returns {sgring}
 */
export function bankCardFormat(cardNumber: any) {
  if(!cardNumber){
      return ''
  }
  // 移除所有非数字字符
  const cleanedNumber = (cardNumber + '').replace(/\D/g, '');
  // 将数字按每4位分组，使用空格隔开
  const formattedNumber = cleanedNumber.replace(/(\d{4})(?=\d)/g, '$1 ');
  return formattedNumber;
}
/**
 * 下载文件
 * @param exportFunc exportApi
 * @param privatePam expoetApi params
 */

export function exportHandle (exportFunc: any, privatePam: object, requireParams = {}) {

  return exportFunc(privatePam, {timeout: 5 * 60000, responseType: 'blob', ...requireParams}).then((stream: any) => {
    // 获取文件名
    let fileName = decodeURI(stream.headers['content-disposition'].split(';')[1].split('filename=')[1])
    let url = window.URL.createObjectURL(
      new Blob([stream.data], { type: 'application/vnd.ms-excel;charset=utf-8' })
    )
  
    // 创建A标签下载
    let link = document.createElement("a")
    link.style.display = "none"
    link.href = url
    link.setAttribute("download", decodeURI(fileName))
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }).catch((error: any) => {
    console.log("download error",error)
    let reader = new FileReader()
    reader.onload = (e: any) => {
      ElMessage.error(JSON.parse(e.target.result).message || '下载失败')
    }
    reader.readAsText(error.response.data)
    return Promise.reject()
  })


  /* let stream = await exportFunc( privatePam, {timeout: 5 * 60000, responseType: 'blob'})
  let fileName = decodeURI(stream.headers['content-disposition'].split(';')[1].split('filename=')[1])
  let url = window.URL.createObjectURL(
    new Blob([stream.data], { type: 'application/vnd.ms-excel;charset=utf-8' })
  )

  let link = document.createElement("a")
  link.style.display = "none"
  link.href = url
  link.setAttribute(
    "download",
    decodeURI(fileName)
  )
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link) */
}

/**
 * rbg => hex
 * @param r red
 * @param g green
 * @param b blue
 * @returns color hex
 */
export const rgbToHex = (r: number, g: number, b: number) =>"#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);

/**
 * 返回当前每页条数下,最大的页数
 * @param _total {number} 总条数
 * @param _page {number} 当前页面
 * @param _size {number} 每页条数
 * @returns {number}
 */
export const calcPageNumb = (_total: number, _page: number, _size: number) => {
  // 如果不在第一页，并且数据不为0
  if(!(_page <= 1 || _total <= 0)){
    // ，向上取整，获取最大页数
    let maxPage = Math.ceil( _total / _size )
    // 返回页数或者最大页数
    return maxPage < _page ? maxPage : _page
  }
  // 返回首页
  return 1
}

/**
 * 格式化文件大小
 * @param bytes {number}
 * @returns {string}
 */

export function formatFileSize(bytes: number) {
  if (bytes === 0 || !bytes) return '-';

  const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString());

  return (bytes / Math.pow(1024, i)).toFixed(2) + ' ' + sizes[i];
}

/**
 * @description 格式化百分数
 * @param {number} rate 百分数
 * @returns {string}
 */
export const formatRate = (row: any, column: any, rate: number, index: number) => {
  if(rate === 0 || !rate) return '-';
  return (rate * 100).toFixed(2) + '%'
}