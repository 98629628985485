/* 授权管理模块 */

import * as commonLib from '../modules/commonLib'
import {IResponseTypes, IModels} from './request'

export default function createFetch(fetchConfig: any) {
  const rapperFetch = commonLib.getRapperRequest(fetchConfig)

  return {
    /**
* 接口名：获取用户列表
* Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=508910&itf=2256870
* @param req 请求参数
* @param extra 请求配置项
*/
    /* 'GET/users': (req?: IModels['GET/users']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/users',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/users']>
    }, */

    /**
     * 接口名：创建资源
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=508910&itf=2252268
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'POST/resources': (req?: IModels['POST/resources']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/resources',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/resources']>
    }, */

    /**
     * 接口名：编辑资源
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=508910&itf=2255762
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'PUT/resources': (req?: IModels['PUT/resources']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/resources',
        method: 'PUT',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PUT/resources']>
    }, */

    /**
     * 接口名：资料列表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=508910&itf=2252265
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'GET/resources': (req?: IModels['GET/resources']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/resources',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/resources']>
    }, */

    /**
     * 接口名：获取资源
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=508910&itf=2252266
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'GET/resources/{id}': (req?: IModels['GET/resources/{id}']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/resources/{id}',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/resources/{id}']>
    }, */

    /**
     * 接口名：删除资源
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=508910&itf=2252312
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'DELETE/resources/{id}': (req?: IModels['DELETE/resources/{id}']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/resources/{id}',
        method: 'DELETE',
        params: req,
        extra,
      }) as Promise<IResponseTypes['DELETE/resources/{id}']>
    }, */

    /**
     * 接口名：角色列表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=508910&itf=2252313
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'GET/roles': (req?: IModels['GET/roles']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/roles',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/roles']>
    }, */

    /**
     * 接口名：创建角色
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=508910&itf=2252315
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'POST/roles': (req?: IModels['POST/roles']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/roles',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/roles']>
    }, */

    /**
     * 接口名：更新角色
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=508910&itf=2255600
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'PUT/roles': (req?: IModels['PUT/roles']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/roles',
        method: 'PUT',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PUT/roles']>
    }, */

    /**
     * 接口名：删除角色
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=508910&itf=2252316
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'DELETE/roles/{id}': (req?: IModels['DELETE/roles/{id}']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/roles/{id}',
        method: 'DELETE',
        params: req,
        extra,
      }) as Promise<IResponseTypes['DELETE/roles/{id}']>
    }, */

    /**
     * 接口名：创建员工角色
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=508910&itf=2252318
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'POST/users/privileges': (req?: IModels['POST/users/privileges']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/users/privileges',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/users/privileges']>
    }, */

    /**
     * 接口名：员工角色列表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=508910&itf=2254581
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'GET/users/privileges': (req?: IModels['GET/users/privileges']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/users/privileges',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/users/privileges']>
    }, */

    /**
     * 接口名：删除员工角色
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=508910&itf=2256895
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'DELETE/users/privileges/{id}': (
      req?: IModels['DELETE/users/privileges/{id}']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/users/privileges/{id}',
        method: 'DELETE',
        params: req,
        extra,
      }) as Promise<IResponseTypes['DELETE/users/privileges/{id}']>
    }, */

    /**
     * 接口名：员工角色
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=508910&itf=2262636
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'GET/users/privileges/{userId}': (
      req?: IModels['GET/users/privileges/{userId}']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/users/privileges/{userId}',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/users/privileges/{userId}']>
    }, */

    /**
     * 接口名：获取权限
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=508910&itf=2275611
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'GET/permission': (req?: IModels['GET/permission']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/permission',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/permission']>
    }, */

    /**
     * 接口名：获取创建资源多选
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=508910&itf=2321865
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'GET/resources/actions': (req?: IModels['GET/resources/actions']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/resources/actions',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/resources/actions']>
    }, */

    /**
     * 接口名：获取某个资源，被多少人使用
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=508910&itf=2350593
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'GET/resources/{id}/use': (req?: IModels['GET/resources/{id}/use']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/resources/{id}/use',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/resources/{id}/use']>
    }, */

    /**
     * 接口名：导出资源使用情况列表
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'POST/resources/use/export': (req?: IModels['POST/resources/use/export']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/resources/use/export',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/resources/use/export']>
    }, */

    /**
     * 接口名：其他平台账号列表
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'POST/sso/account/list': (req?: IModels['POST/sso/account/list']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/sso/account/list',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/sso/account/list']>
    }, */

    /**
     * 接口名：其他平台账号权限详情
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'POST/sso/account/permissions': (req?: IModels['POST/sso/account/permissions']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/sso/account/permissions',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/sso/account/permissions']>
    }, */

    /**
     * 接口名：其他平台账号权限详情
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'POST/sso/update_platform_account_permissions': (req?: IModels['POST/sso/update_platform_account_permissions']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/sso/update_platform_account_permissions',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/sso/update_platform_account_permissions']>
    }, */

    /**
     * 接口名：创建其他平台
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'POST/sso/platform': (req?: IModels['POST/sso/platform']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/sso/platform',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/sso/platform']>
    }, */

    /**
     * 接口名：获取所有其他平台
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'GET/sso/platforms': (req?: IModels['GET/sso/platforms']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/sso/platforms',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/sso/platforms']>
    },

    /**
     * 接口名：批量导入单个平台的多个用户权限
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'POST/sso/batch_import_platform_permissions': (req?: any, extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/sso/batch_import_platform_permissions',
        method: 'POST',
        params: req,
        extra,
      }) as any
    }, */


  }
}