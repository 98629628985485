/* 新勾销模块 */

import * as commonLib from '../modules/commonLib'
import {IResponseTypes, IModels} from './request'

export default function createFetch(fetchConfig: any) {
  const rapperFetch = commonLib.getRapperRequest(fetchConfig)

  return {
    /**
     * 接口名：新勾销
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=542061&itf=2433981
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/verification/liquidate': (req?: IModels['POST/verification/liquidate']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/verification/liquidate',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/verification/liquidate']>
    },

    /**
     * 接口名：未匹配客户流水
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=542061&itf=2434007
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/verification/liquidate/not_match_flow': (
      req?: IModels['POST/verification/liquidate/not_match_flow']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/verification/liquidate/not_match_flow',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/verification/liquidate/not_match_flow']>
    },

    /**
     * 接口名：数据集成展示
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=542061&itf=2435997
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/verification/flow_authorize': (
      req?: IModels['POST/verification/flow_authorize']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/verification/flow_authorize',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/verification/flow_authorize']>
    },

    /**
     * 接口名：授权
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=542061&itf=2436051
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/verification/flow_authorize/authorize': (
      req?: IModels['POST/verification/flow_authorize/authorize']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/verification/flow_authorize/authorize',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/verification/flow_authorize/authorize']>
    },

    /**
     * 接口名：订单饼图
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=542061&itf=2436463
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/verification/flow_authorize/pie': (
      req?: IModels['POST/verification/flow_authorize/pie']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/verification/flow_authorize/pie',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/verification/flow_authorize/pie']>
    },

    /**
     * 接口名：根据英文名搜索销售
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=542061&itf=2438143
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/users/dropdown/alias': (req?: IModels['GET/users/dropdown/alias']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/users/dropdown/alias',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/users/dropdown/alias']>
    },

    /**
     * 接口名：根据客户名、客户ID模糊搜索
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=542061&itf=2438142
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/verification/accounts/dropdown/search': (
      req?: IModels['GET/verification/accounts/dropdown/search']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/verification/accounts/dropdown/search',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/verification/accounts/dropdown/search']>
    },

    /**
     * 接口名：流水订单的发票获取
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=542061&itf=2440371
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/verification/liquidate/invoices': (
      req?: IModels['POST/verification/liquidate/invoices']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/verification/liquidate/invoices',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/verification/liquidate/invoices']>
    },

    /**
     * 接口名：汇率_手动更新
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=542061&itf=2441586
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/exchange_rates/manual_sync': (
      req?: IModels['POST/exchange_rates/manual_sync']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/exchange_rates/manual_sync',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/exchange_rates/manual_sync']>
    },

    /**
     * 接口名：补充勾销
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=542061&itf=2442303
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/verification/liquidate/again': (
      req?: IModels['POST/verification/liquidate/again']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/verification/liquidate/again',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/verification/liquidate/again']>
    },

    /**
     * 接口名：根据客户ID获取流水
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=542061&itf=2442881
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/sale/customer/flows': (req?: IModels['GET/sale/customer/flows']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/sale/customer/flows',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/sale/customer/flows']>
    },

    /**
     * 接口名：转汇为USD金额
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=542061&itf=2443864
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/exchange_rates/convert/usd': (
      req?: IModels['GET/exchange_rates/convert/usd']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/exchange_rates/convert/usd',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/exchange_rates/convert/usd']>
    },

    /**
     * 接口名：根据发票号/订单号查询
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=542061&itf=2447927
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/verification/liquidate/nos': (
      req?: IModels['POST/verification/liquidate/nos']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/verification/liquidate/nos',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/verification/liquidate/nos']>
    },

    /**
     * 接口名：根据订单号导出
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/verification/liquidate/nos/export': (
      req?: IModels['POST/verification/liquidate/nos/export']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/verification/liquidate/nos/export',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/verification/liquidate/nos/export']>
    },

    /**
     * 接口名：取消关联
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=542061&itf=2448369
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/invoices/cancel_invoice': (
      req?: IModels['PATCH/invoices/cancel_invoice']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/invoices/cancel_invoice',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/invoices/cancel_invoice']>
    },
  }
}