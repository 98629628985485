export default {
  'app': {
    homePage: {
      welcome: '欢迎来到火星语盟'
    },
    'salaryDetail': {
      'tableTitle': '工资明细',
      'name': '姓名',
      'enName': '英文名',
      'department': '所属部门',
      'date': '月份',
      'noData': '暂无此月数据',
      'confirmSalary': '确认工资',
      'confirmPrompt': '是否确认本月工资：',
      'confirmCancel': '已取消确认',
      'success': '成功',
      'rate': '参考汇率：',
      'explain': '说明',

      'summaryTable': '总表',
      'project': '项目',
      'totalSalary': '工资项',
      'totalAttendance': '考勤项',
      'totalBonus': '奖金项',
      'totalCommission': '提成项',
      'otherBenefits': '其他应发项',
      'paymentDueMonth': '应发合计',
      'personalInsuranceTax': '社保个税项目',
      'otherDeductedFee': '其他应扣项目',
      'totalDeducted': '应扣合计',
      'totalActualSalary': '实发数合计',
      'payFromFinancialPart': '工资卡',
      'filialPiety': '尽孝金',
      'oneTimeReward': '一次性奖励',

      'issuedThroughPayroll': '工资表发放',
      'issuedThroughOtherChannels': '已单独支付',
      'summary': '合计计税金额',
      'remark': '备注',
      'dividerTips': '明细',

      'total': '合计:',
      'salary': '月度工资',
      'basicSalary': '基本工资',
      'jobAllowance': '职务津贴',
      'positionAllowance': '年度贡献奖',
      'otherAllowance': '其他补贴',
      'companyAllowance': '公司补助',

      'InsuranceTax': '社保个税项目',
      'personalInsurance': '个人社保',
      'personalHousing': '个人公积金',
      'personalIncomeTax': '个人所得税',

    },
    'DMS':{
      'Policies & Regulations':'政策制度',
      'Sales Kit':'销售套件',
      'Mars Resources':'火星资源',
      'Download History':'下载记录',
      'Recycle Bin':'回收站',
      'Trash Empty':'清空回收站',
      'Restore':'还原',
      'Original Location':'原位置',
      'Date Deleted':'删除时间',      
      'Download':'下载',
      'Upload':'上传',
      'Search':'搜索文件',
      'PDF Convert':'转 PDF',
      'New Folder':'新建文件夹',
      'Rename':'重命名',
      'Change Owner':'更改负责人',
      'Share':'分享',
      'Move':'移动',
      'Delete':'删除',
      'Update':'更新',
      'Font Check':'检查字体',
      'Refresh':'刷新',
      'Go Back':'返回上一级',
      'File/Folder':'文件/文件夹',
      'Security Level':'密级',
      'Public':'公开',
      'Confidential':'秘密',
      'Access Permission':'访问权限',
      'All members':'全部成员可见',
      'Some members':'部分成员可见',
      'Administrator':'管理员可见',
      'Owner':'负责人',
      'Date Updated':'更新时间',
      'General Info':'文件详情',
      'Type':'文件格式',
      'Date Created':'创建时间',
      'Size':'文件大小',
      'Contains':'包含',
      'Version':'版本号',
      'History':'历史',
      'Last download by':'最近下载人',
      'Comments':'备注',
      'fileUpload':{
        'File Upload':'上传文件',
        'UploadActionTip':'拖拽文件或者<em>点此上传</em>',
        'UploadTips':'不支持压缩包文件，且只能上传大小不超过 1G 的文件',
        'File Name':'文件名',
        'EnterTips':'请输入文件名',
        'VersionTip':'<p>如不想使用系统版本号</p><p>务必按公司文档管理规范，填写正确的版本号</p>',
        'Security Level':'文档密级',
        'SecurityLevelTips':'<span>秘密：不属于涉密文件资料，但属于公司内部的工作秘密，不能随意向他人公开。 <br>公开：可对公司以外人员公开传阅的文件。</span>',
        'NoteTip':'填写备注以便更好的了解文件，并且更容易被检索到',
        'Versionplaceholder':'请按公司规范，输入版本号',
        'Versionmessage':'请输入版本号',
        'NameLimit':'名称最大为200字符',
        'UnSupportType':'该文件类型不支持',
        'SizeLimit':'上传文件大小不可超过 1G',
        'FileChose':'请选择文件',
        'ChooseType':'请选择文件类型',
        'VersionRepeat':'版本号与历史版本号重复',
        'StopUpload':'已中止上传'
      },
      'File update':'更新文件',	
      'Alert':'提示',
      'Cancel':'取消',
      'OK':'确定',
      'Folder Name':'文件夹名称',
      'Folder owner is responsible for managing the directory and its contents. Please remind them to plan and manage the folder carefully.':'文件夹负责人，对目录以及目录底下的文件负有管理责任请告知负责人务必认真规划好，管理好自己的文件夹',
      'Move To':'移动到',
      'Move to here':'移动到这里',
      'Version history':'历史版本',
      'None':'无',
      'Files':'个文件',
      'Folders':'个文件夹',
      'Last Modifier':'最近更新人',
      'Preview':'预览',
      'Notice':'通知',
      'Watermarking':'加水印',
      'Document Notification':'文档通知',
      'Document Update Summary':'文档变更说明',
      'Notification Message':'将通过企业微信通知公司全员',
      'Modify security level':'点击修改密级',
      'Directory Owner':'目录负责人',
      'ApplyRange':'应用到全部子目录和文件',
      'Note':'请注意',
      'DirNoteFir':'目录负责人，对目录及其子目录，以及下面的全部<br/>文件负有管理责任',
      'DirNoteFir2':'目录负责人，对目录及其子目录，以及下面的全部文件负有管理责任',
      'DirNoteSec':'目录负责人必须要管理好自己的文件夹',
      'NewDirNoteMsg1':'文件夹负责人，对目录以及目录底下的文件负有管理责任',
      'NewDirNoteMsg2':'请告知负责人务必认真规划好，管理好自己的文件夹',
      'Enter folder name':'请输入文件夹名称',
      'Choose Owner':'请选择负责人',
      'File':"文件",
      'Actions':'操作',
      'File Share':'分享文件（夹）',
      'Share with link':'链接分享',
      'ShareNote':'通过分享的链接登录系统，可直接跳转到文件（夹）所在的目录',
      "Copy":'复制',
      'DownLoadNote':'文件中含有公司的工作秘密，不得擅自使用、上网发布或与他人分享。',
      'Got It':'知道了',
      'Add Watermark':'添加水印',
      'WatermarkNote':'选择一个水印文本',
      'Add Members':'选择成员',
      'AddMenbersTips':'请在左侧分栏中勾选并添加成员，或在企微复制群邮箱地址到搜索框，批量添加成员',
      'Selected':'已选成员',
      'MemberSelected':'请选择成员',
      'Message':{
        'FileNumLimit':'一次只能下载一个文件',
        'UpperLimit':'已超出当日文档下载上限！',
        'WaitTip':'请耐心等待，如果 PDF 文件转换失败，您也可以直接上传 PDF 文件或者将问题反馈给管理员。',
        'Conversion Successful':'转换成功',
        'TypeCheck':'请检查转换文件类型，是否可以转换',
        'FileSelect':"请选择一个文件/文件夹",
        'NameEmpty':'名称不能为空',
        'Rename Successful':'重命名成功',
        'Update Successful':'更新成功',
        'Copy Successful':'复制成功',
        'FolderSelect':'请选择一个文件夹',
        'Move Successful':'移动成功',
        'FileSelectOne':'请至少选择一个文件/文件夹',
        'Delete Successful':'删除成功',
        'SecurityLevelUpdated':'密级更新成功',
        'Notification':'已向公司全员发送通知',
        'Add successful':'添加成功'
      },
      'DoubleClick':'双击选取成员',
      'Username':'用户名',
      'Success':'成功',
      'FontDisabled':'属于禁用字体',
      'FindFont':'发现下列字体',
      'Delete File':'删除文件',
      'DelMsgFir':'确认彻底删除全部？删除后将无法恢复',
      'DelMsgSec':'确实要永久删除吗？将无法找回哦！',
      'Delete Permanently':'彻底删除',
      'AllDel':'全部删除成功',
      'Successfully restored':'还原成功',
      'SecurityLevelSelect':'请选择文档密级',
      'Modification successful':'修改成功',
      'Creation successful':'创建成功',
      'DelAlert':'确定要放入回收站吗？',
      'CancelUploadingTip':'文件上传中，是否取消上传?',
      "TerminatedUpload":'已中止上传',
      'NewUploadTip':'上传成功的文件默认其文档密级为“秘密”，并且只有上传者本人有权限查看，如需修改，请到文件所在文件夹进行操作。',
      'Permissions':'权限管理',
      'Permission':'访问权限',
      'Security':'文档密级',
      'SelOneFile':'请选择至少一个文件',
      'HasFolders':'勾选的文件中含有文件夹',
      'SettingSuccess':'设置成功',
      'Close':'关闭'

    }
  }
}