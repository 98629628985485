import axios from '@/http/index'
import {preParams, Extra, BasicTableResult} from "../index";
// import {} from './model/individualModel';

export default {

  /**
   * @description: 坏账_待收款订单_查询
   * @param {number} page 页数
   * @param {size} size 页面条数
   * @param {string} orderNo 订单编号
   * @param {string} customer
   * @param {string} owner 客户经理
   * @param {string} extraDays
   * @return {array}
   */
  'POST/bad_debt/finance/unreceived_payments/query': (params: any, extra: Extra) => {
    return axios(`/bad_debt/finance/unreceived_payments/query`, preParams(params, extra, 'post'))
  },
  
  /**
   * @description: 坏账_坏账批次_新建坏账批次
   * @param {string} name 批次名称
   * @param {array} orderIds 订单ids
   * @return {void}
   */
  'POST/bad_debt/pack/create_pack': (params: {name: string, orderIds: number[]}, extra: Extra) => {
    return axios(`/bad_debt/pack/create_pack`, preParams(params, extra, 'post'))
  },
  
  /**
   * @description: 坏账_坏账批次_分页查询
   * @param {number} page
   * @param {number} size
   * @return {void}
   */
  'POST/bad_debt/pack/query': (params: {page: number, size: number}, extra: Extra) => {
    return axios(`/bad_debt/pack/query`, preParams(params, extra, 'post'))
  },
  
  /**
   * @description: 坏账_坏账批次_获取某批详情
   * @param {number} id
   * @return {void}
   */
  'POST/bad_debt/pack/detail': (params: {id: number}, extra: Extra) => {
    return axios(`/bad_debt/pack/detail`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 坏账_申报后同步co2状态
   * @param {number} id
   * @return {void}
   */
  'POST/bad_debt/finance/sync': (params: {id: number}, extra: Extra) => {
    return axios(`/bad_debt/finance/sync`, preParams(params, extra, 'post'))
  },
  
  /**
   * @description: 坏账_坏账批次_获取某批详情
   * @param {array} ids
   * @return {void}
   */
  'POST/bad_debt/pack/details': (params: {ids: number[]}, extra: Extra) => {
    return axios(`/bad_debt/pack/details`, preParams(params, extra, 'post'))
  },
  
  /**
   * @description: 坏账待收款订单_导出
   * @param {number} id
   * @return {void}
   */
  'POST/bad_debt/finance/unreceived_payments/download': (params: {id: number}, extra: Extra) => {
    return axios(`/bad_debt/finance/unreceived_payments/download`, preParams(params, extra, 'post'))
  },
  
  /**
   * @description: 坏账_坏账批次_加入到一个批次
   * @param {number} packId 批次id
   * @param {array} orderIds 订单ids
   * @return {void}
   */
  'POST/bad_debt/pack/add_to_pack': (params: {packId: number, orderIds: number[]}, extra: Extra) => {
    return axios(`/bad_debt/pack/add_to_pack`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 坏账_待收款订单_确认坏账
   * @param {array} idList
   * @param {boolean} isEnforced 是否强制执行
   * @return {void}
   */
  'POST/bad_debt/finance/unreceived_payments/confirm_bab_debt': (params: any, extra: Extra) => {
    return axios(`/bad_debt/finance/unreceived_payments/confirm_bab_debt`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 坏账_待确认_查询
   * @return {array}
   */
  'POST/bad_debt/finance/unconfirmed/query': (params: any, extra: Extra) => {
    return axios(`/bad_debt/finance/unconfirmed/query`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 坏账_确认坏账_重新收款
   * @param {number} id
   * @return {void}
   */
  'POST/bad_debt/finance/recharge': (params: {id: number}, extra: Extra) => {
    return axios(`/bad_debt/finance/recharge`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 坏账_确认坏账_申报坏账
   * @param {array} idList
   * @return {void}
   */
  'POST/bad_debt/finance/declare_bad_debt': (params: {idList: number[]}, extra: Extra) => {
    return axios(`/bad_debt/finance/declare_bad_debt`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 坏账_坏账批次_关闭
   * @param {number} id
   * @return {void}
   */
  'PATCH/bad_debt/pack/closed': (params: {id: number}, extra: Extra) => {
    return axios(`/bad_debt/pack/closed`, preParams(params, extra, 'patch'))
  },

  /**
   * @description: 坏账_申报坏账_查询
   * @return {array}
   */
  'POST/bad_debt/finance/declare_bad_debt/query': (params: any, extra: Extra) => {
    return axios(`/bad_debt/finance/declare_bad_debt/query`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 坏账_申报坏账_财务确认
   * @return {void}
   */
  'POST/bad_debt/finance/finance_confirm': (params: any, extra: Extra) => {
    return axios(`/bad_debt/finance/finance_confirm`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 坏账_待确认_查询
   * @return {array}
   */
  'POST/bad_debt/sale/unconfirmed/query': (params: any, extra: Extra) => {
    return axios(`/bad_debt/sale/unconfirmed/query`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 坏账_确认坏账
   * @param {number} id
   * @param {number} orderId
   * @param {number} confirmType 0:接受，1:拒绝
   * @param {string} rejectReason 拒绝原因
   * @return {void}
   */
  'POST/bad_debt/sale/confirmed': (params: any, extra: Extra) => {
    return axios(`/bad_debt/sale/confirmed`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 坏账_确认坏账_查询
   * @return {array}
   */
  'POST/bad_debt/sale/confirmed/query': (params: any, extra: Extra) => {
    return axios(`/bad_debt/sale/confirmed/query`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 坏账_确认坏账_重新收款
   * @param {number} id
   * @return {void}
   */
  'POST/bad_debt/sale/recharge': (params: {id: number}, extra: Extra) => {
    return axios(`/bad_debt/sale/recharge`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 坏账_申报坏账_查询
   * @param {string} orderNo 订单编号
   * @param {string} customer
   * @param {string} owner 客户经理
   * @return {array}
   */
  'POST/bad_debt/sale/declare_bad_debt/query': (params: {id: number}, extra: Extra) => {
    return axios(`/bad_debt/sale/declare_bad_debt/query`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 坏账_重新收款记录_查询
   * @return {array}
   */
  'POST/bad_debt/finance/recharge/query': (params: {id: number}, extra: Extra) => {
    return axios(`/bad_debt/finance/recharge/query`, preParams(params, extra, 'post'))
  },
}