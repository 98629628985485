import axios from '@/http/index'
import {preParams, Extra, BasicTableResult} from "../index";
import {
  LaborHomeSearch,
  LaborDocSearch,
  LaborAdd,
} from './model/laborModel';

export default {
  /**
   * @description: 合同首页-查看获取最新合同
   * @param {string} userName 姓名
   * @param {string} approvalStatus 状态
   * @param {number} companyId 公司ID
   * @return {void}
   */
  'POST/labor_contracts/latest': (params: LaborHomeSearch, extra?: Extra) => {
    return axios(`/labor_contracts/latest`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 获取劳动合同档案列表
   * @param {string} key 姓名
   * @param {string} no 合同编号
   * @param {number} companyId 公司ID
   * @param {number} orgId 团队ID
   * @return {void}
   */
  'POST/labor_contracts/list': (params: LaborDocSearch, extra?: Extra) => {
    return axios(`/labor_contracts/list`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 增-劳动合同信息
   * @param {number} userId 员工ID
   * @param {string} no 合同编号
   * @return {void}
   */
  'POST/labor_contracts': (params: LaborAdd, extra?: Extra) => {
    return axios(`/labor_contracts`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 更新-劳动合同信息
   * @param {number} userId 员工ID
   * @param {number} orgId 团队ID
   * @param {number} companyId 公司ID
   * @param {number} operator 劳动合同经办人ID
   * @param {number} rewardId 薪酬负责人ID
   * @param {number} leaderId 合伙人ID
   * @param {sgring} signTimes 签订次数
   * @param {string} no 合同编号
   * @param {string} category 合同状态
   * @param {string} startDate 合同开始日期
   * @param {string} endDate 合同结束日期
   * @param {number} baseSalary 基础薪酬
   * @param {number} probationPerformanceBonus 试用期绩效奖金
   * @param {number} performanceBonus 转正绩效奖金
   * @param {number} perfectAttendanceAward 全勤奖
   * @param {number} probationSalary 试用期工资总额
   * @param {number} regularSalary 转正后工资总额
   * @param {number} jobAllowance 职位津贴
   * @param {number} postAllowance 转正年度贡献奖
   * @param {number} otherAllowance 其他补贴
   * @param {string} officeSpace 工作地点
   * @param {string} otherAgreement 其他约定
   * @param {string} note 备注
   * @return {void}
   */
  'PATCH/labor_contracts': (params: LaborAdd, extra?: Extra) => {
    return axios(`/labor_contracts`, preParams(params, extra, 'patch'))
  },

  /**
   * @description: 删除-劳动合同记录
   * @param {number} id
   * @return {void}
   */
  'DELETE/labor_contracts/{id}': (params: {id: number}, extra?: Extra) => {
    return axios.delete<void>(`/labor_contracts/${params.id}`)
  },

  /**
   * @description: 劳务合同_设置为作废状态
   * @param {number} id
   * @return {void}
   */
  'PATCH/labor_contracts/home_page/waster': (params: {id: number}, extra?: Extra) => {
    return axios(`/labor_contracts/home_page/waster`, preParams(params, extra, 'patch'))
  },

  /**
   * @description: 上传-劳动合同文件
   * @param {number} id
   * @return {void}
   */
  'POST/labor_contracts/upload': (params: {id: number}, extra?: Extra) => {
    return axios(`/labor_contracts/upload`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 删除劳动合同文件
   * @param {number} contractId 合同ID
   * @param {number} fileId 文件ID
   * @return {void}
   */
  'DELETE/labor_contracts/delete_file': (params: {contractId: number, fileId: number}, extra?: Extra) => {
    return axios.delete<void>(`/labor_contracts/delete_file`, preParams(params, extra))
  },

  /**
   * @description: 获取劳动合同附件列表
   * @param {number} id 合同ID
   * @return {void}
   */
  'GET/labor_contracts/{id}': (params: {id: number}, extra?: Extra) => {
    return axios.get<void>(`/labor_contracts/${params.id}`, preParams(params, extra, 'get'))
  },

  /**
   * @description: 差异值查询(在职总人数和劳动合同数)
   * @return {void}
   */
  'GET/labor_contracts/diff': (params?: any, extra?: Extra) => {
    return axios.get<void>(`/labor_contracts/diff`)
  },

  /**
   * @description: 首页查看获取最新合同
   * @return {void}
   */
  'POST/labor_contracts/home_page/query': (params: LaborHomeSearch, extra?: Extra) => {
    return axios(`/labor_contracts/home_page/query`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 合同_审批状态拉下框
   * @return {void}
   */
  'GET/labor_contracts/drop_list/approval_status': (params?: any, extra?: Extra) => {
    return axios.get<object>(`/labor_contracts/drop_list/approval_status`)
  },

  /**
   * @description: 劳务合同首页_提交审批
   * @param {number} id 合同ID
   * @return {void}
   */
  'POST/labor_contracts/home_page/submit_approval': (params: {id: number}, extra?: Extra) => {
    return axios(`/labor_contracts/home_page/submit_approval`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 劳务合同_审批驳回
   * @param {number} id 合同ID
   * @return {void}
   */
  'PATCH/labor_contracts/home_page/reject': (params: {id: number}, extra?: Extra) => {
    return axios(`/labor_contracts/home_page/reject`, preParams(params, extra, 'patch'))
  },

  /**
   * @description: 劳务合同_审批通过
   * @param {number} id 合同ID
   * @return {void}
   */
  'POST/labor_contracts/home_page/approving': (params: {id: number}, extra?: Extra) => {
    return axios(`/labor_contracts/home_page/approving`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 合同_预览
   * @param {number} id 合同ID
   * @return {void}
   */
  'GET/labor_contracts/home_page/preview': (params: {id: number}, extra?: Extra) => {
    return axios.get<any>(`/labor_contracts/home_page/preview`, preParams(params, extra, 'get'))
  },

  /**
   * @description: 合同_生成合同编号
   * @param {string} category 合同类型
   * @return {void}
   */
  'GET/labor_contracts/generate/contract_no': (params: {category: string}, extra?: Extra) => {
    return axios.get<any>(`/labor_contracts/generate/contract_no`, preParams(params, extra, 'get'))
  },

  /**
   * @description: 合同_批量更新合同管理人
   * @param {array} ids 合同类型
   * @return {void}
   */
  'PATCH/labor_contracts/update_manger': (params: {ids: number[]}, extra?: Extra) => {
    return axios(`/labor_contracts/update_manger`, preParams(params, extra, 'patch'))
  },

  /**
   * @description: 获取当前用户所在团队团长
   * @param {number} userId 用户ID
   * @return {void}
   */
  'GET/organizations/get_owners': (params: {userId: number}, extra?: Extra) => {
    return axios.get<void>(`/organizations/get_owners`, preParams(params, extra, 'get'))
  },

  /**
   * @description: 获取需审批合同
   * @param {number} id 
   * @return {void}
   */
  'GET/labor_contracts/get/{id}': (params: {id: number}, extra?: Extra) => {
    return axios.get<void>(`/labor_contracts/get/${params.id}`, preParams(params, extra, 'get'))
  },

  /**
   * @description: 劳务合同首页_重新发起审批
   * @param {number} id 
   * @return {void}
   */
  'POST/labor_contracts/home_page/reapproval': (params: {id: number}, extra?: Extra) => {
    return axios(`/labor_contracts/home_page/reapproval`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 劳务合同档案_是否可签署合同
   * @param {number} userId 用户ID
   * @param {string} category 合同状态
   * @param {string} signTimes 签订次数
   * @return {boolean} canBeSigned true: 可以签订
   */
  'POST/labor_contracts/home_page/can_be_signed': (params: any, extra?: Extra) => {
    return axios(`/labor_contracts/home_page/can_be_signed`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 劳务合同_附件下载
   * @param {number} id 合同ID
   * @return {stream}
   */
  'POST/labor_contracts/download': (params: {id: number}, extra?: Extra) => {
    return axios(`/labor_contracts/download`, preParams(params, extra, 'post'))
  },
}