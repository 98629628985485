/* 项目模块 */

import * as commonLib from '../modules/commonLib'
import {IResponseTypes, IModels} from './request'

export default function createFetch(fetchConfig: any) {
  const rapperFetch = commonLib.getRapperRequest(fetchConfig)

  return {
    /**
     * 接口名：新增-项目
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=529341&itf=2368127
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'POST/projects': (req?: IModels['POST/projects']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/projects',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/projects']>
    }, */

    /**
     * 接口名：更新-项目
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=529341&itf=2368128
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'PATCH/projects': (req?: IModels['PATCH/projects']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/projects',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/projects']>
    }, */

    /**
     * 接口名：获取-项目列表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=529341&itf=2368129
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'GET/projects': (req?: IModels['GET/projects']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/projects',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/projects']>
    }, */

    /**
     * 接口名：删除-项目
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=529341&itf=2368130
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'DELETE/projects/{id}': (req?: IModels['DELETE/projects/{id}']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/projects/{id}',
        method: 'DELETE',
        params: req,
        extra,
      }) as Promise<IResponseTypes['DELETE/projects/{id}']>
    }, */

    /**
     * 接口名：获取-项目成员列表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=529341&itf=2368133
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'POST/project_members/list': (req?: IModels['POST/project_members/list']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/project_members/list',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/project_members/list']>
    }, */

    /**
     * 接口名：获取-项目成本人员勾选信息
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=529341&itf=2368154
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'POST/project_cost_time/user': (req?: IModels['POST/project_cost_time/user']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/project_cost_time/user',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/project_cost_time/user']>
    }, */

    /**
     * 接口名：产生个人项目参与时间
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=529341&itf=2368299
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'POST/project_cost_time/generate': (
      req?: IModels['POST/project_cost_time/generate']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/project_cost_time/generate',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/project_cost_time/generate']>
    }, */

    /**
     * 接口名：获取-项目成本人员勾选列表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=529341&itf=2368157
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'POST/project_cost_time/click': (
      req?: IModels['POST/project_cost_time/click']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/project_cost_time/click',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/project_cost_time/click']>
    }, */

    /**
     * 接口名：新增-项目成员
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=529341&itf=2368131
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'POST/project_members': (req?: IModels['POST/project_members']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/project_members',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/project_members']>
    }, */

    /**
     * 接口名：更新-项目成员
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=529341&itf=2368132
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'PATCH/project_members': (req?: IModels['PATCH/project_members']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/project_members',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/project_members']>
    }, */

    /**
     * 接口名：删除-项目成员
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=529341&itf=2368134
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'DELETE/project_members/{id}': (req?: IModels['DELETE/project_members/{id}']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/project_members/{id}',
        method: 'DELETE',
        params: req,
        extra,
      }) as Promise<IResponseTypes['DELETE/project_members/{id}']>
    }, */

    /**
     * 接口名：展示研发项目成本
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=529341&itf=2372748
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'POST/project_cost_details/list': (
      req?: IModels['POST/project_cost_details/list']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/project_cost_details/list',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/project_cost_details/list']>
    }, */

    /**
     * 接口名：获取研发工时
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=529341&itf=2373500
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'POST/project_cost_time/time': (req?: IModels['POST/project_cost_time/time']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/project_cost_time/time',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/project_cost_time/time']>
    }, */

    /**
     * 接口名：获取成本列表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=529341&itf=2373751
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'POST/project_cost': (req?: IModels['POST/project_cost']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/project_cost',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/project_cost']>
    }, */

    /**
     * 接口名：获取最新工资表成本
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=529341&itf=2373764
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'POST/project_cost/salary': (req?: IModels['POST/project_cost/salary']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/project_cost/salary',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/project_cost/salary']>
    }, */

    /**
     * 接口名：修改分摊日期
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=529341&itf=2373767
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'PATCH/project_cost': (req?: IModels['PATCH/project_cost']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/project_cost',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/project_cost']>
    }, */

    /**
     * 接口名：批量按月导出研发工时表并打包压缩文件
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=529341&itf=2375479
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'POST/project_cost_time/export': (
      req?: IModels['POST/project_cost_time/export']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/project_cost_time/export',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/project_cost_time/export']>
    }, */

    /**
     * 接口名：锁定项目成本
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=529341&itf=2382743
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'PATCH/project_cost_time/locked_time': (
      req?: IModels['PATCH/project_cost_time/locked_time']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/project_cost_time/locked_time',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/project_cost_time/locked_time']>
    }, */

    /**
     * 接口名：设置人员批量归属项目
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=529341&itf=2382940
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'POST/project_members/batch_set': (
      req?: IModels['POST/project_members/batch_set']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/project_members/batch_set',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/project_members/batch_set']>
    }, */

    /**
     * 接口名：根据人员获取所在项目
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=529341&itf=2384042
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'GET/projects/user': (req?: IModels['GET/projects/user']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/projects/user',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/projects/user']>
    }, */

    /**
     * 接口名：个人研发项目
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=529341&itf=2402357
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'POST/project_cost_time/owner': (
      req?: IModels['POST/project_cost_time/owner']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/project_cost_time/owner',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/project_cost_time/owner']>
    }, */

    /**
     * 接口名：获取未设置研发项目人员
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=529341&itf=2405555
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'GET/projects/unset': (req?: IModels['GET/projects/unset']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/projects/unset',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/projects/unset']>
    }, */

    /**
     * 接口名：取消工时确认
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=529341&itf=2438874
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'PATCH/project_cost_time/cancel_time': (
      req?: IModels['PATCH/project_cost_time/cancel_time']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/project_cost_time/cancel_time',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/project_cost_time/cancel_time']>
    }, */
  }
}