import {AxiosRequestConfig} from 'axios'
/** 请求的额外参数类型 */
export interface Extra extends AxiosRequestConfig {
  // 默认 'application/json'
  contentType?: 'application/json' | 'multipart/form-data' | 'application/x-www-form-urlencoded' | 'text/plain' | 'text/html' | 'application/javascript' | 'formData';
  // 请求 url 
  query?: {
    [key: string]: any;
  }
  // 扩展字段
  [key: string]: any;
}

export interface BasicTableResult<T> {
  data: T[];
  rowCount: number;
}



// http请求预处理
export const preParams: any = (params?: any, extra?: Extra, reqType?: string ) => {
  if(extra && extra.contentType === "formData"){
    // 请求头设置
    extra.headers = {'Content-Type': "multipart/form-data"}
    // 请求参数处理
    const formData = new FormData()
    for (let key in params) {
      formData.append(key, params[key])
    }
    params = formData
  }

  return {
    params: reqType?.toLowerCase() === "get" ? params : {},
    method: reqType,
    data: params,
    ...extra
  }
}