import axios from '@/http/index'
import {preParams, Extra, BasicTableResult} from "../index";
// import {} from './model/individualModel';

export default {
  /**
   * @description: 勾销校验
   * @return {void}
   */
  'POST/verification/liquidate/validate': (params: any, extra?: Extra) => {
    return axios(`/verification/liquidate/validate`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 获取流水绑定的订单
   * @param {array} isStr
   * @return {void}
   */
  'POST/financial_flows/orders_of_flow_product': (params: {idStr: number[]}, extra?: Extra) => {
    return axios(`/financial_flows/orders_of_flow_product`, preParams(params, extra, 'post'))
  },
}