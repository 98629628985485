import axios from '@/http/index'
import {preParams, Extra, BasicTableResult} from "../index";

export default {
  /**
   * @description: 批量删除科目凭证
   * @param {array} voucherNoList ids
   * @return {void}
   */
  'DELETE/voucher/delete': (params: {voucherNoList: number[]}, extra?: Extra) => {
    return axios.delete<void>(`/voucher/delete`, preParams(params, extra, 'delete'))
  },

  /**
   * @description: 单个删除科目凭证
   * @param {number}  id
   * @return {void}
   */
  'DELETE/voucher/single_delete/{id}': (params: {id: number}, extra?: Extra) => {
    return axios.delete<void>(`/voucher/single_delete/${params.id}`, preParams(params, extra, 'delete'))
  },

  /**
   * @description: 下载科目凭证
   * @return {void}
   */
  'POST/voucher_subject/download': (params: any, extra?: Extra) => {
    return axios(`/voucher_subject/download`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 新增凭证记录
   * @return {void}
   */
  'POST/voucher': (params: any, extra?: Extra) => {
    return axios(`/voucher`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 编辑凭证记录
   * @return {void}
   */
  'PATCH/voucher': (params: any, extra?: Extra) => {
    return axios(`/voucher`, preParams(params, extra, 'patch'))
  },

  /**
   * @description: 导出金蝶凭证
   * @return {void}
   */
  'POST/voucher/kingdee_voucher': (params: any, extra?: Extra) => {
    return axios(`/voucher/kingdee_voucher`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 辅助核算-列表分页
   * @return {void}
   */
  'POST/voucher_assists/page': (params: any, extra?: Extra) => {
    return axios(`/voucher_assists/page`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 新增-辅助核算
   * @param {string} number 编码
   * @param {string} name 名称
   * @param {string} note 备注
   * @return {void}
   */
  'POST/voucher_assists': (params: any, extra?: Extra) => {
    return axios(`/voucher_assists`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 编辑-辅助核算
   * @param {number} id
   * @param {string} number 编码
   * @param {string} name 名称
   * @param {string} note 备注
   * @return {void}
   */
  'PATCH/voucher_assists': (params: any, extra?: Extra) => {
    return axios(`/voucher_assists`, preParams(params, extra, 'patch'))
  },

  /**
   * @description: 删除-辅助核算
   * @param {number} id
   * @return {void}
   */
  'DELETE/voucher_assists/{id}': (params: {id: number}, extra?: Extra) => {
    return axios.delete<void>(`/voucher_assists/${params.id}`)
  },
  
  /**
   * @description: 下拉框-辅助核算
   * @return {void}
   */
  'GET/voucher_assists': () => axios.get(`/voucher_assists`),

  /**
   * @description: 凭证查询某天汇率
   * @return {void}
   */
  'POST/voucher/day_exchange_rate': (params: any, extra?: Extra) => {
    return axios(`/voucher/day_exchange_rate`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 辅助核算类别
   * @return {void}
   */
    'POST/voucher_assist_categorys/list': (params: any, extra?: Extra) => {
      return axios(`/voucher_assist_categorys/list`, preParams(params, extra, 'post'))
    },

  /**
   * @description: 新增辅助核算类别
   * @return {void}
   */
    'POST/voucher_assist_categorys': (params: any, extra?: Extra) => {
      return axios(`/voucher_assist_categorys`, preParams(params, extra, 'post'))
    },

  /**
   * @description: 编辑辅助核算类别
   * @return {void}
   */
    'PATCH/voucher_assist_categorys': (params: any, extra?: Extra) => {
      return axios(`/voucher_assist_categorys`, preParams(params, extra, 'patch'))
    },

  /**
   * @description: 删除辅助核算类别
   * @return {void}
   */
    'DELETE/voucher_assist_categorys/{id}': (params: any, extra?: Extra) => {
      return axios(`/voucher_assist_categorys/${params.id}`, preParams({}, extra, 'delete'))
    },

  /**
   * @description:  导出-辅助核算
   * @return {void}
   */
    'POST/voucher_assists/export': (params: any, extra?: Extra) => {
      return axios(`/voucher_assists/export`, preParams(params, extra, 'post'))
    },
  /**
   * @description:  凭证流水核对
   * @return {void}
   */
    'POST/voucher/check_voucher': (params: any, extra?: Extra) => {
      return axios(`/voucher/check_voucher`, preParams(params, extra, 'post'))
    },
  /**
   * @description:  类型列表
   * @return {void}
   */
    'POST/financial_flows/drop/flow_payment_type': (params: any, extra?: Extra) => {
      return axios(`/financial_flows/drop/flow_payment_type`, preParams(params, extra, 'post'))
    },
  /**
   * @description:  凭证流水核对导出
   * @return {void}
   */
    'POST/voucher/check_voucher_export': (params: any, extra?: Extra) => {
      return axios(`/voucher/check_voucher_export`, preParams(params, extra, 'post'))
    },

  /**
   * @description: 批量删除科目余额
   * @param {array} ids
   * @return {void}
   */
  'DELETE/order/receivable/balances/del': (params: {ids: number[]}, extra?: Extra) => {
    return axios.delete<void>(`/order/receivable/balances/del`, preParams(params, extra, 'delete'))
  },

  /**
   * @description: 按照约定规则生成凭证
   * @param {array} ids
   * @return {void}
   */
  'POST/voucher/rule_generate': (params: {ids: number[]}, extra?: Extra) => {
    return axios(`/voucher/rule_generate`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 获母子公司列表
   * @return {void}
   */
  'GET/companies/parent': () => {
    return axios.get(`/companies/parent`)
  },
  /**
   * @description: 打印凭证附件列表
   * @param {array} ids
   * @return {void}
   */
  'POST/voucher_prints/page': (params: {ids: number[]}, extra?: Extra) => {
    return axios(`/voucher_prints/page`, preParams(params, extra, 'post'))
  },
  /**
   * @description: 打印凭证附件列表
   * @param {array} ids
   * @return {void}
   */
  'DELETE/voucher_prints/batch_delete': (params: {ids: number[]}, extra?: Extra) => {
    return axios(`/voucher_prints/batch_delete`, preParams(params, extra, 'delete'))
  },
  /**
   * @description: 导入三方凭证模板
   * @param {array} ids
   * @return {void}
   */
  'POST/voucher_prints/import': (params: {ids: number[]}, extra?: Extra) => {
    return axios(`/voucher_prints/import`, preParams(params, extra, 'POST'))
  },
}