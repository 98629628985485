/* 我的模块 */

import * as commonLib from '../modules/commonLib'
import {IResponseTypes, IModels} from './request'

export default function createFetch(fetchConfig: any) {
  const rapperFetch = commonLib.getRapperRequest(fetchConfig)

  return {
    /**
     * 接口名：工资条
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=525640&itf=2330741
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PUT/salaries/payroll': (req?: IModels['PUT/salaries/payroll']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/salaries/payroll',
        method: 'PUT',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PUT/salaries/payroll']>
    },

    /**
     * 接口名：确认工资条
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=525640&itf=2349051
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PUT/salaries/payroll_confirm': (
      req?: IModels['PUT/salaries/payroll_confirm']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/salaries/payroll_confirm',
        method: 'PUT',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PUT/salaries/payroll_confirm']>
    },

    /**
     * 接口名：我的询证客户列表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=525640&itf=2399645
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/order/receivable/clients/my': (
      req?: IModels['POST/order/receivable/clients/my']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/order/receivable/clients/my',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/order/receivable/clients/my']>
    },

    /**
     * 接口名：销售确认询证函
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=525640&itf=2399645
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/confirmation/sale/confirm': (
      req?: IModels['POST/confirmation/sale/confirm']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/confirmation/sale/confirm',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/confirmation/sale/confirm']>
    },
  }
}