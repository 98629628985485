/* 数据报表模块 */

import * as commonLib from '../modules/commonLib'
import {IResponseTypes, IModels} from './request'

export default function createFetch(fetchConfig: any) {
  const rapperFetch = commonLib.getRapperRequest(fetchConfig)

  return {
    /**
     * 接口名：上传对账单
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=543044&itf=2439588
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/bank_receipt/create': (req?: IModels['POST/bank_receipt/create']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/bank_receipt/create',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/bank_receipt/create']>
    },

    /**
     * 接口名：获取公司以及相应时间(左)
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=543044&itf=2440857
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/bank_receipt/company_time': (
      req?: IModels['POST/bank_receipt/company_time']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/bank_receipt/company_time',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/bank_receipt/company_time']>
    },

    /**
     * 接口名：获取公司对账单
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=543044&itf=2440858
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/bank_receipt/list': (req?: IModels['POST/bank_receipt/list']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/bank_receipt/list',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/bank_receipt/list']>
    },

    /**
     * 接口名：未匹配订单
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=543044&itf=2440981
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/verification/liquidate/not_match_order': (
      req?: IModels['POST/verification/liquidate/not_match_order']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/verification/liquidate/not_match_order',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/verification/liquidate/not_match_order']>
    },

    /**
     * 接口名：批量删除
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=543044&itf=2441741
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/bank_receipt/delete_in_batch': (
      req?: IModels['POST/bank_receipt/delete_in_batch']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/bank_receipt/delete_in_batch',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/bank_receipt/delete_in_batch']>
    },

    /**
     * 接口名：上传文件
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=543044&itf=2441796
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/bank_receipt/upload_by_id': (
      req?: IModels['POST/bank_receipt/upload_by_id']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/bank_receipt/upload_by_id',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/bank_receipt/upload_by_id']>
    },

    /**
     * 接口名：根据id查询
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=543044&itf=2441805
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/bank_receipt/query_by_id': (
      req?: IModels['POST/bank_receipt/query_by_id']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/bank_receipt/query_by_id',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/bank_receipt/query_by_id']>
    },

    /**
     * 接口名：下载单个回单或对账单
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=543044&itf=2441806
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/bank_receipt/download_file': (
      req?: IModels['POST/bank_receipt/download_file']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/bank_receipt/download_file',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/bank_receipt/download_file']>
    },

    /**
     * 接口名：批量下载
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=543044&itf=2441807
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/bank_receipt/download_in_batch': (
      req?: IModels['POST/bank_receipt/download_in_batch']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/bank_receipt/download_in_batch',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/bank_receipt/download_in_batch']>
    },

    /**
     * 接口名：对账单银行简称
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/bank_receipt/account_name_list': (
      req?: IModels['POST/bank_receipt/account_name_list']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/bank_receipt/account_name_list',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/bank_receipt/account_name_list']>
    },

    /**
     * 接口名：删除单个文件
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=543044&itf=2441815
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/bank_receipt/delete_file': (
      req?: IModels['POST/bank_receipt/delete_file']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/bank_receipt/delete_file',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/bank_receipt/delete_file']>
    },

    /**
     * 接口名：订单交付_获取最新数据
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=543044&itf=2445332
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/order/delivery/query_latest_data': (
      req?: IModels['GET/order/delivery/query_latest_data']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/order/delivery/query_latest_data',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/order/delivery/query_latest_data']>
    },
  }
}