/* 银行流水模块 */

import * as commonLib from '../modules/commonLib'
import {IResponseTypes, IModels} from './request'

export default function createFetch(fetchConfig: any) {
  const rapperFetch = commonLib.getRapperRequest(fetchConfig)

  return {
    /**
     * 接口名：查询
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507358&itf=2250152
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/financial_flows/conditions': (
      req?: IModels['POST/financial_flows/conditions']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/financial_flows/conditions',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/financial_flows/conditions']>
    },

    /**
     * 接口名：银行流水导出
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507358&itf=2245422
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/financial_flows/downLoad': (
      req?: IModels['POST/financial_flows/downLoad']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/financial_flows/downLoad',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/financial_flows/downLoad']>
    },

    /**
     * 接口名：银行流水下拉框
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507358&itf=2244031
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/financial_banks/drop_list': (
      req?: IModels['POST/financial_banks/drop_list']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/financial_banks/drop_list',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/financial_banks/drop_list']>
    },

    /**
     * 接口名：锁定解锁单条记录
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507358&itf=2260427
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/financial_flows/status': (
      req?: IModels['PATCH/financial_flows/status']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/financial_flows/status',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/financial_flows/status']>
    },

    /**
     * 接口名：手动同步支付池流水
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507358&itf=2342479
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/financial_flows/sync_flow': (
      req?: IModels['POST/financial_flows/sync_flow']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/financial_flows/sync_flow',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/financial_flows/sync_flow']>
    },

    /**
     * 接口名：修改收付方
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507358&itf=2369591
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PUT/financial_flows/{id}/cost_to': (
      req?: IModels['PUT/financial_flows/{id}/cost_to']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/financial_flows/{id}/cost_to',
        method: 'PUT',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PUT/financial_flows/{id}/cost_to']>
    },

    /**
     * 接口名：银行流水批量修改
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507358&itf=2376830
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/financial_flows/batch': (req?: IModels['PATCH/financial_flows/batch']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/financial_flows/batch',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/financial_flows/batch']>
    },

    /**
     * 接口名：银行流水单独添加
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507358&itf=2376833
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/financial_flows': (req?: IModels['POST/financial_flows']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/financial_flows',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/financial_flows']>
    },

    /**
     * 接口名：银行流水行批量删除
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507358&itf=2376835
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'DELETE/financial_flows/batch': (
      req?: IModels['DELETE/financial_flows/batch']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/financial_flows/batch',
        method: 'DELETE',
        params: req,
        extra,
      }) as Promise<IResponseTypes['DELETE/financial_flows/batch']>
    },

    /**
     * 接口名：银行流水上传
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507358&itf=2376836
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/financial_flows/upload': (req?: IModels['POST/financial_flows/upload']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/financial_flows/upload',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/financial_flows/upload']>
    },

    /**
     * 接口名：银行流水修改
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507358&itf=2376840
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/financial_flows': (req?: IModels['PATCH/financial_flows']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/financial_flows',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/financial_flows']>
    },

    /**
     * 接口名：银行回单下载
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507358&itf=2390863
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/bank_receipt/download': (req?: IModels['GET/bank_receipt/download']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/bank_receipt/download',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/bank_receipt/download']>
    },

    /**
     * 接口名：银行回单_预览
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507358&itf=2390997
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/bank_receipt/preview': (req?: IModels['GET/bank_receipt/preview']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/bank_receipt/preview',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/bank_receipt/preview']>
    },

    /**
     * 接口名：银行流水_修改回单编号
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507358&itf=2412082
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/financial_flows/update_receipt_no': (
      req?: IModels['PATCH/financial_flows/update_receipt_no']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/financial_flows/update_receipt_no',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/financial_flows/update_receipt_no']>
    },

    /**
     * 接口名：款项类型下拉
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=507358&itf=2450577
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/financial_flows/drop/payment_type_drop_list': (
      req?: IModels['GET/financial_flows/drop/payment_type_drop_list']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/financial_flows/drop/payment_type_drop_list',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/financial_flows/drop/payment_type_drop_list']>
    },

    /**
     * 接口名：导入回单编号
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/financial_flows/import_receipt_no': (
      req?: IModels['POST/financial_flows/import_receipt_no']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/financial_flows/import_receipt_no',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/financial_flows/import_receipt_no']>
    },

    /**
     * 接口名：银行流水_导入修改收付方账户
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/financial_flows/import_cost_to_account': (
      req?: IModels['POST/financial_flows/import_cost_to_account']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/financial_flows/import_cost_to_account',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/financial_flows/import_cost_to_account']>
    },
  }
}