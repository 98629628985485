import Api from '@/http/modules/index'
let {
  // 获取个人消息
  'GET/sys_messages/oneself': getPersonalMsgApi,
  // 获取公告消息
  'GET/sys_messages/announcement': getNoticeArrApi,
} = Api

interface Message {
  title: string
  createdAt: string
  status: string
  url: string
  msgId: number
  filename: string
  content: string
}

interface Sate {
  personalMessageArr: Message[]
  noticMessageArr: Message[]
}

const state: Sate = {
  personalMessageArr: [],
  noticMessageArr: []
}

const mutations = {
  SET_PERSONAL: (state: any, messageArr: any) => {
    state.personalMessageArr = messageArr
  },
  SET_NOTICE: (state: any, messageArr: any) => {
    state.noticMessageArr = messageArr
  }
}

const actions = {
  getMessage({ commit, state, rootState }: any) {
    getPersonalMsgApi({userId: rootState.userId}).then((rep: any) => {
      commit("SET_PERSONAL", rep)
    })
    // 获取公告
    getNoticeArrApi({userId: rootState.userId}).then((rep: any) => {
      commit("SET_NOTICE", rep)
    })
    }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};