/* 劳动合同模块 */

import * as commonLib from '../modules/commonLib'
import {IResponseTypes, IModels} from './request'

export default function createFetch(fetchConfig: any) {
  const rapperFetch = commonLib.getRapperRequest(fetchConfig)

  return {
    /**
     * 接口名：首页查看获取最新合同
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=534925&itf=2398599
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'POST/labor_contracts/latest': (req?: IModels['POST/labor_contracts/latest']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/labor_contracts/latest',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/labor_contracts/latest']>
    }, */

    /**
     * 接口名：获取劳动合同列表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=534925&itf=2398604
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'POST/labor_contracts/list': (req?: IModels['POST/labor_contracts/list']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/labor_contracts/list',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/labor_contracts/list']>
    }, */

    /**
     * 接口名：新增-劳动合同信息
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=534925&itf=2398600
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'POST/labor_contracts': (req?: IModels['POST/labor_contracts']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/labor_contracts',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/labor_contracts']>
    }, */

    /**
     * 接口名：更新-劳动合同信息
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=534925&itf=2398601
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'PATCH/labor_contracts': (req?: IModels['PATCH/labor_contracts']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/labor_contracts',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/labor_contracts']>
    }, */

    /**
     * 接口名：删除-劳动合同记录
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=534925&itf=2398602
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'DELETE/labor_contracts/{id}': (req?: IModels['DELETE/labor_contracts/{id}']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/labor_contracts/{id}',
        method: 'DELETE',
        params: req,
        extra,
      }) as Promise<IResponseTypes['DELETE/labor_contracts/{id}']>
    }, */

    /**
     * 接口名：劳务合同_设置为作废状态
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'PATCH/labor_contracts/home_page/waster': (req?: IModels['PATCH/labor_contracts/home_page/waster']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/labor_contracts/home_page/waster',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/labor_contracts/home_page/waster']>
    }, */

    /**
     * 接口名：上传-劳动合同文件
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=534925&itf=2398603
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'POST/labor_contracts/upload': (req?: IModels['POST/labor_contracts/upload']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/labor_contracts/upload',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/labor_contracts/upload']>
    }, */

    /**
     * 接口名：删除劳动合同文件
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=534925&itf=2398607
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'DELETE/labor_contracts/delete_file': (
      req?: IModels['DELETE/labor_contracts/delete_file']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/labor_contracts/delete_file',
        method: 'DELETE',
        params: req,
        extra,
      }) as Promise<IResponseTypes['DELETE/labor_contracts/delete_file']>
    }, */

    /**
     * 接口名：获取劳动合同附件列表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=534925&itf=2399127
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'GET/labor_contracts/{id}': (req?: IModels['GET/labor_contracts/{id}']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/labor_contracts/{id}',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/labor_contracts/{id}']>
    }, */

    /**
     * 接口名：差异值查询(在职总人数和劳动合同数)
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=534925&itf=2398626
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'GET/labor_contracts/diff': (req?: IModels['GET/labor_contracts/diff']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/labor_contracts/diff',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/labor_contracts/diff']>
    }, */

    /**
     * 接口名：劳务合同首页_分页查询
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=534925&itf=2423730
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'POST/labor_contracts/home_page/query': (
      req?: IModels['POST/labor_contracts/home_page/query']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/labor_contracts/home_page/query',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/labor_contracts/home_page/query']>
    }, */

    /**
     * 接口名：合同_审批状态拉下框
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=534925&itf=2423733
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'GET/labor_contracts/drop_list/approval_status': (
      req?: IModels['GET/labor_contracts/drop_list/approval_status']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/labor_contracts/drop_list/approval_status',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/labor_contracts/drop_list/approval_status']>
    }, */

    /**
     * 接口名：获取劳动合同快到期员工
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=534925&itf=2318052
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'GET/users/contracts/expired': (req?: IModels['GET/users/contracts/expired']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/users/contracts/expired',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/users/contracts/expired']>
    }, */

    /**
     * 接口名：劳务合同首页_提交审批
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=534925&itf=2423859
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'POST/labor_contracts/home_page/submit_approval': (
      req?: IModels['POST/labor_contracts/home_page/submit_approval']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/labor_contracts/home_page/submit_approval',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/labor_contracts/home_page/submit_approval']>
    }, */

    /**
     * 接口名：劳务合同_驳回
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=534925&itf=2424172
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'PATCH/labor_contracts/home_page/reject': (
      req?: IModels['PATCH/labor_contracts/home_page/reject']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/labor_contracts/home_page/reject',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/labor_contracts/home_page/reject']>
    }, */

    /**
     * 接口名：劳务合同_审批
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=534925&itf=2424179
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'POST/labor_contracts/home_page/approving': (
      req?: IModels['POST/labor_contracts/home_page/approving']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/labor_contracts/home_page/approving',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/labor_contracts/home_page/approving']>
    }, */

    /**
     * 接口名：合同_预览
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=534925&itf=2424485
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'GET/labor_contracts/home_page/preview': (
      req?: IModels['GET/labor_contracts/home_page/preview']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/labor_contracts/home_page/preview',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/labor_contracts/home_page/preview']>
    }, */

    /**
     * 接口名：合同_生成合同编号
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=534925&itf=2424718
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'GET/labor_contracts/generate/contract_no': (
      req?: IModels['GET/labor_contracts/generate/contract_no']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/labor_contracts/generate/contract_no',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/labor_contracts/generate/contract_no']>
    }, */

    /**
     * 接口名：批量修改合同管理员
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=534925&itf=2429693
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'PATCH/labor_contracts/update_manger': (
      req?: IModels['PATCH/labor_contracts/update_manger']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/labor_contracts/update_manger',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/labor_contracts/update_manger']>
    }, */

    /**
     * 接口名：获取当前用户所在团队团长
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=534925&itf=2435851
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'GET/organizations/get_owners': (
      req?: IModels['GET/organizations/get_owners']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/organizations/get_owners',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/organizations/get_owners']>
    }, */

    /**
     * 接口名：获取需审批合同
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=534925&itf=2436114
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'GET/labor_contracts/get/{id}': (
      req?: IModels['GET/labor_contracts/get/{id}']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/labor_contracts/get/{id}',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/labor_contracts/get/{id}']>
    }, */

    /**
     * 接口名：劳务合同首页_重新发起审批
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=534925&itf=2437872
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'POST/labor_contracts/home_page/reapproval': (
      req?: IModels['POST/labor_contracts/home_page/reapproval']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/labor_contracts/home_page/reapproval',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/labor_contracts/home_page/reapproval']>
    }, */

    /**
     * 接口名：劳务合同档案_是否可签署合同
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=534925&itf=2437876
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'POST/labor_contracts/home_page/can_be_signed': (
      req?: IModels['POST/labor_contracts/home_page/can_be_signed']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/labor_contracts/home_page/can_be_signed',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/labor_contracts/home_page/can_be_signed']>
    }, */

    /**
     * 接口名： 劳务合同_下载
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'POST/labor_contracts/download': (
      req?: IModels['POST/labor_contracts/download']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/labor_contracts/download',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/labor_contracts/download']>
    }, */
  }
}