/* crm模块 */

import * as commonLib from '../modules/commonLib'
import {IResponseTypes, IModels} from './request'

export default function createFetch(fetchConfig: any) {
  const rapperFetch = commonLib.getRapperRequest(fetchConfig)

  return {
    /**
     * 接口名：客户列表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=538745&itf=2417609
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/crm_customer/list': (req?: IModels['POST/crm_customer/list']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/crm_customer/list',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/crm_customer/list']>
    },

    /**
     * 接口名：上传(批量上传)
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=538745&itf=2417652
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/crm_customer/create': (req?: IModels['POST/crm_customer/create']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/crm_customer/create',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/crm_customer/create']>
    },

    /**
     * 接口名：修改
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=538745&itf=2417760
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/crm_customer/update': (req?: IModels['POST/crm_customer/update']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/crm_customer/update',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/crm_customer/update']>
    },

    /**
     * 接口名：下载模板
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=538745&itf=2417720
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/crm_customer/download_template': (
      req?: IModels['POST/crm_customer/download_template']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/crm_customer/download_template',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/crm_customer/download_template']>
    },

    /**
     * 接口名：读取模板(导入)
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=538745&itf=2417721
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/crm_customer/read_template': (
      req?: IModels['POST/crm_customer/read_template']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/crm_customer/read_template',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/crm_customer/read_template']>
    },

    /**
     * 接口名：潜客转预客
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=538745&itf=2417786
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/crm_customer/lead_convert_prospect': (
      req?: IModels['POST/crm_customer/lead_convert_prospect']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/crm_customer/lead_convert_prospect',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/crm_customer/lead_convert_prospect']>
    },

    /**
     * 接口名：客户跟进
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=538745&itf=2417822
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/crm_customer/follower': (req?: IModels['POST/crm_customer/follower']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/crm_customer/follower',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/crm_customer/follower']>
    },

    /**
     * 接口名：上传
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=538745&itf=2418055
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/upload': (req?: IModels['POST/upload']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/upload',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/upload']>
    },

    /**
     * 接口名：下载
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=538745&itf=2418057
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/upload': (req?: IModels['GET/upload']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/upload',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/upload']>
    },

    /**
     * 接口名：转客户
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=538745&itf=2418099
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/crm_customer/convert_customer': (
      req?: IModels['POST/crm_customer/convert_customer']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/crm_customer/convert_customer',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/crm_customer/convert_customer']>
    },

    /**
     * 接口名：分配跟进人
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=538745&itf=2418139
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/crm_customer/assignee': (req?: IModels['POST/crm_customer/assignee']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/crm_customer/assignee',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/crm_customer/assignee']>
    },

    /**
     * 接口名：分配负责人
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=538745&itf=2418170
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/crm_customer/assign_manager': (
      req?: IModels['POST/crm_customer/assign_manager']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/crm_customer/assign_manager',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/crm_customer/assign_manager']>
    },

    /**
     * 接口名：订单列表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=538745&itf=2418693
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/crm_order/list': (req?: IModels['POST/crm_order/list']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/crm_order/list',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/crm_order/list']>
    },

    /**
     * 接口名：添加订单
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=538745&itf=2418760
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/crm_order/assign_pm': (req?: IModels['POST/crm_order/assign_pm']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/crm_order/assign_pm',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/crm_order/assign_pm']>
    },

    /**
     * 接口名：客户列表-联系人列表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=538745&itf=2418838
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/crm_order/list_customer': (
      req?: IModels['POST/crm_order/list_customer']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/crm_order/list_customer',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/crm_order/list_customer']>
    },

    /**
     * 接口名：新增订单
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=538745&itf=2418992
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/crm_order/create': (req?: IModels['POST/crm_order/create']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/crm_order/create',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/crm_order/create']>
    },

    /**
     * 接口名：修改订单
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=538745&itf=2419002
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/crm_order/update': (req?: IModels['POST/crm_order/update']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/crm_order/update',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/crm_order/update']>
    },

    /**
     * 接口名：记录商机列表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=538745&itf=2419006
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/crm_probability/list': (req?: IModels['POST/crm_probability/list']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/crm_probability/list',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/crm_probability/list']>
    },

    /**
     * 接口名：记录商机客户列表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=538745&itf=2419036
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/crm_probability/customer_list': (
      req?: IModels['POST/crm_probability/customer_list']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/crm_probability/customer_list',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/crm_probability/customer_list']>
    },

    /**
     * 接口名：添加记录商机
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=538745&itf=2419071
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/crm_probability/create': (req?: IModels['POST/crm_probability/create']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/crm_probability/create',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/crm_probability/create']>
    },

    /**
     * 接口名：添加跟进人
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=538745&itf=2419098
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/crm_probability/assignee': (
      req?: IModels['POST/crm_probability/assignee']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/crm_probability/assignee',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/crm_probability/assignee']>
    },

    /**
     * 接口名：更新商机记录信息
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=538745&itf=2419103
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/crm_probability/update': (req?: IModels['POST/crm_probability/update']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/crm_probability/update',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/crm_probability/update']>
    },

    /**
     * 接口名：预览
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=538745&itf=2419258
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/crm_customer/preview_file': (
      req?: IModels['POST/crm_customer/preview_file']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/crm_customer/preview_file',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/crm_customer/preview_file']>
    },

    /**
     * 接口名：商机记录-跟进
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=538745&itf=2419268
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/crm_probability/follower': (
      req?: IModels['POST/crm_probability/follower']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/crm_probability/follower',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/crm_probability/follower']>
    },

    /**
     * 接口名：商机记录-转订单
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=538745&itf=2419331
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/crm_probability/convert_order': (
      req?: IModels['POST/crm_probability/convert_order']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/crm_probability/convert_order',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/crm_probability/convert_order']>
    },

    /**
     * 接口名：跟进记录列表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=538745&itf=2419375
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/crm_customer_followup/list': (
      req?: IModels['POST/crm_customer_followup/list']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/crm_customer_followup/list',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/crm_customer_followup/list']>
    },

    /**
     * 接口名：修改跟进记录
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=538745&itf=2419380
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/crm_customer_followup/update': (
      req?: IModels['POST/crm_customer_followup/update']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/crm_customer_followup/update',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/crm_customer_followup/update']>
    },

    /**
     * 接口名：添加个人潜客/客户
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=538745&itf=2420534
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/crm_customer/create_personal': (
      req?: IModels['POST/crm_customer/create_personal']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/crm_customer/create_personal',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/crm_customer/create_personal']>
    },

    /**
     * 接口名：修改个人
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=538745&itf=2420633
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/crm_customer/update_personal': (
      req?: IModels['POST/crm_customer/update_personal']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/crm_customer/update_personal',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/crm_customer/update_personal']>
    },

    /**
     * 接口名：创建客户组织
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=538745&itf=2421115
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/crm_customer/create_enterprise': (
      req?: IModels['POST/crm_customer/create_enterprise']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/crm_customer/create_enterprise',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/crm_customer/create_enterprise']>
    },

    /**
     * 接口名：修改组织
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=538745&itf=2421150
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/crm_customer/update_enterprise': (
      req?: IModels['POST/crm_customer/update_enterprise']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/crm_customer/update_enterprise',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/crm_customer/update_enterprise']>
    },

    /**
     * 接口名：商机记录修改状态
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=538745&itf=2421292
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/crm_probability/update_state': (
      req?: IModels['POST/crm_probability/update_state']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/crm_probability/update_state',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/crm_probability/update_state']>
    },

    /**
     * 接口名：获取crm用户
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=538745&itf=2423524
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/crm_customer/crm_user_list': (
      req?: IModels['POST/crm_customer/crm_user_list']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/crm_customer/crm_user_list',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/crm_customer/crm_user_list']>
    },

    /**
     * 接口名：销售看板列表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=538745&itf=2427346
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/crm_order/performance': (req?: IModels['POST/crm_order/performance']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/crm_order/performance',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/crm_order/performance']>
    },

    /**
     * 接口名：客户管理批量分配跟进人
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=538745&itf=2451581
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/crm_customer/assign_followup': (
      req?: IModels['POST/crm_customer/assign_followup']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/crm_customer/assign_followup',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/crm_customer/assign_followup']>
    },
  }
}