import router from "@/router/index";
import store from "@/store/index";

export default (vue: any) => {
  vue.directive("permission", {
    mounted(el: any, binding: any) {
      let { currentRoute: cRouter }:any = router
      
      // 如果包含资源权限管理， 但是没有此资源
      let elOriginalStyle = ''
      if(binding.arg && !store.getters.resources.includes(binding.arg)){
        elOriginalStyle = el.style.display
        el.style.display = 'none'
        // el.parentElement.removeChild(el)
        return
      } else if(binding.arg && store.getters.resources.includes(binding.arg)) {
        // el.style.display = 'block'
        el.style.display = elOriginalStyle
        return
      }

      if(!cRouter.value.meta || !cRouter.value.meta.crud) return;
      
      if(!cRouter.value.meta.crud.includes(binding.value) && !cRouter.value.meta.crud.includes('Manage')){
        el.style.display = 'none'
        // el.parentElement.removeChild(el)
      }
      
      
      // el.parentElement.removeChild(el)

    }
  })
}

/* let { app } = _router;
  let _route = app.$route;
  if (!(_route.meta && _route.meta.type)) return;
  if (_route.meta.type < 2) {
      if (value) {
        let { permissonObj } = value
        permissonObj.common = false
        return
      }
      el.parentElement.removeChild(el)
} */