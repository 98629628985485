
import { defineComponent, PropType, onBeforeMount, toRefs, watch } from 'vue'


export default defineComponent({
    props: {
        selectConfig: {
            type: Object as PropType<any>,
            default: () => { }
        },
        dataObj: {
            type: Object as PropType<any>,
            default: {}
        }
    },
    emits: ['selectChange'],
    setup(props, context) {
        // let { selectConfig }: any = toRefs(props)
        onBeforeMount(() => {
            
            let { selectFunc, funcParam, options } = props.selectConfig
            
            
            // 拥有自定义选项
            if (props.selectConfig.options && props.selectConfig.options.length > 0) return;
            if(!selectFunc) { return }
            selectFunc(funcParam || {}).then((rep: any) => {
                
                props.selectConfig.options = rep
                
            })

        })
        const changeHandle = (value: string | number) => {

            // context.emit('selectChange', { value, upKey: selectConfig.value.upKey})
            props.selectConfig.value = value
            if(props.selectConfig.selectCb){
                props.selectConfig.selectCb(value,  props.selectConfig.upKey)
            }
        }
        // 同步config中value
        watch(()=> props.selectConfig.value, (newValue) => {
            props.dataObj[props.selectConfig.upKey] = newValue
            context.emit('selectChange', { value: newValue, upKey: props.selectConfig.upKey})

        })

        return {
            changeHandle,
        }
    },
})
