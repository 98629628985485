export default (vue: any) => {
  vue.directive("decimal", {
    mounted(el: any, binding: any) {

      el.addEventListener('click', () => {
        if (!el.disabled) {
          // binding.value();
          el.disabled = true;
          setTimeout(() => {
            el.disabled = false;
          }, 500);
        }
      });


    }
  })
}