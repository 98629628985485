export default (vue: any) => {
    vue.directive("showTooltip", {
		mounted(el:any, binding:any) {
			if(binding.value){
				el.style.display = el.scrollWidth>374 ? 'inline-block' : 'none';
			}else{
				el.style.display = el.scrollWidth>374 ? 'none' : 'inline-block';
				
			}	
		},
		// updated(el:any, binding:any) {		
		// 	if(binding.value){
		// 		el.style.display = el.scrollWidth>374 ? 'inline-block' : 'none';
		// 	}else{
		// 		el.style.display = el.scrollWidth>374 ? 'none' : 'inline-block';
				
		// 	}	
		// }
    })   
}