import zhCn from 'element-plus/es/locale/lang/zh-cn'
import en from 'element-plus/es/locale/lang/en'

import appZhCn from './appZhCn'
import appEn from './appEn'


export default {
  'zh-CN': {
    caption: '简体中文',
    ...zhCn,
    ...appZhCn
  },
  'en': {
    caption: 'English',
    ...en,
    ...appEn
  }
}