/* 销售分配模块 */

import * as commonLib from '../modules/commonLib'
import {IResponseTypes, IModels} from './request'

export default function createFetch(fetchConfig: any) {
  const rapperFetch = commonLib.getRapperRequest(fetchConfig)

  return {
    /**
     * 接口名：销售分配详情
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=529871&itf=2370853
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/sale/customers/page': (req?: IModels['POST/sale/customers/page']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/sale/customers/page',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/sale/customers/page']>
    },

    /**
     * 接口名：分配销售
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=529871&itf=2370872
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/sale/customers/divide': (req?: IModels['POST/sale/customers/divide']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/sale/customers/divide',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/sale/customers/divide']>
    },

    /**
     * 接口名：取消分配
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=529871&itf=2370880
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/sale/customers/divide/cancel': (
      req?: IModels['POST/sale/customers/divide/cancel']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/sale/customers/divide/cancel',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/sale/customers/divide/cancel']>
    },

    /**
     * 接口名：销售可查看的客户列表(左)
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=529871&itf=2371719
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/sale/customers': (req?: IModels['POST/sale/customers']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/sale/customers',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/sale/customers']>
    },

    /**
     * 接口名：销售可查看的发票列表(上)
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=529871&itf=2371721
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/sale/invoices': (req?: IModels['POST/sale/invoices']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/sale/invoices',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/sale/invoices']>
    },

    /**
     * 接口名：销售可查看的流水列表(下)
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=529871&itf=2371722
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/sale/flows': (req?: IModels['POST/sale/flows']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/sale/flows',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/sale/flows']>
    },

    /**
     * 接口名：发票根据核销记录获取流水
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=529871&itf=2375291
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/sale/invoices/{id}/flows': (
      req?: IModels['GET/sale/invoices/{id}/flows']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/sale/invoices/{id}/flows',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/sale/invoices/{id}/flows']>
    },

    /**
     * 接口名：流水根据核销记录获取发票
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=529871&itf=2375292
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/sale/flows/{id}/invoices': (
      req?: IModels['GET/sale/flows/{id}/invoices']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/sale/flows/{id}/invoices',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/sale/flows/{id}/invoices']>
    },
  }
}