import { staticRoutes, asyncRoutes } from "@/router";
import { RouteRecordRaw } from 'vue-router'
import { deepClone } from "@/utils/index"
import Api from '@/http/modules/index'
const { 
    'GET/permission': getPermissionApi, 
    'GET/oauth2': oauthApi,
} = Api

/**
 * Use meta.role to determine if the current user has permission
 * @param {array} route 路由
 * @param {array} perObj 当前账号所拥有权限
 */
function hasPermission(route: any, perObj: any) {
    if (route.meta && route.meta.permissionStr) {
        let _perStr = route.meta.permissionStr
        return Object.keys(perObj).some((pmisCode: string) => {
            // if(pmisCode === _perStr){
            if(_perStr.includes(pmisCode)){
                route.meta.crud = perObj[pmisCode]
                return true
            }
        })
    } 
    return true
}

/**
 * @description Filter asynchronous routing tables by recursion
 * @param {array} routes 所有动态路由
 * @param {array} permissionsArr 当前账号所拥有权限
 * @return {array} 有权限的路由
 */
export function filterAsyncRoutes(routes: any, permissionsArr: object) {
    // const res: any[] = [];
    return routes.reduce((resultRt: any[], route: RouteRecordRaw) => {
        // 非菜单栏分类
        if(!route.children && hasPermission(route, permissionsArr)){
            resultRt.push(route);
        }
        if(route.children && route.children.length > 0){
            // 递归判断子菜单权限
            route.children = filterAsyncRoutes(route.children, permissionsArr);
            if((route.children as RouteRecordRaw[]).length > 0){
                resultRt.push(route)
            }
        }
        return resultRt
    }, []);


    /* const tmp = { ...route };
    // 菜单栏分类不校验
    if (hasPermission(tmp, permissionsArr)) {
        if (tmp.children) {
            tmp.children = filterAsyncRoutes(tmp.children, permissionsArr);
        }
        res.push(tmp);
    } */
    // return res;
}

const state = {
    privileges: false,
    // 所有资源list
    resources: [],
    routes: [],
    asyncFullRouters: asyncRoutes,
    sidebarRouters: [],
    // 企业微信绑定
    weCom: false,
}

const mutations = {
    SET_PRIVILEGES: (state: any, privileges: any) => {
        state.privileges = privileges
        state.resources = Object.keys(privileges)
    },
    SET_ROUTES: (state: any, routes: any) => {
        state.sidebarRouters = routes
    },
    SET_WE_COM: (state: any, weCom: boolean) => {
        state.weCom = weCom
    }
}

const actions = {
    generateRoutes({ commit, state }: any, permissionsArr = {}) {
        return new Promise((resolve) => {

            let accessRouters
            accessRouters = filterAsyncRoutes((state.asyncFullRouters), permissionsArr)
            // 菜单分组不校验权限
            /* let accessRouters = state.asyncFullRouters.reduce((routerArr: any[], asyncRoute: any) => {
                let routeList: any = filterAsyncRoutes([asyncRoute], permissionsArr)
                if(routeList[0].children.length === 0) return routerArr;
                return routerArr.concat(routeList)
            }, []) */
            
            /* let _sidebarRouters = staticRoutes.concat(deepClone(accessRouters)).filter((_rout: any) => {
                return _rout.meta && !_rout.meta.hidden
            }) */
            
            commit("SET_ROUTES", staticRoutes.concat(deepClone(accessRouters)))
            resolve(accessRouters)
        });
    },
    getPermission({ commit, state }: any){
        return getPermissionApi().then((_privileges: any) => {
            commit("SET_PRIVILEGES", _privileges);
            return _privileges
        })
    },
    generateWeCom({ commit, state }: any){
        return new Promise((resolve) => {
            oauthApi().then((rep: any) => {
                // true: 没有绑定，需要显示绑定二维码
                // rep.show ? state.weCom = false : state.weCom = true
                rep.show ? commit("SET_WE_COM", false) : commit("SET_WE_COM", true)
                resolve(rep)
            })
        });
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};