/* 发票审批模块 */

import * as commonLib from '../modules/commonLib'
import {IResponseTypes, IModels} from './request'

export default function createFetch(fetchConfig: any) {
  const rapperFetch = commonLib.getRapperRequest(fetchConfig)

  return {
    /**
     * 接口名：查询审批记录
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=516887&itf=2299026
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/approved/records': (req?: IModels['POST/approved/records']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/approved/records',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/approved/records']>
    },

    /**
     * 接口名：HR审批列表（分页）
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=516887&itf=2298855
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/approved/conditions': (req?: IModels['POST/approved/conditions']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/approved/conditions',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/approved/conditions']>
    },

    /**
     * 接口名：审批接口
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=516887&itf=2298857
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/approved/approved': (req?: IModels['PATCH/approved/approved']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/approved/approved',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/approved/approved']>
    },

    /**
     * 接口名：列表（不分页）
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=516887&itf=2299065
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/approved/list': (req?: IModels['GET/approved/list']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/approved/list',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/approved/list']>
    },

    /**
     * 接口名：审批记录审批（财务二次审批）
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=516887&itf=2300677
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/approved/approved_record': (
      req?: IModels['POST/approved/approved_record']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/approved/approved_record',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/approved/approved_record']>
    },

    /**
     * 接口名：修改进项税额纳税差异
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=516887&itf=2303556
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/approved/update_amount': (
      req?: IModels['PATCH/approved/update_amount']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/approved/update_amount',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/approved/update_amount']>
    },

    /**
     * 接口名：查询二次审批数据列表（不分页）
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=516887&itf=2304127
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/approved/sec_approved': (req?: IModels['POST/approved/sec_approved']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/approved/sec_approved',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/approved/sec_approved']>
    },

    /**
     * 接口名：审批记录详情列表（分页）
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=516887&itf=2304143
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/approved/query_details': (req?: IModels['POST/approved/query_details']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/approved/query_details',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/approved/query_details']>
    },

    /**
     * 接口名：组织审批人列表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=516887&itf=2308362
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/org_approved/list': (req?: IModels['GET/org_approved/list']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/org_approved/list',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/org_approved/list']>
    },

    /**
     * 接口名：公司审批人列表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=516887&itf=2308363
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/com_approved/list': (req?: IModels['GET/com_approved/list']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/com_approved/list',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/com_approved/list']>
    },

    /**
     * 接口名：财务审批记录
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=516887&itf=2312077
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/approved/sec_record': (req?: IModels['POST/approved/sec_record']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/approved/sec_record',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/approved/sec_record']>
    },

    /**
     * 接口名：保存票据接口
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=516887&itf=2314415
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/invoice/save': (req?: IModels['POST/invoice/save']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/invoice/save',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/invoice/save']>
    },

    /**
     * 接口名：查询支付单据的票据
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=516887&itf=2314421
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/invoice': (req?: IModels['GET/invoice']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/invoice',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/invoice']>
    },

    /**
     * 接口名：删除票据
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=516887&itf=2316385
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'DELETE/invoice': (req?: IModels['DELETE/invoice']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/invoice',
        method: 'DELETE',
        params: req,
        extra,
      }) as Promise<IResponseTypes['DELETE/invoice']>
    },

    /**
     * 接口名：保存共享票据（总票据）
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=516887&itf=2317109
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/invoice/save_share': (req?: IModels['POST/invoice/save_share']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/invoice/save_share',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/invoice/save_share']>
    },

    /**
     * 接口名：查找某共享票据
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=516887&itf=2317149
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/invoice/find_share': (req?: IModels['POST/invoice/find_share']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/invoice/find_share',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/invoice/find_share']>
    },

    /**
     * 接口名：共享票据列表（总）
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=516887&itf=2318172
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/invoice/share_conditions': (
      req?: IModels['POST/invoice/share_conditions']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/invoice/share_conditions',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/invoice/share_conditions']>
    },

    /**
     * 接口名：财务审批回退
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=516887&itf=2321440
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/approved/roll_back_status': (
      req?: IModels['POST/approved/roll_back_status']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/approved/roll_back_status',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/approved/roll_back_status']>
    },

    /**
     * 接口名：公司审批人-增加
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=516887&itf=2334522
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/com_approved': (req?: IModels['POST/com_approved']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/com_approved',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/com_approved']>
    },

    /**
     * 接口名：公司审批人-修改
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=516887&itf=2334523
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/com_approved': (req?: IModels['PATCH/com_approved']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/com_approved',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/com_approved']>
    },

    /**
     * 接口名：公司审批人-删除
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=516887&itf=2334525
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'DELETE/com_approved': (req?: IModels['DELETE/com_approved']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/com_approved',
        method: 'DELETE',
        params: req,
        extra,
      }) as Promise<IResponseTypes['DELETE/com_approved']>
    },

    /**
     * 接口名：组织审批人-增加
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=516887&itf=2334526
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/org_approved': (req?: IModels['POST/org_approved']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/org_approved',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/org_approved']>
    },

    /**
     * 接口名：组织审批人-修改
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=516887&itf=2334528
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/org_approved': (req?: IModels['PATCH/org_approved']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/org_approved',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/org_approved']>
    },

    /**
     * 接口名：组织审批人-删除
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=516887&itf=2334529
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'DELETE/org_approved': (req?: IModels['DELETE/org_approved']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/org_approved',
        method: 'DELETE',
        params: req,
        extra,
      }) as Promise<IResponseTypes['DELETE/org_approved']>
    },

    /**
     * 接口名：审批-办公场地-添加
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=516887&itf=2344776
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/lease_approved': (req?: IModels['POST/lease_approved']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/lease_approved',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/lease_approved']>
    },

    /**
     * 接口名：审批-办公场地-修改
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=516887&itf=2344777
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/lease_approved': (req?: IModels['PATCH/lease_approved']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/lease_approved',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/lease_approved']>
    },

    /**
     * 接口名：审批-办公场地-删除
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=516887&itf=2344778
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'DELETE/lease_approved': (req?: IModels['DELETE/lease_approved']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/lease_approved',
        method: 'DELETE',
        params: req,
        extra,
      }) as Promise<IResponseTypes['DELETE/lease_approved']>
    },

    /**
     * 接口名：办公场地审批人列表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=516887&itf=2344779
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/lease_approved/list': (req?: IModels['GET/lease_approved/list']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/lease_approved/list',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/lease_approved/list']>
    },

    /**
     * 接口名：无票管理-条件查询
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=516887&itf=2346977
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/invoice/no_invoice_conditions': (
      req?: IModels['POST/invoice/no_invoice_conditions']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/invoice/no_invoice_conditions',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/invoice/no_invoice_conditions']>
    },

    /**
     * 接口名：无票管理-发票提醒
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=516887&itf=2346980
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/invoice/send_remind': (req?: IModels['POST/invoice/send_remind']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/invoice/send_remind',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/invoice/send_remind']>
    },

    /**
     * 接口名：驳回
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=516887&itf=2360007
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/approved/reject': (req?: IModels['POST/approved/reject']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/approved/reject',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/approved/reject']>
    },

    /**
     * 接口名：发票管理_条件查询
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=516887&itf=2366493
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/invoice/conditions': (req?: IModels['POST/invoice/conditions']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/invoice/conditions',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/invoice/conditions']>
    },

    /**
     * 接口名：发票管理_详情查询
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=516887&itf=2366494
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/invoice/detail': (req?: IModels['POST/invoice/detail']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/invoice/detail',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/invoice/detail']>
    },

    /**
     * 接口名：发票认证_条件查询
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=516887&itf=2367810
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/invoice/confirm_conditions': (
      req?: IModels['POST/invoice/confirm_conditions']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/invoice/confirm_conditions',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/invoice/confirm_conditions']>
    },

    /**
     * 接口名：发票管理_发票认证
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=516887&itf=2367811
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/invoice/confirm_invoice': (
      req?: IModels['PATCH/invoice/confirm_invoice']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/invoice/confirm_invoice',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/invoice/confirm_invoice']>
    },

    /**
     * 接口名：删除共享票据
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=516887&itf=2369590
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'DELETE/invoice/share_delete': (req?: IModels['DELETE/invoice/share_delete']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/invoice/share_delete',
        method: 'DELETE',
        params: req,
        extra,
      }) as Promise<IResponseTypes['DELETE/invoice/share_delete']>
    },

    /**
     * 接口名：无票管理_确认无票
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=516887&itf=2373946
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/invoice/no_invoice_confirm': (
      req?: IModels['PATCH/invoice/no_invoice_confirm']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/invoice/no_invoice_confirm',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/invoice/no_invoice_confirm']>
    },

    /**
     * 接口名：发票_上传发票附件
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=516887&itf=2426732
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/invoice/upload_invoice': (req?: IModels['POST/invoice/upload_invoice']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/invoice/upload_invoice',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/invoice/upload_invoice']>
    },

    /**
     * 接口名：发票_预览发票附件
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=516887&itf=2426738
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/invoice/preview': (req?: IModels['GET/invoice/preview']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/invoice/preview',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/invoice/preview']>
    },

    /**
     * 接口名：发票_下载发票附件
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=516887&itf=2426736
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/invoice/down_invoice': (req?: IModels['POST/invoice/down_invoice']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/invoice/down_invoice',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/invoice/down_invoice']>
    },

    /**
     * 接口名：财务审批详情解锁/锁定
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=516887&itf=2429741
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/pay_pool/locked': (req?: IModels['PATCH/pay_pool/locked']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/pay_pool/locked',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/pay_pool/locked']>
    },

    /**
     * 接口名：修改账单信息
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=516887&itf=2429749
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/pay_pool/update_bank': (req?: IModels['PATCH/pay_pool/update_bank']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/pay_pool/update_bank',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/pay_pool/update_bank']>
    },

    /**
     * 接口名：企微通知报销人打印面单及发票
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=516887&itf=2451559
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/invoice_expense_vouchers/send_supplement': (
      req?: IModels['POST/invoice_expense_vouchers/send_supplement']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/invoice_expense_vouchers/send_supplement',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/invoice_expense_vouchers/send_supplement']>
    },

    /**
     * 接口名：发票管理_导入认证
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=516887&itf=2451588
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/invoice/invoice_upload': (req?: IModels['POST/invoice/invoice_upload']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/invoice/invoice_upload',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/invoice/invoice_upload']>
    },

    /**
     * 接口名：税务局发票_分页查询
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/invoice_tax_bureau/query': (req?: IModels['POST/invoice_tax_bureau/query']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/invoice_tax_bureau/query',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/invoice_tax_bureau/query']>
    },

    /**
     * 接口名：税务局发票_导入
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/invoice_tax_bureau/upload': (req?: IModels['POST/invoice_tax_bureau/upload']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/invoice_tax_bureau/upload',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/invoice_tax_bureau/upload']>
    },

    /**
     * 接口名：税务局发票_批量删除
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'DELETE/invoice_tax_bureau/delete': (req?: IModels['DELETE/invoice_tax_bureau/delete']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/invoice_tax_bureau/delete',
        method: 'DELETE',
        params: req,
        extra,
      }) as Promise<IResponseTypes['DELETE/invoice_tax_bureau/delete']>
    },

    /**
     * 接口名：税务局发票_匹配
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/invoice_tax_bureau/match': (req?: IModels['POST/invoice_tax_bureau/match']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/invoice_tax_bureau/match',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/invoice_tax_bureau/match']>
    },

  }
}