import router from "./router/index";
import NProgress from "nprogress"
import "nprogress/nprogress.css"
import store from "./store/index";
import { dimensionReduce } from "@/utils/index"
import Api from '@/http/modules/index'
const {
    // 获取jwt token
    'GET/jwt/token': jwtTokenApi,  
} = Api
NProgress.configure({ showSpinner: false });
// 白名单
const whiteList: string[] = ["/blank", "/login"]


router.beforeEach(async (to, from, next) => {

    NProgress.start()
    const hasToken: string | null = store.getters.token
    const hasPrivileges: object | boolean = store.getters.privileges
    const weCom: boolean = store.getters.weCom
    
    store.commit('SET_CURRENT_ROUTE', to)

    if (to.meta.passCheck === true) {
        next()
        NProgress.done()
        return
    }

    if (hasToken && to.path === '/login') {
        let redirect=to.query.redirect
        if(redirect&&redirect.includes('credits.ccjk.com')){ //火花兑换跳转过来CO3已登录，直接跳转到火花兑换
            jwtTokenApi().then((res:any)=>{
                window.location.href=redirect+`?token=${res.token}`
            })
            return
        }
        next({path: (redirect || from.fullPath) as string, replace: true})
        NProgress.done()
        return
    }
    
    // 没有token
    if (!hasToken && to.path !== '/login') {
        // next(`/login?redirect=${to.path}`)
        next({path: 'login', query: { redirect: to.path, ...to.query }})
        NProgress.done()
        return
    }

    // 如未获取权限
    if(hasPrivileges === false && hasToken){
        // 获取路由
        let reault = await store.dispatch('permissionMd/getPermission')
        // 根据权限编码，动态生成路由
        let accessRoutes  = await store.dispatch('permissionMd/generateRoutes', reault)
        
        accessRoutes.forEach((_router: any) => { 
            _router.children = dimensionReduce(_router.children)
            router.addRoute(_router)
        })
        
        next({path: to.path, replace: true, query: to.query})
        return 
    }

    next()
})

router.afterEach(() => {
    NProgress.done();
});